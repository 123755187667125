import { Checkbox, Col, Row } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { FC, useContext, useEffect, useMemo, useState } from "react"
import Modal, { ModalCommonProps } from "../../../../Component/ModalCommon"
import { AppContext } from "../../../../Context/AppContext"
import localStore from "../../../../Util/LocalStore"

const CheckboxGroup = Checkbox.Group

interface IProps extends ModalCommonProps {
    keyColumnsToShow: CheckboxValueType[]
    setKeyColumnsToShow: (values: CheckboxValueType[]) => void
    closeSettingColumns: () => void
}

export const keyColumns: string[] = [
    "code",
    "system",
    "timestamp",
    "participant",
    "roles",
    "memo",
    // "error",
    "reviewStatus",
    "amount",
    "balance",
    "refBalance",
    "recharge",
    "transactionType",
    'm1Orders',
    // 'deliveryNote'
]

const keyColumnsMapping: any = {
    code: "Mã bút toán/Mã phiếu/Mã giao dịch/Mã tham chiếu",
    system: "Hệ thống",
    timestamp: "Thời gian giao dịch",
    participant: "Người nhận/nộp",
    roles: "Vai trò",
    memo: "Nội dung giao dịch",
    error: "Lỗi",
    reviewStatus: "Trạng thái",
    amount: "Số tiền",
    balance: "Số dư",
    refBalance: "Số dư từ SMS",
    recharge: "Giao dịch đã xử lý",
    transactionType: 'Loại GD',
    m1Orders: 'Mã đơn',
    // deliveryNote: 'Mã phiếu xuất'

}

export const ModalSettingColumns: FC<IProps> = ({
    keyColumnsToShow,
    setKeyColumnsToShow,
    closeSettingColumns,
    ...props
}) => {
    const appContext = useContext(AppContext)

    const keyColumnsCodeToShow = useMemo(() => {
        const keys: string[] = [...keyColumns]
        if (appContext.state.account.type !== 'BANK') {
            keys.splice(10, 1)
        }

        return keys
    }, [appContext.state.account.type])

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(keyColumnsToShow)

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list)
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? keyColumnsCodeToShow : ['code'])
    }

    const onOk = () => {
        setKeyColumnsToShow(checkedList)
        closeSettingColumns()
        localStore.setJson('transaction-columns', checkedList)
    }

    useEffect(() => {
        if (props.visible) {
            setCheckedList(keyColumnsToShow)
        }
    }, [props.visible, keyColumnsToShow])

    return (
        <Modal title="Cài đặt hiển thị" {...props} width={620} onOk={onOk} onCancel={closeSettingColumns}>
            <div className="space-between mg-bt-6 pd-l-15">
                <Checkbox
                    indeterminate={checkedList.length !== keyColumnsCodeToShow.length}
                    onChange={onCheckAllChange}
                    checked={checkedList.length === keyColumnsCodeToShow.length}
                >
                    Chọn tất cả
                </Checkbox>
                <span className="text-secondary-color-2">
                    Đã chọn: {checkedList.length}/{keyColumnsCodeToShow.length}
                </span>
            </div>

            <CheckboxGroup value={checkedList} onChange={onChange}>
                <Row>
                    {keyColumnsCodeToShow.map((keyColumn: string, index: number) => (
                        <Col xs={12} sm={(index % 2) ? 8 : 16} key={keyColumn} className="align-base-line mg-bt-4">
                            <i className="fa-solid fa-grip-dots-vertical mg-r-8 text-secondary-color-2" />
                            <Checkbox disabled={keyColumn === 'code'} value={keyColumn}>{keyColumnsMapping[keyColumn]}</Checkbox>
                        </Col>
                    ))}
                </Row>
            </CheckboxGroup>
        </Modal>
    )
}
