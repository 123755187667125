import React from 'react';
import {Link} from 'react-router-dom';

class Notfound extends React.Component {

    render() {
        return (
            <div className="error-page">
                <h2 className="headline text-yellow"> 404</h2>
                <div className="error-content">
                    <h3><i className="fa fa-warning text-yellow"/>Không tìm thấy trang yêu cầu</h3>
                    <div>Rất tiếc chúng tôi không tìm thấy trang mà bạn yêu cầu</div>
                    <div><Link to={"/"}>Trở lại trang chủ</Link></div>
                </div>
            </div>
        );
    }
}

export default Notfound;