import {Component} from "react";
import {Button, Tooltip} from 'antd';
import lodash from 'lodash';
import moment from "moment";
import RoleDomain from "../../Domain/RoleDomain";
import SecurityService from "../../Util/SecurityService";
import {PERMISSIONS} from "../../Util/Constants";
import CommonTable from "../../Component/CommonTable";

interface Props {
    loading?: boolean,
    items: Array<RoleDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize: number) => void,
    toggleUpdateModal: (role: RoleDomain) => void,
    toggleGrantPermissionModal: (row: RoleDomain) => void
}

interface State {
    loading: boolean,
    columns: Array<any>
}

class DataList extends Component<Props, State> {

    state: State = {
        loading: false,
        columns: [
            {
                title: "Mã",
                key: "code",
                dataIndex: 'code',
                render: (code: string, row: any) => <span onClick={() => this.props.toggleUpdateModal(row)}
                                                className="blue-link pointer">{code}</span>
            },
            {
                title: "Tên",
                key: "name",
                dataIndex: 'name'
            },
            {
                title: <div style={{textAlign:'right'}}>Ngày tạo</div>,
                key: "createdAt",
                dataIndex: 'createdAt',
                render: (createdAt: any) => <div style={{textAlign:'right'}}>{moment(createdAt).format('DD/MM/YYYY')}</div>
            },
            {
                title: "Người tạo",
                key: "createdBy",
                dataIndex: 'createdBy'
            },
            {
                title: <div style={{textAlign:'center'}}>Trạng thái</div>,
                key: "status",
                dataIndex: 'status',
                render: (status: any) => <>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Tooltip title={status} placement={'right'}>
                            <span style={{color: status === 'ENABLED' ? '#27ae61' : '#707070'}}><i style={{fontSize:16}} className="fa-solid fa-circle-check"/></span>
                        </Tooltip>
                    </div>
                    {/*<Switch size={'small'} disabled={this.state.loading} loading={this.state.loading} onChange={() => {}} checked={status === 'ENABLED'}/>*/}
                </>
            },
            {
                title: <div style={{textAlign:'center'}}>Phân quyền</div>,
                key: "grant_permission",
                render: (row: any) => {
                    if (SecurityService.can(PERMISSIONS.PERMISSION_GRANT)) {
                        return (<div style={{display:'flex',justifyContent:'center'}}>
                            <Button type={'link'} onClick={() => this.props.toggleGrantPermissionModal(row)}>
                                <Tooltip title={'Phân quyền'}><i className="fa-regular fa-sliders"/></Tooltip>
                            </Button>
                        </div>)
                    }
                }
            }
        ]
    }

    handleChangePage = (page: number, pageSize : number) => {
        this.props.onChangePage(page, pageSize)
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state;
        return (
            <div>
                <CommonTable
                    className={'a-table'}
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        onChange: this.handleChangePage,
                        showSizeChanger:true,
                        pageSizeOptions:[10,20,25,50,100],
                        showLessItems:true,
                    }}
                    emptyText="Danh sách vai trò đang trống"
                    rowClassName={'role-record'}
                    scroll={{y:window.innerHeight - 300,x:767}}
                />
            </div>
        );
    }
}

export default DataList;
