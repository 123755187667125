import React from 'react';
import {AppContext} from '../../Context/AppContext';
import AccountBoxStatistic from "../../Component/AccountBoxStatistic";

interface Props {}

class AccountBoxStatisticConsumer extends React.Component<Props, any> {

    render() {
        return (
            <AppContext.Consumer>
                {context => {
                    const {account} = context.state;
                    return (
                        <AccountBoxStatistic
                            account={account}
                        />
                    )
                }}
            </AppContext.Consumer>
        );
    }
}

export default AccountBoxStatisticConsumer;