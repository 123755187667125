import apiClient from "../Util/ApiClient";
import PaymentVoucherCreateCommand from "../Command/PaymentVoucherCreateCommand";
import PaymentVoucherQueryCriteria from "../QueryCriteria/PaymentVoucherQueryCriteria";
import PaymentVoucherUpdateCommand from "../Command/PaymentVoucherUpdateCommand";

export default class PaymentVoucherApi {

    static getPaymentVouchersByAccount = (account: any, query: PaymentVoucherQueryCriteria) => {
        return apiClient.get(`/accounts/${account}/payment-vouchers`, {
            params: query
        })
    };

    static create = (command: PaymentVoucherCreateCommand) => {
        return apiClient.post('/payment-vouchers', command);
    }

    static update = (code: string, data: PaymentVoucherUpdateCommand) => {
        return apiClient.patch('/payment-vouchers/'+ code, data);
    }

    static reject = (code: string) => {
        return apiClient.post(`/payment-vouchers/${code}/reject`)
    }

    static approve = (code: string) => {
        return apiClient.post(`/payment-vouchers/${code}/approve`)
    }

    static getByCode = (code: any) => {
        return apiClient.get(`/payment-vouchers/${code}`);
    }

    static getWarnings = (code: string, warning: string) => {
        return apiClient.get(`/payment-vouchers/${code}/warnings`, {
            params: {warning}
        });
    }

    static review = (code:string) => {
        return apiClient.post(`/payment-vouchers/${code}/review`);
    }
}
