import { LogQueryCriteria } from "../QueryCriteria/LogQueryCriteria"
import apiClient from "../Util/ApiClient"

export default class LogApi {
    static getLogs = (params: LogQueryCriteria) => {
        return apiClient.get(`logs`, {
            params: {
                offset: 0,
                limit: 1000,
                sort: "timestamp:desc",
                ...params,
            },
        })
    }
}
