import React from 'react'
import {GetComponentProps} from 'rc-table/lib/interface'

interface TransactionCustomRowProps extends GetComponentProps<any> {
    index?: number,
    children?: any,
    record?: any,
    isRowExpanded?: boolean
    focusRow?: boolean
    rowHasError?: boolean
}

function TransactionCustomRow(props: TransactionCustomRowProps) {
    const {
        children, index, isRowExpanded,focusRow,rowHasError
    } = props;

    return (<>
        <tr className={`${index! % 2 !== 0 ? "row-dark" : 'row-light'} ${rowHasError ? 'row-has-error': ''} ant-table-row ${focusRow ? 'transaction-focus-row' : ''} ${isRowExpanded ? 'active-row-key' : ''}`}>
            {children}
        </tr>
    </>)
}

export default TransactionCustomRow;
