import { Component } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import Utils from '../../Util/Utils'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import SelectRoleStatus from '../../Component/SelectRoleStatus'
import SelectSuggestStaff from '../../Component/SelectSuggestStaff'

interface FormInterface {
    name?: string
    code?: string
    status?: string
    createdBy?: string
}

interface Props extends DefaultPropsInterface {
    handleCollapseFilter: () => void
}

interface State {
    form: FormInterface
}

class FilterForm extends Component<Props, State> {
    state: State = {
        form: {
            ...Utils.getQueryFromLocation(this.props.location),
        },
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.setState({ form: Utils.getQueryFromLocation(this.props.location) })
        }
    }

    handleChangeInput = (field: string, e: any) => {
        const form: any = this.state.form
        form[field] = e.target.value
        this.setState({ form })
    }

    handleChangeSelect = (field: string, value: any) => {
        const form: any = this.state.form
        form[field] = value
        this.setState({ form })
        this.handleFilter()
    }

    handleFilter = () => {
        let newFilter = {
            ...this.state.form,
            offset: 0,
            page: 1,
        }
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, newFilter)
    }

    clearFilter = () => {
        this.setState({
            form: {},
        })
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {})
    }

    render() {
        const form: FormInterface = this.state.form
        const disableSubmit: boolean = Utils.checkDisableSubmit(form)

        return (
            <Form
                layout={'horizontal'}
                labelAlign={'left'}>
                <Row gutter={20}>
                    <Col
                        xs={24}
                        lg={6}>
                        <Form.Item label={'Tên'}>
                            <Input
                                autoFocus
                                className={'search-input'}
                                allowClear
                                placeholder={'Tìm kiếm theo tên'}
                                value={form.name}
                                onChange={this.handleChangeInput.bind(this, 'name')}
                                onPressEnter={() => this.handleFilter()}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        lg={7}
                        xl={6}>
                        <Form.Item label={'Người tạo'}>
                            <SelectSuggestStaff
                                value={form.createdBy}
                                onChange={this.handleChangeSelect.bind(this, 'createdBy')}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        lg={8}
                        xl={6}>
                        <Form.Item label={'Trạng thái'}>
                            <SelectRoleStatus
                                value={form.status}
                                onChange={this.handleChangeSelect.bind(this, 'status')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="flex space-between items-center">
                    <span
                        onClick={this.props.handleCollapseFilter}
                        className={'font-size-12 cursor-pointer'}>
                        <i className="fa-solid fa-chevron-up mg-r-3" /> Thu gọn
                    </span>

                    <div>
                        {!disableSubmit && (
                            <Button
                                type={'text'}
                                onClick={this.clearFilter}
                                className="font-size-12px">
                                <i className="fa-regular fa-repeat mg-r-5" />
                                Làm mới bộ lọc
                            </Button>
                        )}

                        <Button
                            type={'primary'}
                            onClick={this.handleFilter}
                            disabled={disableSubmit}>
                            Tìm kiếm
                        </Button>
                    </div>
                </div>
            </Form>
        )
    }
}

export default FilterForm
