import apiClient from '../Util/ApiClient'
import SuggestStaffQueryCriteria from '../QueryCriteria/SuggestStaffQueryCriteria'
import { StaffQueryCriteria } from '../QueryCriteria/StaffQueryCriteria'
import { AxiosResponse } from 'axios'
import AccountDomain, { IPrivilage } from '../Domain/AccountDomain'

export default class StaffApi {
    static getSuggestStaff(query: SuggestStaffQueryCriteria) {
        return apiClient.get('/staffs/autocomplete', {
            params: { ...query },
        })
    }

    static getStaffs(query: StaffQueryCriteria) {
        return apiClient.get('/staffs', {
            params: { ...query },
        })
    }

    static getStaffByUsername(username: any) {
        return apiClient.get(`/staffs/${username}`)
    }

    static getAccountsByUsername(username: any): Promise<
        AxiosResponse<{
            accounts: {
                owner: boolean
                privilege: IPrivilage
                account: AccountDomain
            }[]
        }>
    > {
        return apiClient.get(`/staffs/${username}/accounts`, {
            params: { sort: 'createdAt:desc' },
        })
    }
}
