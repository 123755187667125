import { Badge, Button, Modal, notification } from 'antd'
import { AxiosError } from 'axios'
import lodash from 'lodash'
import React from 'react'
import DeviceApi from '../../Api/DeviceApi'
import DefaultComponent from '../../Component/DefaultComponent'
import DefaultLayout from '../../Component/Layout/Default'
import QrCode from '../../Component/QrCode'
import DeviceDomain, { DeviceModel } from '../../Domain/DeviceDomain'
import DataList from './DataList'
import DeviceCreate from './DeviceCreate'
import FilterForm from './FilterForm'
import SenderConfigModal from './SenderConfigModal'
import { AppContext } from '../../Context/AppContext'
import Utils from '../../Util/Utils'
import { PERMISSIONS } from '../../Util/Constants'
import SecurityService from '../../Util/SecurityService'
import AccountApi from '../../Api/AccountApi'

import './style.scss'
import AccountDomain from '../../Domain/AccountDomain'
import { NotificationCommon } from '../../Component/Notification'

interface State {
    loading: boolean
    devices: Array<DeviceDomain>
    total: number
    pageSize: number
    currentPage: number
    showModalCreate: boolean
    showQrCodeModal: boolean
    showSenderConfigModal: boolean
    newDevice: DeviceDomain
    selectedDevice: DeviceDomain
    selectedRowKeys: Array<string>
    accounts: Array<AccountDomain>
}

interface Props {}

class DeviceList extends DefaultComponent<Props, State> {
    static contextType = AppContext

    state = {
        loading: false,
        devices: [],
        pageSize: 25,
        total: 0,
        currentPage: 1,
        showModalCreate: false,
        showQrCodeModal: false,
        showSenderConfigModal: false,
        newDevice: { ...DeviceModel },
        selectedDevice: { ...DeviceModel },
        selectedRowKeys: [],
        accounts: [],
    }

    componentDidMount() {
        this.fetchDevices({
            ...this.getQueryFromLocation(),
        })
        this.fetchMyAccounts()
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchDevices({
                ...this.getQueryFromLocation(),
            })
        }
    }

    fetchDevices = (filter: any = {}) => {
        this.setState({ loading: true })
        filter = {
            offset: 0,
            limit: this.state.pageSize,
            sort: 'createdAt:desc',
            ...filter,
        }

        DeviceApi.filter(filter)
            .then((response) => {
                this.setState({
                    currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                    pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                    total: parseInt(lodash.get(response, 'headers.x-total-count')),
                    devices: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchMyAccounts = (query = {}) => {
        query = {
            offset: 0,
            limit: 1000,
            ...query,
        }
        this.setState({ loading: true })
        AccountApi.autocomplete(query)
            .then((response) => {
                this.setState({
                    accounts: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onChangePage = (page: number, pageSize: number = 25) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize,
        })
        this.setState({ pageSize })
    }

    toggleModalCreate = () => {
        this.setState({
            showModalCreate: !this.state.showModalCreate,
        })
    }

    toggleQrCodeModel = () => {
        this.setState(
            {
                showQrCodeModal: !this.state.showQrCodeModal,
            },
            () => {
                if (!this.state.showQrCodeModal) {
                    this.setState({
                        newDevice: { ...DeviceModel },
                    })
                }
            }
        )
    }

    handleCreateSuccess = (device: DeviceDomain) => {
        notification.warn({
            message: 'Vui lòng quét mã QR để tạo thiết bị',
        })
        this.toggleModalCreate()
        this.fetchDevices({})
        this.setState({ newDevice: { ...device } }, () => {
            this.toggleQrCodeModel()
        })
    }

    handleCreateFail = (error: AxiosError) => {
        if (error.response?.status === 400) {
            NotificationCommon.error({
                message: 'Tạo thiết bị không thành công',
            })
        } else {
            NotificationCommon.error({
                message: 'Tạo thiết bị không thành công. Lỗi: ' + error.message,
            })
        }
    }

    handleModalCreateVisibleChange = (visible: boolean) => {
        this.setState({ showModalCreate: visible })
    }

    generateQrCode = (device: DeviceDomain) => {
        return JSON.stringify({
            ...device,
            tenant: Utils.getAppContext(this).state.tenant.code,
        })
    }

    handleSenderConfigModalVisibleChange = (device: DeviceDomain, visible: boolean) => {
        this.setState(
            {
                selectedDevice: { ...device },
                showSenderConfigModal: visible,
            },
            () => {
                if (!this.state.showSenderConfigModal) {
                    this.setState({
                        selectedDevice: { ...DeviceModel },
                    })
                }
            }
        )
    }

    handleSenderConfigSuccess = () => {
        this.setState({
            selectedDevice: { ...DeviceModel },
        })
    }

    handleClickDownloadApp = () => {
        let url = process.env.APP_DOWNLOAD_URL || 'https://downloads.gobiz.vn/gobiz-sms-app'
        let features = undefined
        window.open(url, '_blank', features)
    }

    render() {
        const { devices, currentPage, pageSize, total, loading, showModalCreate, newDevice, showQrCodeModal, showSenderConfigModal, selectedDevice, accounts } =
            this.state

        return (
            <DefaultLayout
                {...this.props}
                title={`Danh sách thiết bị`}
                breadcrumb={[{ title: 'Danh sách thiết bị' }]}>
                <div className="main-content device-page">
                    <div className="white-box py-12 px-12">
                        <FilterForm {...this.props} />
                    </div>

                    <div className="white-box py-12 px-12">
                        <div className="table-container">
                            <div className="table-header mg-bt-12">
                                <div className="align-center font-medium fsz-16px capitalize">
                                    Danh sách thiết bị{' '}
                                    <Badge
                                        count={total}
                                        showZero
                                        overflowCount={999999}
                                        className="pd-l-8"
                                    />
                                </div>
                                <div className="table-options">
                                    <Button
                                        type={'default'}
                                        className={'mg-r-10'}
                                        icon={<i className="fa-solid fa-download mg-r-5" />}
                                        onClick={this.handleClickDownloadApp}>
                                        <span className={'capitalize font-medium'}>Tải app</span>
                                    </Button>
                                    {SecurityService.can(PERMISSIONS.DEVICE_CREATE) && (
                                        <Button
                                            type={'primary'}
                                            onClick={this.toggleModalCreate}
                                            icon={<i className="fa-regular fa-plus pd-r-8" />}>
                                            Tạo thiết bị
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <DataList
                                loading={loading}
                                items={devices}
                                total={total}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onChangePage={this.onChangePage}
                                onSenderConfigVisibleChange={this.handleSenderConfigModalVisibleChange}
                                getDevices={this.fetchDevices}
                            />
                        </div>
                    </div>
                </div>

                {showModalCreate && (
                    <DeviceCreate
                        onCreateSuccess={this.handleCreateSuccess}
                        onCreateFail={this.handleCreateFail}
                        onVisibleChange={this.handleModalCreateVisibleChange}
                    />
                )}

                {showQrCodeModal && newDevice.code && (
                    <Modal
                        visible={showQrCodeModal}
                        destroyOnClose={true}
                        closeIcon={<i className="fa-solid fa-xmark" />}
                        title={`Device ${newDevice.name} - Qr Code`}
                        onCancel={() => this.toggleQrCodeModel()}
                        okButtonProps={{
                            style: {
                                display: 'none',
                            },
                        }}
                        centered
                        cancelButtonProps={{
                            icon: <i className="fa-solid fa-xmark pd-r-8" />,
                            type: 'ghost',
                        }}>
                        <div className="text-center">
                            <QrCode
                                value={this.generateQrCode(newDevice)}
                                width={150}
                                height={150}
                            />
                        </div>
                    </Modal>
                )}

                {showSenderConfigModal && selectedDevice.code && (
                    <SenderConfigModal
                        deviceCode={selectedDevice.code}
                        onVisibleChange={(visible) => this.handleSenderConfigModalVisibleChange(selectedDevice, visible)}
                        onConfigSuccess={this.handleSenderConfigSuccess}
                        accounts={accounts}
                    />
                )}
            </DefaultLayout>
        )
    }
}

export default DeviceList
