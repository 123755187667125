import React from "react";
import {Radio, RadioChangeEvent, SelectProps} from "antd";

interface Props {
    value?: any,
    onChange?: (value: any) => void,
    selectProps?: SelectProps
    type?:string,
}

interface State {
    loading: boolean,
    items: Array<Object>
}

class SelectRoleStatus extends React.Component<Props, State> {

    state = {
        loading: false,
        items: [
            {value: '', label: 'Tất cả'},
            {value: 'ENABLED', label: 'Bật'},
            {value: 'DISABLED', label: 'Tắt'},
        ]

    }

    componentDidMount() {
        if(this.props.type && this.props.type === 'update') {
            this.setState({items:[
                    {value: 'ENABLED', label: 'Bật'},
                    {value: 'DISABLED', label: 'Tắt'},
                ]})
        }
    }

    getRadioValue = () => {
        if(this.props.value === null || undefined || '' )
            return ''
        else return this.props.value
    }

    render() {
        const {onChange, value} = this.props;
        const {items} = this.state;
        return (<>
            <Radio.Group
                options={items}
                onChange={(e:RadioChangeEvent) => onChange ? onChange(e.target.value) : null}
                value={value ? value : ''}
                defaultValue={''}
            >
            </Radio.Group>
        </>
        );
    }
}

export default SelectRoleStatus;
