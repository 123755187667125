import {DatePicker, Form, Input, Modal} from 'antd';
import React from 'react';
import {get} from 'lodash';
import moment from 'moment';
import AccountApi from "../../../Api/AccountApi";
import {AxiosError, AxiosResponse} from "axios";
import AccountDomain from "../../../Domain/AccountDomain";
import Utils from "../../../Util/Utils";

interface Props {
    account: AccountDomain,
    onVisibleChange: (visible: boolean) => void,
    onSuccess: (response: AxiosResponse) => void,
    onFail: (error: AxiosError) => void
}

interface State {
    loading: boolean,
    balance: number,
    disableSubmit: boolean
}

export interface SettlementForm {
    name: string,
    settlementAt: string
}

const defaultSettlementForm: SettlementForm = {
    name: "",
    settlementAt: '',
}

class SettlementModal extends React.Component<Props, State> {

    formRef: any = React.createRef();

    state = {
        loading: false,
		disableSubmit: true,
        balance: 0,
        form: {...defaultSettlementForm}
    }

    handleFormValuesChange = (_: any, allValues: any) => {
        const {account} = this.props;
        let {settlementAt, name} = allValues

        if(!settlementAt) {
            settlementAt = moment(new Date().toDateString())
        }

        AccountApi.enquireBalance(account.code, settlementAt.toISOString())
            .then(response => {
                this.setState({
                    balance: response.data.balance
                })
            })

        this.setState({
            disableSubmit: !name || !settlementAt
        })
    }

    handleOk = () => {
        const {account} = this.props;
        const {balance} = this.state;
        this.formRef.current.validateFields()
            .then((values: SettlementForm) => {
                this.props.onVisibleChange(false);
                Modal.confirm({
                    title: `Bạn có chắc chắn muốn thực hiện chốt sổ với số dư ${Utils.currencyFormatByCurrency(balance, get(account, 'currency.code'))} tại thời điểm ${moment(values.settlementAt).format('DD/MM/YYYY HH:mm')} không?`,
                    onOk: () => {
                        this.setState({loading: true});
                        AccountApi.settlement(account.code, values)
                            .then(response => {
                                this.props.onSuccess(response);
                            })
                            .catch(error => {
                                this.props.onFail(error);
                            })
                            .finally(() => {
                                this.setState({loading: false});
                            })
                    },
                    onCancel: () => {
                        this.handleCancel();
                    },
                    okButtonProps: {
                        icon: <i className="fa-solid fa-check pd-r-8" />
                    },
                    cancelButtonProps: {
                        icon: <i className="fa-solid fa-xmark pd-r-8" />,
                        type: 'ghost'
                    }
                })
            })
    }

    handleCancel = () => {
        this.props.onVisibleChange(false);
    }

    disabledDate = (current: any) => {
        return current && current > moment().add(1, 'day').startOf('day');
    }

    render() {
        const {account} = this.props;
        const {loading, balance, disableSubmit} = this.state;
        return (
            <Modal
                visible={true}
                title={`Chốt sổ - Tài khoản ${account.code}`}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
				width={660}
				okText={"Xác Nhận"}
				closeIcon={<i className="fa-solid fa-xmark"  />}
				cancelText={"Hủy Bỏ"}
				centered
                okButtonProps={{
                    loading: loading,
                    disabled: loading || disableSubmit,
					icon: <i className="fa-solid fa-check pd-r-8" />
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
					icon: <i className="fa-solid fa-xmark pd-r-8" />,
                    type: 'ghost'
                }}
            >

                <Form
                    ref={this.formRef}
                    onValuesChange={this.handleFormValuesChange}
                    labelCol={{span: 5}}
                    labelAlign="left"
                >
                    <div className={'mg-bt-10'}>
                        <b>Số dư ({get(account, 'currency.code')}): </b>{Utils.currencyFormatByCurrency(balance, get(account, 'currency.code'))}
                    </div>

                    <Form.Item
                        label={'Thời gian chốt'}
                        required={true}
                        name={'settlementAt'}
                        rules={[
                            {required: true, message: "Thời gian chốt không được để trống"},
                        ]}
                        initialValue={moment()}
                    >
                        <DatePicker
                            format={"DD/MM/YYYY HH:mm"}
                            showTime={true}
                            className={'width-100pc'}
                            disabledDate={this.disabledDate}
                            placeholder="Vui lòng chọn thời gian chốt"
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Nội dung chốt'}
                        required={true}
                        name={'name'}
                        rules={[
                            {required: true, message: "Nội dung chốt không được để trống"}
                        ]}
                        className="mg-bt-0"
                    >
                        <Input placeholder='Vui lòng nhập nội dung chốt' onPressEnter={this.handleOk} autoFocus/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default SettlementModal;
