import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Popover, Tag, Tooltip, Typography } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import MessageDomain from '../../Domain/MessageDomain'
import { ACCOUNT_PERMISSIONS, MESSAGE_STATUS_TOOLTIP } from '../../Util/Constants'
import AccountDomain from '../../Domain/AccountDomain'
import CommonTable from '../../Component/CommonTable'
import { LogModal } from '../../Component/LogModal'
import { LogResrouceType } from '../../Interface/LogInterface'
import { ListItem } from './LogListItem'
import { LogModalRef } from '../../Component/LogModal/type'
import SecurityService from '../../Util/SecurityService'
import { ColumnsType } from 'antd/lib/table'
import ProjectDomain from '../../Domain/ProjectDomain'

interface Props {
    isNoAccountSMS: boolean
    loading?: boolean
    items: Array<MessageDomain>
    account: AccountDomain
    total: number
    pageSize: number
    currentPage: number
    onChangePage: (page: number, pageSize?: number) => void
    onRetryAnalyticMessage: (message: MessageDomain) => void
    onModalCreatePaymentVoucherVisibleChange: (messageId: string, visible: boolean) => void
    onModalCreateReceiptVoucherVisibleChange: (messageId: string, visible: boolean) => void
    onModalUpdateRemarkVisibleChange: (message: MessageDomain, visible: boolean) => void
}

interface State {
    projects: ProjectDomain[]
}

const renderTooltipStatus = () => {
    return (
        <div>
            {Object.keys(MESSAGE_STATUS_TOOLTIP).map((status, index) => (
                <div key={`status_${status}`}>
                    {status}: {lodash.get(MESSAGE_STATUS_TOOLTIP, status)}
                </div>
            ))}
        </div>
    )
}

class DataList extends Component<Props, State> {
    logRef = createRef<LogModalRef>()
    columns: ColumnsType<any> = []
    constructor(props: Props) {
        super(props)
        const { isNoAccountSMS } = props
        this.columns = [
            {
                title: isNoAccountSMS ? 'Mã sms' : 'TK quỹ / Mã GD / Mã sms',
                key: 'transactionAccount',
                render: (row: any) => (
                    <div className={'font-size-14 data'}>
                        {!isNoAccountSMS && (
                            <>
                                <div className={'message-table-row-item'}>
                                    <Tooltip title={'Tài khoản quỹ'}>
                                        <i className="fa-regular fa-money-check-dollar" />
                                        <span className={'text-dark-2'}>
                                            {this.props.account.name} ({lodash.get(row, 'account')})
                                        </span>
                                    </Tooltip>
                                    <Typography.Paragraph
                                        className={''}
                                        copyable={{
                                            text: lodash.get(row, 'account'),
                                            icon: [<i className="far fa-copy" />, <i className="fa-solid fa-check" />],
                                        }}
                                    />
                                </div>
                                <div className={'message-table-row-item'}>
                                    <Tooltip title={'Mã giao dịch'}>
                                        <i className="fa-regular fa-rectangle-barcode" />{' '}
                                        {row.transaction ? (
                                            <Link
                                                className={'font-14px blue-link'}
                                                to={`/transactions?query=${row.transaction}`}>
                                                {row.transaction}
                                            </Link>
                                        ) : (
                                            '--'
                                        )}
                                    </Tooltip>
                                    <Typography.Paragraph
                                        className={'flr'}
                                        copyable={{
                                            text: row.transaction,
                                            icon: [<i className="far fa-copy" />, <i className="fa-solid fa-check" />],
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <div className={'message-table-row-item'}>
                            <Tooltip title={'Mã tin nhắn'}>
                                <i className="fa-regular fa-message-sms" />
                                <span className={'text-dark-2'}>{lodash.get(row, 'id')}</span>
                            </Tooltip>
                            <Typography.Paragraph
                                className={'flr'}
                                copyable={{
                                    text: lodash.get(row, 'id'),
                                    icon: [<i className="far fa-copy" />, <i className="fa-solid fa-check" />],
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                title: 'Nhận sms/Hệ thống nhận',
                key: 'time',
                render: (row: any) => (
                    <div className={'font-size-14'}>
                        <div>
                            <Tooltip title={'Nhận sms'}>{moment(row.messageIncomingAt).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>
                        </div>
                        <div>
                            <Tooltip title={'Hệ thống nhận'}>{moment(row.timestamp).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Hệ thống/Username',
                key: 'username',
                render: (record: any) => {
                    const customer = lodash.get(record, 'messageData.dataCustomer')
                    const project = lodash.get(record, 'messageData.dataProject')

                    let customerLink = `/transactions?participant=c:${project}:${customer}&participantType=inside&project=${project}&participantUsername=${customer}`

                    return (
                        <div>
                            <div className={'font-size-14'}>
                                <Tooltip title={'Hệ thống'}>{project}</Tooltip>
                            </div>
                            <div className={'font-size-14'}>
                                <Tooltip title={'Username'}>
                                    {customerLink ? (
                                        <Link
                                            target={'_blank'}
                                            to={customerLink}>
                                            {customer}
                                        </Link>
                                    ) : (
                                        customer
                                    )}
                                </Tooltip>
                            </div>
                        </div>
                    )
                },
            },
            {
                title: 'Thiết bị/Sim/Sender/Type',
                key: 'device',
                render: (row: any) => {
                    return (
                        <>
                            <div className={'font-size-14 message-table-row-item'}>
                                <Tooltip title={'Thiết bị'}>
                                    <i className="fa-regular fa-desktop" /> <span className={'text-dark-2'}>{lodash.get(row, 'device.name')}</span>
                                </Tooltip>
                                <Typography.Paragraph
                                    className={''}
                                    copyable={{
                                        text: lodash.get(row, 'device.name'),
                                        icon: [<i className="far fa-copy" />, <i className="fa-solid fa-check" />],
                                    }}
                                />
                            </div>
                            <div className={'font-size-14 message-table-row-item'}>
                                <Tooltip title={'Sim'}>
                                    <i className="fa-regular fa-sim-card pd-l-2" />
                                    <span className={'text-dark-2 mg-l-6'}>{lodash.get(row, 'sim')}</span>
                                </Tooltip>
                                <Typography.Paragraph
                                    className={''}
                                    copyable={{
                                        text: lodash.get(row, 'sim'),
                                        icon: [<i className="far fa-copy" />, <i className="fa-solid fa-check" />],
                                    }}
                                />
                            </div>
                            <div className={'font-size-14 message-table-row-item'}>
                                <Tooltip title={'Sender'}>
                                    <i className="fa-regular fa-paper-plane" /> <span className={'text-dark-2'}>{lodash.get(row, 'sender')}</span>{' '}
                                </Tooltip>
                                <Typography.Paragraph
                                    className={''}
                                    copyable={{
                                        text: lodash.get(row, 'sender'),
                                        icon: [<i className="far fa-copy" />, <i className="fa-solid fa-check" />],
                                    }}
                                />
                            </div>
                            <div className={'font-size-14 message-table-row-item'}>
                                <Tooltip title={'Type'}>
                                    <i className="fa-regular fa-mobile" />{' '}
                                    <span className={'text-dark-2'}>{lodash.get(row, 'type') === 'APP' ? 'Banking App Notification' : 'SMS'}</span>{' '}
                                </Tooltip>
                            </div>
                        </>
                    )
                },
            },
            {
                title: 'Nội dung',
                key: 'content',
                dataIndex: 'content',
                render: (content: any, row: any) => (
                    <>
                        <div className="font-size-14 width-200px h-scroll">{content}</div>
                        <div className="font-12px text-danger">
                            Ghi chú GD: <b className={'italic'}>{row.remark}</b>
                        </div>
                    </>
                ),
            },
            {
                title: (
                    <span>
                        Trạng thái{' '}
                        <Tooltip overlayInnerStyle={{width: 282}} title={renderTooltipStatus()}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                ),
                key: 'status',
                dataIndex: 'status',
                render: (status: any) => {
                    let color = 'default'
                    if (status === 'SUCCESS') {
                        color = 'success'
                    } if (status === 'RESOLVED') {
                        color = '#0C66E4'
                    } else if (status === 'FAILED') {
                        color = 'error'
                    } else if (status === 'REJECTED') {
                        color = 'warning'
                    }
                    return <Tag color={color}>{status}</Tag>
                },
            },
            {
                title: 'Lý do lỗi cuối',
                key: 'failedReason',
                dataIndex: 'lastFailedReason',
                render: (lastFailedReason: any, row: any) => {
                    const { reason, guide } = getFailedReason(lastFailedReason || '', row)

                    return (
                        <>
                            {lastFailedReason && (
                                <>
                                    {reason}
                                    {!isNoAccountSMS && (
                                        <>
                                            <br />
                                            <Popover
                                                overlayClassName="max-width-350"
                                                content={guide}
                                                title={<>HD xử lý {reason}</>}
                                                placement="right"
                                                trigger="hover">
                                                <span className="text-danger pointer underline">HD xử lý</span>
                                            </Popover>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )
                },
            },
            {
                title: 'Tiến trình',
                key: 'process',
                render: (row: any) => {
                    if (row.process === 'PROCESSED') {
                        return <Link to={`/transactions?query=${row.transaction}`}>{row.process}</Link>
                    } else {
                        return <span>{row.process}</span>
                    }
                },
            },
            {
                title: '',
                width: 48,
                key: 'log',
                render: (row: MessageDomain) => (
                    <>
                        {SecurityService.allowTo(ACCOUNT_PERMISSIONS.MESSAGE_LOG_VIEW) && (
                            <Button
                                type="link"
                                className="text-blue font-size-12 underline"
                                onClick={() => this.openLogModal(row)}>
                                <i className="fa-regular fa-file-lines" /> Log
                            </Button>
                        )}
                    </>
                ),
            },
            {
                title: '',
                width: 48,
                key: 'actions',
                render: (row: any) => {
                    return (
                        <div className={'message-container__data-list-action-col'}>
                            {!row.transaction && (
                                <div>
                                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.MESSAGE_RETRY_ANALYSE) && (
                                        <Tooltip
                                            title={'Phân tích lại'}
                                            color={'#FDB924'}
                                            placement={'left'}>
                                            <div
                                                className={'message-container__data-list__action'}
                                                onClick={this.handleRetryAnalyticMessage.bind(this, row)}
                                                style={{ backgroundColor: '#FDB924' }}>
                                                <i className="fa-solid fa-arrow-rotate-right" />
                                            </div>
                                        </Tooltip>
                                    )}

                                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_CREATE) && (
                                        <Tooltip
                                            title={'Tạo phiếu thu'}
                                            color={'#339DFF'}
                                            placement={'left'}>
                                            <div
                                                className={'message-container__data-list__action'}
                                                onClick={() => this.props.onModalCreateReceiptVoucherVisibleChange(row.id, true)}
                                                style={{ backgroundColor: '#339DFF' }}>
                                                <i className="fa-solid fa-scroll" />
                                            </div>
                                        </Tooltip>
                                    )}

                                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_CREATE) && (
                                        <Tooltip
                                            title={'Tạo phiếu chi'}
                                            color={'#4DD69C'}
                                            placement={'left'}>
                                            <div
                                                className={'message-container__data-list__action'}
                                                onClick={() => this.props.onModalCreatePaymentVoucherVisibleChange(row.id, true)}
                                                style={{ backgroundColor: '#4DD69C' }}>
                                                <i className="fa-solid fa-memo" />
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                            {SecurityService.allowTo(ACCOUNT_PERMISSIONS.MESSAGE_UPDATE) && (
                                <div>
                                    <Tooltip
                                        title={'Ghi chú giao dịch'}
                                        color={'#3340B6'}
                                        placement={'left'}>
                                        <div
                                            className={'message-container__data-list__action'}
                                            onClick={() => this.props.onModalUpdateRemarkVisibleChange(row, true)}
                                            style={{ backgroundColor: '#3340B6' }}>
                                            <i className="fa-solid fa-note" />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )
                },
            },
        ]

        if (this.props.isNoAccountSMS) {
            this.columns = [this.columns[0], this.columns[1], this.columns[3], this.columns[4], this.columns[6]]
        }

        this.state = {
            projects: [],
        }
    }

    handleRetryAnalyticMessage = (message: MessageDomain) => {
        this.props.onRetryAnalyticMessage(message)
    }

    handleChangePage = (page: number, pageSize?: number) => {
        this.props.onChangePage(page, pageSize)
    }

    openLogModal = (record: any) => {
        const { account } = this.props
        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.SMS,
            resourceCode: record.id,
        })
    }

    render() {
        const { loading, total, pageSize, items, currentPage } = this.props

        return (
            <>
                <CommonTable
                    columns={this.columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        onChange: this.handleChangePage,
                    }}
                    emptyText="Danh sách tin nhắn đang trống"
                    rowClassName={'message-table-row'}
                />

                <LogModal
                    ref={this.logRef}
                    title="Log tin nhắn"
                    ListItemContent={ListItem}
                    isShowResourceCodeOnTitle={true}
                />
            </>
        )
    }
}

export default DataList

export const enum FailedReasonTemplate {
    MESSAGE_NO_FOUND = 'message_not_found',
    DUPLICATE_MESSAGE = 'duplicate_message',
    TEMPLATE_NOT_FOUND = 'template_not_found',
    TEMPLATE_NOT_MATCHES = 'template_not_matches',
    TIMESTAMP_INELIGIBLE = 'timestamp_ineligible',
    REF_EXIST = 'ref_exist',
    SOURCE_EXIST = 'source_exist',
    REJECT_DEBIT_TRANSACTION = 'reject_debit_transaction',
    PROJECT_INACTIVE = 'project_inactive',
}

type FailedReasonTranslate = {
    reason: string
    guide: string | JSX.Element
}

export const getFailedReason = (lastFailedReason: string, row?: any): FailedReasonTranslate => {
    if (lastFailedReason.includes(FailedReasonTemplate.MESSAGE_NO_FOUND)) {
        return {
            reason: 'SMS không tồn tại trong CSDL',
            guide: 'Bấm phân tích lại',
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.DUPLICATE_MESSAGE)) {
        let duplicatedSMSCode: string =
            row.lastFailedReason &&
            (row.lastFailedReason as string).substring(row.lastFailedReason.search(`duplicate with messages`) + 'duplicate with messages'.length)
        return {
            reason: 'Trùng SMS',
            guide: `Trùng nội dung với mã SMS ${duplicatedSMSCode?.replaceAll(',', ', ')}. Kiểm tra lại SMS để xử lý thủ công nếu đây là SMS khác`,
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.TEMPLATE_NOT_FOUND)) {
        return {
            reason: 'Không nhận diện được mẫu SMS',
            guide: 'Gửi lại SMS cho kỹ thuật để được hỗ trợ đồng thời tạo phiếu thu/chi ngay tại SMS này để được xử lý ngay',
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.TEMPLATE_NOT_MATCHES)) {
        return {
            reason: 'Mẫu SMS chưa hỗ trợ',
            guide: 'Gửi lại SMS cho kỹ thuật để được hỗ trợ đồng thời tạo phiếu thu/chi ngay tại SMS này để được xử lý ngay',
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.TIMESTAMP_INELIGIBLE)) {
        return {
            reason: 'Lỗi thời gian nhận SMS < Thời gian chốt sổ lần cuối',
            guide: 'Liên hệ kỹ thuật để được hỗ trợ',
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.REF_EXIST)) {
        let refExistedTransaction = row.lastFailedReason?.slice(-16, -1).replace(/'/, '')
        return {
            reason: 'Giao dịch bị trùng mã bút toán',
            guide: `Mã bút toán đã tồn tại trong giao dịch ${refExistedTransaction}. Kiểm tra lại SMS có mã bút toán trùng để tạo phiếu thu/chi nếu đây là hai khoản khác nhau`,
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.SOURCE_EXIST)) {
        return {
            reason: 'SMS đã phát sinh 2 giao dịch khác nhau',
            guide: (
                <>
                    - Kiểm tra lại các giao dịch phát sinh từ SMS thông qua Mã SMS
                    <br />- Truy thu nếu phát sinh tài chính 2 lần
                </>
            ),
        }
    }
    if (lastFailedReason.includes(FailedReasonTemplate.REJECT_DEBIT_TRANSACTION)) {
        return {
            reason: 'Không phát sinh giao dịch trừ tiền tự động do cấu hình',
            guide: 'Tạo phiếu chi thủ công.',
        }
    }

    if (lastFailedReason.includes(FailedReasonTemplate.PROJECT_INACTIVE)) {
        return {
            reason: 'Hệ thống ngừng hoạt động',
            guide: 'Liên hệ kỹ thuật để được hỗ trợ.',
        }
    }

    return { reason: 'Lỗi không xác định', guide: 'Liên hệ kỹ thuật để được hỗ trợ' }
}
