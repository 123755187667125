import { FC, memo } from "react"
import ReceiptVoucherDetail from "."
import { LogItemInterface } from "../../../Component/LogModal/type"
import { ChangedValuesInterface, LogInterface, ReceiptVoucherEvent } from "../../../Interface/LogInterface"
import Utils from "../../../Util/Utils";
import AccountDomain from "../../../Domain/AccountDomain";
import { get } from "lodash";
import { LogChangedIcon } from "../../../Component/LogModal/ChangedIcon";

interface ListItemProps extends LogItemInterface<LogInterface<ReceiptVoucherEvent, ReceiptVoucherDetail>> {
    account: AccountDomain
}

const ListItem: FC<ListItemProps> = memo(
    ({ item, logQuery, changedValues, account }: ListItemProps) => {
        const currency = get(account, 'currency.code');

        const renderUpdateItem = () => {
            if (item.activity !== ReceiptVoucherEvent.UPDATE) {
                return
            }

            const parseChangedValues: ChangedValuesInterface[] = handleParseChangedValues(changedValues, currency)

            return (
                <>
                    <span>Cập nhật phiếu thu</span>
                    {parseChangedValues.map(({ left, right, key }: ChangedValuesInterface, index: number) => (
                        <span key={`value_${index}`}>
                            <br />
                            {titleFieldsMapping[key] || key} {": "}
                            <span className="font-medium">{left || '---'}</span>
                            <LogChangedIcon /><span className="font-medium">{right || "---"}</span>
                        </span>
                    ))}
                </>
            )
        }

        return (<div> {
            {
                [ReceiptVoucherEvent.CREATE]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã được tạo.
                    </span>
                ),
                [ReceiptVoucherEvent.UPDATE]: renderUpdateItem(),
                [ReceiptVoucherEvent.APPROVED]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã được xác nhận giao dịch
                    </span>
                ),
                [ReceiptVoucherEvent.REVIEWED]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã được phê duyệt
                    </span>
                ),
                [ReceiptVoucherEvent.REJECTED]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã bị hủy
                    </span>
                ),
                [ReceiptVoucherEvent.UPDATE_PAYER]: renderUpdateItem(),
            }[item.activity]}</div>)
    }
)

export { ListItem }

const titleFieldsMapping: any = {
    project: "Hệ thống nhận",
    payer: "Người nộp tiền",
    payerRef: "Người nộp tiền",
    amount: "Số tiền",
    memo: "Nội dung giao dịch",
    remark: "Ghi chú",
    timestamp: "Thời gian giao dịch",
    ref: "Mã bút toán",
    orderCode: 'Mã thanh toán',
    deliveryNote: 'Mã phiếu xuất'
}


const handleParseChangedValues = (changedValues: ChangedValuesInterface[], currency: string) => {
    let payerIndex = -1
    let payerRefIndex = -1

    const parseChangedValues: ChangedValuesInterface[] = changedValues.map((changedValue: ChangedValuesInterface, index: number) => {
        const { left, key, right } = changedValue
        const changedValueTemp = { ...changedValue }
        if (key === 'payer') {
            payerIndex = index
        }

        if (key === 'payerRef') {
            payerRefIndex = index
        }

        if (key === 'amount') {
            changedValueTemp.right =
                <span
                    className="positive-money">{Utils.currencyFormatByCurrency(right, currency)} ({currency})</span>

            changedValueTemp.left =
                <span
                    className="positive-money">{Utils.currencyFormatByCurrency(left, currency)} ({currency})</span>
        }

        if (key === "timestamp") {
            changedValueTemp.right = Utils.parseDateTime(right)
            changedValueTemp.left = Utils.parseDateTime(left)
        }
        return { ...changedValueTemp }
    })

    return Utils.parseLogReceiverPayer(payerIndex, payerRefIndex, parseChangedValues)
}
