import {FC, memo} from "react"
import {PaymentReasonDomain} from "../../../Domain/PaymentReasonDomain"
import {
    ChangedValuesInterface,
    LogInterface,
    PaymentVoucherEvent,
} from "../../../Interface/LogInterface"
import {LogItemInterface} from "../../../Component/LogModal/type";
import AccountDomain from "../../../Domain/AccountDomain";
import lodash from "lodash";
import Utils from "../../../Util/Utils";
import {LogChangedIcon} from "../../../Component/LogModal/ChangedIcon";

interface ListItemProps extends LogItemInterface<LogInterface<PaymentVoucherEvent, any>> {
    paymentReason?: PaymentReasonDomain[],
    account: AccountDomain
}

const ListItem: FC<ListItemProps> = memo(
    ({item, logQuery, changedValues, paymentReason = [], account}: ListItemProps) => {
        const currency = lodash.get(account, 'currency.code');

        const renderUpdateItem = () => {
            if (item.activity !== PaymentVoucherEvent.UPDATE) {
                return
            }
            const parseChangedValues: ChangedValuesInterface[] = handleParseChangedValues(changedValues, currency, paymentReason)

            return (
                <>
                    <span>Cập nhật phiếu thu</span>
                    {parseChangedValues.map(({left, right, key}: ChangedValuesInterface, index: number) => (
                        <span key={`value_${index}`}>
                            <br/>
                            {titleFieldsMapping[key] || key}{": "}
                            <span className="font-medium">{left || '---'}</span><LogChangedIcon/>
                            <span className="font-medium">{right || "---"}</span>
                        </span>
                    ))}
                </>
            )
        }

        return (<div>{
            {
                [PaymentVoucherEvent.CREATE]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã được tạo.
                    </span>
                ),
                [PaymentVoucherEvent.UPDATE]: renderUpdateItem(),
                [PaymentVoucherEvent.APPROVED]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã được xác nhận giao dịch
                    </span>
                ),
                [PaymentVoucherEvent.REVIEWED]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã được phê duyệt
                    </span>
                ),
                [PaymentVoucherEvent.REJECTED]: (
                    <span>
                        Phiếu <span className="font-medium">#{logQuery?.resourceCode}</span> đã bị hủy
                    </span>
                ),
                [PaymentVoucherEvent.UPDATE_RECEIVER]: renderUpdateItem(),
            }[item.activity]
        }</div>)
    }
)

export {ListItem}

const titleFieldsMapping: any = {
    project: 'Hệ thống chi',
    receiver: "Người nhận tiền",
    receiverRef: "Người nhận tiền",
    amount: "Số tiền",
    memo: "Nội dung giao dịch",
    remark: "Ghi chú",
    timestamp: "Thời gian giao dịch",
    ref: "Mã bút toán",
    invoice: "Số hoá đơn",
    paymentReason: "Mục đích chi",
}

const handleParseChangedValues = (changedValues: ChangedValuesInterface[], currency: string, paymentReasons: PaymentReasonDomain[]) => {
    const paymentReasonNameMapping: any = {}
    paymentReasons.forEach((paymentReason: PaymentReasonDomain) => {
        paymentReasonNameMapping[paymentReason.code || 0] = paymentReason.name
    })

    let receiverIndex = -1
    let receiverRefIndex = -1

    const parseChangedValues: ChangedValuesInterface[] = changedValues.map((changedValue: ChangedValuesInterface, index: number) => {
        const {left, key, right} = changedValue
        const changedValueTemp = {...changedValue}
        if (key === 'receiver') {
            receiverIndex = index
        }

        if (key === 'receiverRef') {
            receiverRefIndex = index
        }

        if (key === 'amount') {
            changedValueTemp.right =
                <span className="positive-money">
                    {Utils.currencyFormatByCurrency(right, currency)} ({currency})
                </span>
            changedValueTemp.left =
                <span className="positive-money">
                    {Utils.currencyFormatByCurrency(left, currency)} ({currency})
                </span>
        }

        if (key === "timestamp") {
            changedValueTemp.right = Utils.parseDateTime(right)
            changedValueTemp.left = Utils.parseDateTime(left)
        }

        if (key === "paymentReason") {
            changedValueTemp.right = paymentReasonNameMapping[changedValue.right]
            changedValueTemp.left = paymentReasonNameMapping[changedValue.left]
        }

        return {...changedValueTemp}
    })

    return Utils.parseLogReceiverPayer(receiverIndex, receiverRefIndex, parseChangedValues)
}
