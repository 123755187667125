import lodash from 'lodash';
import localStore from "./LocalStore";

class SecurityService {

    static can = (permission: string) => {
        const permissions = localStore.getJson('permissions') || [];
        return permissions.includes(permission);
    };

    static isLogged = () => {
        const loginSession = localStore.getJson('loginSession');
        const accessToken = lodash.get(loginSession, 'accessToken');
        return !!accessToken;
    };

    static getUser = () => {
        if (SecurityService.isLogged()) {
            return localStore.getJson('loggedUser');
        }
    };

    static allowTo = (permission:string | string[]) => {
        let accountPermissions = localStore.getJson('accountPermissions') || [];

        if (accountPermissions.owner) return true;

        if (typeof permission === 'string') {
            return accountPermissions.permissions?.includes(permission)
        }

        if (typeof permission === 'object') {
            const filteredArray: string[] = accountPermissions.permissions?.filter((value: string) => permission.includes(value));
            return filteredArray?.length > 0
        }
    }
}

export default SecurityService;
