import {Form, Input, Modal} from 'antd';
import {AxiosError} from "axios";
import lodash, {omitBy} from 'lodash';
import React from 'react';
import RoleApi from "../../Api/RoleApi";
import RoleUpdateCommand from "../../Command/RoleUpdateCommand";
import { NotificationCommon } from '../../Component/Notification';
import SelectRoleStatus from "../../Component/SelectRoleStatus";
import RoleDomain from "../../Domain/RoleDomain";

interface State {
    form: RoleUpdateCommand,
    loading: boolean,
}

interface Props {
    onVisibleChange: (visible: boolean) => void,
    fetchRoles: () => void,
    role: RoleDomain
}

class RoleUpdate extends React.Component<Props, State> {

    state: State = {
        form: {...this.props.role},
        loading: false,
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value
            }
        })
    };

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value
            }
        })
    };

    handleSubmit = () => {
        const {role} = this.props;
        this.setState({loading: true});
        const data = omitBy(this.state.form, (value: any) => (value === "" || value === undefined || value === null));
        RoleApi.update(role.code, data)
            .then((response) => {
                this.handleUpdateSuccess();
            })
            .catch((error: AxiosError) => {
                this.handleUpdateFail(error);
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleUpdateStatus = (status:string) => {
        this.handleChangeSelect('status',status)
    }

    handleUpdateSuccess = () => {
        NotificationCommon.success({
            message: "Cập nhật vai trò thành công",
            icon: <i className="fa-solid fa-circle-check"/>,
        })
        this.props.onVisibleChange(false);
        this.props.fetchRoles();
    }

    handleUpdateFail = (error: AxiosError) => {
        NotificationCommon.error({
            message: "Cập nhật vai trò lỗi: " + error.message,
            icon: <i className="fa-solid fa-triangle-exclamation"/>,
        })
    }

    render() {
        const {form, loading} = this.state;

        return (
            <Modal
                visible={true}
                title={'Cập nhật vai trò'}
                onOk={this.handleSubmit}
                closeIcon={<i className="fa-solid fa-xmark"  />}
                onCancel={() => this.props.onVisibleChange(false)}
                okButtonProps={{
                    loading: loading,
                    disabled: loading || lodash.isEqual(this.props.role,this.state.form),
                    tabIndex: 2,
                    icon: <i className="fa-solid fa-check mg-r-8"/>
                }}
                cancelButtonProps={{
                    type:'ghost',
                    disabled: loading,
                    tabIndex: 3,
                    icon: <i className="fa-solid mg-r-8 fa-xmark"/>
                }}
                okText="Cập nhật vai trò"
                cancelText="Bỏ qua"
				centered
                >
                <Form layout={'horizontal'} labelAlign={'left'} labelCol={{xs:24,sm:4}} initialValues={form}>
                    <Form.Item
                        label={"Tên vai trò"}
                        name="name"
                        rules={[{required: true, message: 'Mã vai trò không được để trống'}]}
                    >
                        <Input
                            autoFocus={true}
                            tabIndex={1}
                            className={'width-100pc'}
                            value={form.name}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, 'name')}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item
                        label={"Trạng thái"}
                    >
                        <SelectRoleStatus
                            type={'update'}
                            value={form.status}
                            onChange={this.handleChangeSelect.bind(this, 'status')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default RoleUpdate;
