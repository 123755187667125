import React from 'react';
import {Card} from "antd";
import QrCode from "../../../Component/QrCode";
import ReceiptVoucherDomain from "../../../Domain/ReceiptVoucherDomain";
import Utils from "../../../Util/Utils";

interface Props {
    receiptVoucher: ReceiptVoucherDomain
}

class VoucherQrCode extends React.Component<Props, any> {

    render() {
        const {receiptVoucher} = this.props;
        return (
            <Card title={"Mã QR"} className={'mg-bt-12'}>
                <QrCode value={Utils.buildUrl(window.location, `/receipt-vouchers/${receiptVoucher.code}`)} />
            </Card>
        )
    }
}

export default VoucherQrCode;