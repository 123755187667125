import React from 'react';
import {Button, Modal} from "antd";

type Props = {
    title:string;
    description?:string;
    onOk:()=>void;
    onCancel:()=>void;
    visible:boolean;
    deleting?:boolean;
}

export default function ConfirmDeletePopup(props:Props) {

    const {visible,deleting,title,description,onCancel,onOk} = props;

    return(
        <>
            <Modal
                className={'confirm-delete-modal'}
                title={title}
                visible={visible}
                onCancel={onCancel}
                onOk={onOk}
                footer={[
                    <Button onClick={onCancel} type={'ghost'}>
                        <i className="fa-solid fa-xmark mg-r-5"/>Hủy bỏ
                    </Button>,
                    <Button onClick={onOk} danger loading={deleting}>
                        <i className="fa-solid fa-trash mg-r-6"/>Xóa
                    </Button>
                ]}
                destroyOnClose
                centered
                width={410}
            >
                <div>{description ? description : null}</div>
            </Modal>
        </>
    )
}

