import React from 'react'
import {Spin} from "antd";
import localStore from "../../Util/LocalStore";
import {AppContext} from "../../Context/AppContext";
import appConfig from "../../Config/App";

class Logout extends React.Component {

    static contextType = AppContext;

    constructor(props: any) {
        super(props);
        localStore.removeItem('loginSession');
        localStore.removeItem('loggedUser');
        localStore.removeItem('tenant');
        localStore.removeItem('permissions');
    }

    componentDidMount(): void {
        // @ts-ignore
        window.location.href = `${appConfig.logoutUrl}`;
    }

    render() {
        return (
            <div className={'mg-t-10 mg-l-10'}>Đang chuyển trang, vui lòng đợi một chút.... <Spin/></div>
        );
    }
}

export default Logout;

