import {Badge, Button} from "antd";
import lodash from "lodash";
import React from 'react';
import RoleApi from "../../Api/RoleApi";
import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import RoleDomain, {DefaultRoleModel} from "../../Domain/RoleDomain";
import Utils from "../../Util/Utils";
import DataList from "./DataList";
import FilterForm from "./FilterForm";
import GrantPermission from "./GrantPermission";
import RoleCreate from "./RoleCreate";
import RoleUpdate from "./RoleUpdate";
import SecurityService from "../../Util/SecurityService";
import {PERMISSIONS} from "../../Util/Constants";

interface State {
    loading: boolean,
    roles: Array<RoleDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    showCreateModal: boolean,
    showUpdateModal: boolean,
    showGrantPermissionModal: boolean,
    selectedRole: RoleDomain,
    collapse:boolean,
}

class RoleList extends DefaultComponent<any, State> {

    state: State = {
        loading: false,
        roles: [],
        pageSize: 25,
        total: 0,
        currentPage: 1,
        showCreateModal: false,
        showUpdateModal: false,
        showGrantPermissionModal: false,
        selectedRole: {...DefaultRoleModel},
        collapse:true,
    }

    componentDidMount() {
        this.fetchRoles({
            ...this.getQueryFromLocation()
        })
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchRoles({
                ...this.getQueryFromLocation()
            });
            this.setState({
                ...this.state,
                pageSize:this.getQueryFromLocation().limit,
                currentPage:this.getQueryFromLocation().page,
            })
        }
    }

    fetchRoles = (query = {}) => {
        this.setState({loading: true});
        const filter = {
            sort: 'createdAt:desc',
            ...query
        };
        RoleApi.filter(filter)
            .then(response => {
                this.setState({
                    roles: response.data,
                    currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                    pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                    total: parseInt(lodash.get(response, 'headers.x-total-count')),
                });
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    onChangePage = (page: number, pageSize: number) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize
        })
    }

    handleCreateModalVisibleChange = (visible: boolean) => {
        this.setState({showCreateModal: visible});
    }

    handleUpdateModalVisibleChange = (visible: boolean) => {
        this.setState({showUpdateModal: visible});
        if (!visible) {
            this.setDefaultSelectedRole();
        }
    }

    handleGrantPermissionModalVisibleChange = (visible: boolean) => {
        this.setState({showGrantPermissionModal: visible});
        if (!visible) {
            this.setDefaultSelectedRole();
        }
    }

    toggleUpdateModal = (role: RoleDomain) => {
        if (SecurityService.can(PERMISSIONS.ROLE_UPDATE)) {
            this.setState({
                showUpdateModal: !this.state.showUpdateModal
            }, () => {
                if (this.state.showUpdateModal) {
                    this.setState({selectedRole: role});
                } else {
                    this.setDefaultSelectedRole();
                }
            })
        }
    }

    toggleGrantPermissionModal = (role: RoleDomain) => {
        this.setState({
            showGrantPermissionModal: !this.state.showGrantPermissionModal
        }, () => {
            if (this.state.showGrantPermissionModal) {
                this.setState({selectedRole: role});
            }
            else {
                this.setDefaultSelectedRole();
            }
        })
    }

    setDefaultSelectedRole = () => {
        this.setState({selectedRole: {...DefaultRoleModel}});
    }

    handleCollapseFilter = () => {
        this.setState({collapse:!this.state.collapse})
    }

    render() {
        const {roles, currentPage, pageSize, total, loading, showCreateModal, showUpdateModal, selectedRole, showGrantPermissionModal} = this.state;
        return (
            <DefaultLayout
                title={"Danh sách vai trò"}
                breadcrumb={[{title:'Danh Sách Vai Trò'}]}
                {...this.props}
            >
                <div className="main-content roles-container">
                    <div className={'white-box roles-container__filter'}>
                        <div className={'roles-container__filter-title align-items-center'} onClick={this.handleCollapseFilter}>
                            <span>Tìm kiếm</span>
                            {this.state.collapse ? <i className="fa-solid fa-chevron-down fsz-14px"/> :
                                <i className="fa-solid fa-chevron-up fsz-14px"/>}
                        </div>
                        {!this.state.collapse && <div className={'roles-container__filter-search'}>
                            <FilterForm
                                handleCollapseFilter={this.handleCollapseFilter}
                                {...this.props}
                            />
                        </div>}
                    </div>
                    <div className="white-box roles-container__data">
                        <div className={'roles-container__data-header'}>
                            <div className={''}>
                                <span className={''}>Danh sách vai trò</span>
                                <Badge className={'mg-l-6'} count={Utils.currencyFormat(total)} overflowCount={999}/>
                            </div>
                            {SecurityService.can(PERMISSIONS.ROLE_CREATE) && <div>
                                <Button type={'primary'} onClick={() => this.handleCreateModalVisibleChange(true)}><i
                                    className="fa-solid fa-plus mg-r-5"/>Tạo vai trò</Button>
                            </div>}
                        </div>
                        <div className={'roles-container__data-list'}>
                            <DataList
                                loading={loading}
                                items={roles}
                                total={total}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onChangePage={this.onChangePage}
                                toggleUpdateModal={this.toggleUpdateModal}
                                toggleGrantPermissionModal={this.toggleGrantPermissionModal}
                            />
                        </div>
                    </div>
                </div>

                {showCreateModal && (
                    <RoleCreate
                        fetchRoles={this.fetchRoles}
                        onVisibleChange={this.handleCreateModalVisibleChange}
                    />
                )}

                {showUpdateModal && selectedRole.code && (
                    <RoleUpdate
                        role={selectedRole}
                        onVisibleChange={this.handleUpdateModalVisibleChange}
                        fetchRoles={this.fetchRoles}
                    />
                )}

                {showGrantPermissionModal && selectedRole.code && (
                    <GrantPermission
                        onVisibleChange={this.handleGrantPermissionModalVisibleChange}
                        role={selectedRole}
                    />
                )}
            </DefaultLayout>
        );
    }
}

export default RoleList;
