import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Input, Radio, RadioChangeEvent, Row} from 'antd';

interface FilterProps {
    onFilter: (filter: {
        type: string | undefined;
        status: string | undefined;
        name:string | undefined;
    }) => void;
    status?: string;
    type?: string;
    filter: { type?: string; status?: string,name?:string };
}

const Filter: React.FC<FilterProps> = ({onFilter, filter,}) => {
    const [status, setStatus] = useState<string | undefined>(
        filter.status || undefined
    );
    const [type, setType] = useState<string | undefined>(
        filter.type || undefined
    );
    const [isShow, setIsShow] = useState<boolean>(true);

    const [name,setName] = useState<string|undefined>(filter.name || undefined);

    const [expand,setExpand] = useState<boolean>(false);

    useEffect(() => {
        setStatus(filter.status);
        setType(filter.type);
        setName(filter.name);
    }, [filter]);

    const handleWindowResize = useCallback(
        () => {
            // console.log(getWindowSize())
            if(getWindowSize().innerWidth >= 1420) {
                setExpand(true)
            }
        },
        []);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    const getWindowSize = () => {
        const {innerWidth,innerHeight} = window;
        return {innerWidth,innerHeight};
    }

    const handleChangeType = (e: string) => {
        const filter = {type: e, status,name};
        setType(e);
        onFilter(filter);
    };

    const handleChangeStatus = (e: string) => {
        const filter = {status: e, type,name};
        setStatus(e);
        onFilter(filter);
    };

    const handleSearchByName = () => {
        const filterTemp = {status,type,name:name}
        onFilter(filterTemp)
    }

    const handleResetFilter = () => {
        onFilter({status:undefined,type:undefined,name:undefined})
        setName(undefined);
        setType(undefined);
        setStatus(undefined)
    }

    const handleSearch = () => {
        onFilter({
            status:status,
            type:type,
            name:name,
        })
    }

    const handleExpandFilter = () => {
        setExpand(!expand);
    }

    return (
        <>
            <div className="account-filter">
                <div className="white-box search pd-12">
                    <div
                        className="font-medium capitalize title text-dark-color pd-r-4 align-items-center"
                        onClick={() => setIsShow(!isShow)}
                    >
                        <span className="fsz-16px">Tìm kiếm</span>
                        
                        {isShow ? (
                            <i className="fa-solid fa-chevron-up"/>
                        ) : (
                            <i className="fa-solid fa-chevron-down"/>
                        )}
                    </div>
                    {isShow && (
                        <Form layout="horizontal" className="mg-t-12">
                            <Row gutter={12} className={''}>
                                <Col xs={24} sm={24} lg={12} xl={10} xxl={8}>
                                    <Form.Item label={<span>Trạng thái</span>}>
                                        <Radio.Group
                                            onChange={({
                                                           target: {value},
                                                       }: RadioChangeEvent) => {
                                                handleChangeStatus(value);
                                            }}
                                            value={status}
                                        >
                                            <Radio value={undefined} checked={status === undefined}>Tất cả</Radio>
                                            <Radio value={'ACTIVE'} checked={status === "ACTIVE"}>Đang hoạt động</Radio>
                                            <Radio value={'INACTIVE'} checked={status === "INACTIVE"}>Ngừng hoạt động</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={12} xl={11} xxl={8}>
                                    <Form.Item label={'Loại tài khoản'}>
                                        <Radio.Group
                                            onChange={({
                                                           target: {value},
                                                       }: RadioChangeEvent) => {
                                                handleChangeType(value);
                                            }}
                                            value={type}
                                        >
                                            <Radio value={undefined}>Tất cả</Radio>
                                            <Radio value={'BANK'}>Tài khoản ngân hàng</Radio>
                                            <Radio value={'CASH'}>Tài khoản tiền mặt</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                {expand && <Col xs={24} sm={18} lg={12} xl={9} xxl={8} className={'account-input'}>
                                    <Form.Item label={'Tên tài khoản'}>
                                        <Input autoFocus placeholder={'Vui lòng nhập tên tài khoản quỹ'}
                                               onChange={(e)=>setName(e.target.value)}
                                               onPressEnter={handleSearchByName}
                                               value={name}
                                        />
                                    </Form.Item>
                                </Col>}
                            </Row>
                            <div className={'justify-between flex'}>
                                <div className={'expand-btn'}>
                                    <span onClick={handleExpandFilter} className={'font-size-12 cursor-pointer'}>
                                        {expand ? <><i className="fa-solid fa-chevron-up mg-r-3"/>Thu gọn</> : <><i className="fa-solid fa-chevron-down mg-r-3"/>Mở rộng</>}
                                    </span>
                                </div>
                                <div>
                                    {status || name || type ? (
                                        <Button type={'text'} onClick={handleResetFilter}>
                                            <i className="fa-solid fa-repeat mg-r-5"/><span className={'font-size-12'}>Làm mới bộ lọc</span>
                                        </Button>
                                    ) : null}
                                    <Button type={'primary'} onClick={handleSearch}>
                                        Tìm kiếm
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </div>
            </div>
        </>
    );
};

export default Filter;
