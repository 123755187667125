import React, {useState} from "react";
import {Alert, Button, DatePicker, Modal} from "antd";
import moment from "moment";
import {RangePickerProps} from "antd/es/date-picker";

type SelectExportRangeProps = {
    handleExportTransaction:(rangeValues:string[]) => void;
    handleVisible:(visible:boolean) => void;
    visible:boolean;
}

const beginDateFormat = "YYYY-MM-DD 00:00:00"
const endDateFormat = "YYYY-MM-DD 23:59:59"

export default function SelectExportRange (props:SelectExportRangeProps) {
    const {
        handleExportTransaction,
        handleVisible,visible
    } = props;

    const [dateRange,setDateRange] = useState<any>([])
    const [error,] = useState<string>('')


    const handleChangeDateRange = (values:any) => {
        let tempValues:any = []
        if (values) {
            tempValues.splice(0,1, moment(values[0].format(beginDateFormat)).toISOString());
            tempValues.splice(1,1, moment(values[1].format(endDateFormat)).toISOString())
        }
        setDateRange(tempValues)
    }

    const handleExport = () => {
        console.log(dateRange)
        handleExportTransaction(dateRange)
        handleVisible(false)
    }

    const disabledFutureDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current > moment().endOf('day');
    };

    return(
        <Modal
            title={'Xuất File Danh Sách'}
            centered
            visible={visible}
            className={''}
            onCancel={() => handleVisible(false)}
            footer={null}
            destroyOnClose
            closeIcon={<i className="fa-solid fa-xmark"/>}
        >
            <div className={'flex width-100pc align-items-center mg-bt-12'} style={{flex:1}}>
                <div className={'mg-r-4'}>Thời gian phát sinh giao dịch: </div>
                <div style={{flex:1}}>
                    <DatePicker.RangePicker
                        allowClear
                        format="YYYY-MM-DD"
                        className={'width-100pc'}
                        disabledDate={disabledFutureDate}
                        // defaultValue={[moment().subtract(30,'d'), moment()]}
                        onChange={(values)=> {
                            handleChangeDateRange(values)
                        }}
                        placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                    />
                </div>
            </div>
            <div className={'mg-bt-12'}> {error &&  <Alert message={error} type="warning" />}
            </div>
            <div className={'flex justify-content-flex-end'}>
                <Button type={'ghost'} onClick={()=> handleVisible(false)}>
                    <i className="fa-solid fa-xmark mg-r-6"/>Hủy bỏ
                </Button>
                <Button type={'primary'}
                        className={'mg-l-8'}
                        onClick={handleExport}
                        // disabled={dateRange.length < 1 || error.length > 1}
                >
                    <i className="fa-regular fa-file-export mg-r-6"/>Xuất file
                </Button>
            </div>
        </Modal>
    )
}
