import React from 'react';
import {Select, SelectProps} from "antd";
import PaymentReasonApi from "../../Api/PaymentReasonApi";
import {PaymentReasonDomain} from "../../Domain/PaymentReasonDomain";

interface Props {
    value: any,
    onChange: (code: string) => void,
    selectProps?: SelectProps<any>
}

interface State {
    value: any,
    loading: boolean,
    items: Array<PaymentReasonDomain>
}

class SelectPaymentReason extends React.Component<Props, State> {

    state = {
        loading: false,
        value: undefined,
        items: []
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        const newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        this.fetchPaymentReasons();
    }

    fetchPaymentReasons = (query = {}) => {
        this.setState({loading: true});
        query = {
            offset: 0,
            limit: 100,
            ...query,
        }
        PaymentReasonApi.autocomplete(query)
            .then(response => {
                this.setState({items: response.data});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleChange = (value: any) => {
        this.setState({value});
        this.props.onChange(value);
    }

    handleSearch = (keyword: any) => {
        if (keyword && keyword.toString().length >= 3) {
            this.fetchPaymentReasons({query: keyword});
        }
        else if (!keyword) {
            this.fetchPaymentReasons({query: ""});
        }
    }

    render() {
        const {selectProps} = this.props;
        const {items, value} = this.state;
        return (
            <Select
                showSearch={true}
                filterOption={false}
                allowClear={true}
                value={value || undefined}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                {...selectProps}
            >
                {items.map((item: any) => (
                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default SelectPaymentReason;