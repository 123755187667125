import apiClient from "../Util/ApiClient";
import {SubTransactionCommand} from "../Command/SubTransactionCommand";
import {DefaultCriteria} from "../QueryCriteria/DefaultCriteria";

export default class SubTransaction {
    static getSubTransactions = (code:string, query:DefaultCriteria = {}) => {
        return apiClient.get(`transactions/${code}/sub-transactions`,{
            params:query
        })
    }

    static createSubTransactions = (code:string,subTransactions:SubTransactionCommand[]) => {
        return apiClient.post(`transactions/${code}/sub-transactions`,subTransactions)
    }

    static deleteSubTransactions = (code:string) => {
        return apiClient.delete(`transactions/${code}/sub-transactions`)
    }
}
