import apiClient from '../Util/ApiClient'
import SuggestCustomerQueryCriteria from '../QueryCriteria/SuggestCustomerQueryCriteria'
import axios from 'axios'

var CancelToken = axios.CancelToken
var cancel: any = null

export default class CustomerApi {
    static getSuggestCustomers(query: SuggestCustomerQueryCriteria) {
        if (!!cancel) {
            cancel()
            cancel = null
        }

        return apiClient
            .get('/customers/autocomplete', {
                params: { ...query },
                cancelToken: new CancelToken((c) => {
                    cancel = c
                }),
            })
            .finally(() => {
                cancel = null
            })
    }
}
