import {Component} from "react"
import moment from "moment"
import {Button, Col, DatePicker, Form, Input, Radio, RadioChangeEvent, Row} from "antd"
import Utils from "../../../Util/Utils"
import SelectSuggestStaff from "../../../Component/SelectSuggestStaff"
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface"
import PaymentVoucherQueryCriteria from "../../../QueryCriteria/PaymentVoucherQueryCriteria"
import SelectPaymentReason from "../../../Component/SelectPaymentReason"
import SelectVoucherStatus from "../../../Component/SelectVoucherStatus";

const RangePicker = DatePicker.RangePicker

const beginDateFormat = "YYYY-MM-DD HH:mm:00"
const endDateFormat = "YYYY-MM-DD HH:mm:59"

interface Props extends DefaultPropsInterface {}

interface State {
    form: PaymentVoucherQueryCriteria
    isShow: boolean
}

class FilterForm extends Component<Props, State> {
    state = {
        form: {
            ...Utils.getQueryFromLocation(this.props.location),
        },
        isShow: false,
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.setState({form:Utils.getQueryFromLocation(this.props.location)})
        }
    }

    handleChangeInput = (field: string, e: any) => {
        const form: any = this.state.form
        form[field] = e.target.value
        this.setState({ form })
    }

    handleChangeSelect = (field: string, value: any) => {
        const form: any = this.state.form
        form[field] = value
        this.setState({ form })
    }

    handleChangeRangePicker = (field: string, values: any) => {
        const { form }: any = this.state

        if (field === "timestamp") {
            form["timestampFrom"] = values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null
            form["timestampTo"] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null
        } else if (field === "preparedAt") {
            form["preparedAtFrom"] =
                values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null
            form["preparedAtTo"] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null
        } else if (field === "approvedAt") {
            form["approvedAtFrom"] =
                values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null
            form["approvedAtTo"] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null
        }

        this.setState({ form })
    }

    handleFilter = () => {
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, this.state.form)
    }

    clearFilter = () => {
        this.setState({ form: {} })
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {})
    }

    toggleFilterShow = () => {
        this.setState({ isShow: !this.state.isShow })
    }

    render() {
        const form: PaymentVoucherQueryCriteria = this.state.form
        const { isShow } = this.state

        const disbaleSubmit: boolean = Utils.checkDisableSubmit(form)

        return (
            <>
                <div className="font-medium  capitalize pointer space-between align-items-center" onClick={this.toggleFilterShow}>
                    <span className="fsz-16px">Tìm kiếm</span>

                    {isShow ? <i className="fa-solid fa-chevron-up" /> : <i className="fa-solid fa-chevron-down" />}
                </div>
                {isShow && (
                    <Form className=" bottom-item-12 pd-t-12" labelAlign="left" labelCol={{ flex: '132px' }}>
                        <Row gutter={20}>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Mã phiếu"} className="">
                                    <Input
                                        autoFocus
                                        placeholder={"Tìm kiếm theo mã phiếu"}
                                        value={form.code}
                                        onChange={this.handleChangeInput.bind(this, "code")}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Mã giao dịch"} className="">
                                    <Input
                                        placeholder={"Tìm kiếm theo mã giao dịch"}
                                        value={form.transaction}
                                        onChange={this.handleChangeInput.bind(this, "transaction")}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Mã bút toán"} className="">
                                    <Input
                                        placeholder={"Tìm kiếm theo mã bút toán"}
                                        value={form.ref}
                                        onChange={this.handleChangeInput.bind(this, "ref")}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Người tạo"} className="">
                                    <SelectSuggestStaff
                                        selectProps={{
                                            placeholder: "Người tạo",
                                        }}
                                        value={form.preparedBy}
                                        onChange={this.handleChangeSelect.bind(this, "preparedBy")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Người xác nhận"} className="">
                                    <SelectSuggestStaff
                                        selectProps={{
                                            placeholder: "Người xác nhận GD",
                                        }}
                                        value={form.approvedBy}
                                        onChange={this.handleChangeSelect.bind(this, "approvedBy")}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={8}>
                                <Form.Item label={"Số hoá đơn"} className="">
                                    <Input
                                        placeholder={"Tìm kiếm theo số hoá đơn"}
                                        value={form.invoice}
                                        onChange={this.handleChangeInput.bind(this, "invoice")}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Mục đích chi"} className="">
                                    <SelectPaymentReason
                                        selectProps={{
                                            placeholder: "Mục đích chi",
                                        }}
                                        value={form.paymentReason}
                                        onChange={this.handleChangeSelect.bind(this, "paymentReason")}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={8}>
                                <Form.Item label={"Thời gian giao dịch"} className="">
                                    <RangePicker
                                        className={"width-100pc"}
                                        value={[
                                            form.timestampTo ? moment(form.timestampTo) : null,
                                            form.timestampFrom ? moment(form.timestampFrom) : null,
                                        ]}
                                        placeholder={["Từ", "Đến"]}
                                        format={"DD/MM/YYYY HH:mm"}
                                        showTime={true}
                                        onChange={this.handleChangeRangePicker.bind(this, "timestamp")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Thời gian tạo"} className="">
                                    <RangePicker
                                        className={"width-100pc"}
                                        value={[
                                            form.preparedAtFrom ? moment(form.preparedAtFrom) : null,
                                            form.preparedAtTo ? moment(form.preparedAtTo) : null,
                                        ]}
                                        placeholder={["Từ", "Đến"]}
                                        format={"DD/MM/YYYY HH:mm"}
                                        showTime={true}
                                        onChange={this.handleChangeRangePicker.bind(this, "preparedAt")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={"Thời gian xác nhận"} className="">
                                    <RangePicker
                                        className={"width-100pc"}
                                        value={[
                                            form.approvedAtFrom ? moment(form.approvedAtFrom) : null,
                                            form.approvedAtTo ? moment(form.approvedAtTo) : null,
                                        ]}
                                        placeholder={["Từ", "Đến"]}
                                        format={"DD/MM/YYYY HH:mm"}
                                        showTime={true}
                                        onChange={this.handleChangeRangePicker.bind(this, "approvedAt")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={16} xl={8}>
                                <Form.Item label={"Trạng thái giao dịch"} className="">
                                    <Radio.Group
                                        onChange={({ target: { value } }: RadioChangeEvent) => {
                                            this.handleChangeSelect("reviewStatus", value)
                                            this.handleFilter()
                                        }}
                                        value={form.reviewStatus}
                                        defaultValue={undefined}
                                    >
                                        <Radio value={undefined}>Tất cả</Radio>
                                        <Radio value={"REVIEWED"}>Đã duyệt</Radio>
                                        <Radio value={"PENDING"}>Chờ duyệt</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24} xl={8}>
                                <Form.Item label={"Trạng thái"} className={""}>
                                    <SelectVoucherStatus
                                        value={form.status}
                                        onChange={({ target: { value } }: RadioChangeEvent) => {
                                            this.handleChangeSelect.bind(this, "status", value)()
                                            this.handleFilter()
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="flex space-between items-center">
                            <span onClick={this.toggleFilterShow} className={'font-size-12 cursor-pointer'}>
                                <i className="fa-solid fa-chevron-up mg-r-3"/> Thu gọn
                            </span>
                            <div>
                                {!disbaleSubmit && (
                                    <Button
                                        type={"text"}
                                        onClick={this.clearFilter}
                                        icon={<i className="fa-solid fa-repeat pd-r-4" />}
                                        className="font-size-12px"
                                    >
                                        Làm mới bộ lọc
                                    </Button>
                                )}

                                <Button type="primary" disabled={disbaleSubmit} onClick={this.handleFilter}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </>
        )
    }
}

export default FilterForm
