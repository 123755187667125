import React from "react";
import {Skeleton, Table, Tooltip, Typography} from "antd";
import TransactionsDomain from "../../Domain/TransactionDomain";
import SubTransaction from "../../Api/SubTransaction";
import {DefaultCriteria} from "../../QueryCriteria/DefaultCriteria";
import {NotificationCommon} from "../Notification";
import SubTransactionCreate from "../SubTransactionCreate";
import lodash from "lodash";
import {AppContext} from "../../Context/AppContext";
import Utils from "../../Util/Utils";
import ConfirmDeletePopup from "../ConfirmPopup";
import SecurityService from "../../Util/SecurityService";
import {ACCOUNT_PERMISSIONS} from "../../Util/Constants";

type SubTransactionListProps = {
    record:TransactionsDomain,
    paymentReasons:any[],
    fetchTransactions:(filter:any) => void;
}

function SubTransactionViewList(props:SubTransactionListProps) {
    const {record,paymentReasons} = props;
    const [loading,setLoading] = React.useState<boolean>(false);
    const [subTransactions,setSubTransactions] = React.useState<any[]>();
    const [showEdit,setShowEdit] = React.useState<boolean>(false);
    const [confirmDelete,setConfirmDelete] = React.useState<boolean>(false);

    const getSubTransactions = React.useCallback(()=> {
        setLoading(true);
        if(record) {
            let filterTemp:DefaultCriteria = {
                offset:0,
                limit:1000,
                sort:'timestamp:desc'
            }
            SubTransaction.getSubTransactions(record.code,filterTemp).then((response) => {
                setSubTransactions(lodash.sortBy(response.data,['timestamp']))
            }).catch((err) => {
                console.log(err.response)
            })
                .finally(() => setLoading(false))
        }
    },[record])
    const account = React.useContext(AppContext)

    React.useEffect(()=> {
        getSubTransactions();
    },[getSubTransactions])

    const handleRemoveSubTransaction = () => {
        if(record) {
            SubTransaction.deleteSubTransactions(record.code).then(()=> {
                NotificationCommon.success({
                    message:'Xóa giao dịch con thành công'
                })
                props.fetchTransactions(Utils.getQueryFromLocation(window.location));
            }).catch((err) => {
                console.log(err.response);
                NotificationCommon.error({
                    message:"Xóa giao dịch con thất bại"
                })
                if (err.response.status === 403) {
                    NotificationCommon.error({
                        message:"Bạn không có quyền thực hiện thao tác này"
                    })
                }
            })
        }
    }

    const confirmDeleteSubTransaction = (visible:boolean) => {
        setConfirmDelete(visible)
    }

    const handleChangeEditModalVisible = (visible:boolean) => {
        setShowEdit(visible)
    }

    const columns = [
        {
            title:'Giao dịch con',
            key:'code',
            width:'10%',
            render:(row:any) => <span>{row.code}</span>

        },
        {
            title:'Nội dung',
            key:'memo',
            width:'15%',
            render:(row:any) => {
                let ellipsis:boolean = false;
                if(row.memo && row.memo.length >= 72) ellipsis = true;
                return (<div>
                    <Typography.Text
                        style={{ width: 250 }}
                        ellipsis={ellipsis ? { tooltip: row.memo } : false}
                    >
                        {row.memo ? row.memo : '---'}
                    </Typography.Text>
                </div>)
            }
        }
        ,
        {
            title:<span className={'flr'}>Số tiền</span>,
            key:'amount',
            width:'20%',
            render:(row:any) => {
                let currency = lodash.get(account.state.account,'currency.code');
                let amount = Utils.currencyFormatByCurrency(row.amount,currency);
                return <span className={`${row.amount < 0 ? 'negative-money' : 'positive-money'} bold flr`}>{amount}</span>
            }
        },
        {
            title:'Mục đích chi',
            key:'reason',
            width:'20%',
            render:(row:any) => {
                let paymentReason = paymentReasons.find((p) => p.code === lodash.get(row.paymentReason,'code'))
                return <span>{paymentReason ? paymentReason.name : '---'}</span>
            }
        },
        {
            title:'Ghi chú',
            key:'remark',
            width:'20%',
            render:(row:any) => {
                let ellipsis:boolean = false;
                if(row.remark && row.remark.length >= 72) ellipsis = true;
                return (<div>
                    <Typography.Text
                        style={{ width: 250 }}
                        ellipsis={ellipsis ? { tooltip: row.remark } : false}
                    >
                        {row.remark ? row.remark : '---'}
                    </Typography.Text>
                </div>)
            }
        },
        {
            title:<div className={'space-between pointer width50pc'}>
                {subTransactions && subTransactions?.length > 0 && <>
                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.SUB_TRANSACTION_EDIT) && <Tooltip title={'Sửa'} className={'mg-r-8'}>
                        <span onClick={() => handleChangeEditModalVisible(true)}><i className="fa-regular fa-pen-to-square font-size-14"/></span>
                    </Tooltip>}
                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.SUB_TRANSACTION_DELETE) && <Tooltip title={'Xóa'}>
                        <span onClick={()=>confirmDeleteSubTransaction(true)}><i className="fa-regular fa-trash-can text-danger font-size-14"/></span>
                    </Tooltip> }
                </>}

            </div>,
            key:'action',
            width:'4%',
        }
    ]

    return(<div className={'sub-transactions-box'}>
        <Skeleton loading={loading} active>
                <Table
                    columns={columns}
                    dataSource={subTransactions}
                    loading={loading}
                    pagination={false}
                    scroll={{x:768}}
                />
        </Skeleton>
        {showEdit && <SubTransactionCreate visible={showEdit}
                                           rootTransaction={record!}
                                           handleModalCreateSubTransactionVisibleChange={handleChangeEditModalVisible}
                                           editSubTransactions={subTransactions}
                                           fetchSubTransaction={getSubTransactions}
        />
        }
        {confirmDelete && <ConfirmDeletePopup title={'Xóa giao dịch con'}
                                              description={'Bạn có chắc muốn xóa các giao dịch con này?'}
                                              onOk={handleRemoveSubTransaction}
                                              onCancel={()=> confirmDeleteSubTransaction(false)}
                                              visible={confirmDelete}
        />}
    </div>)
}

export default SubTransactionViewList;
