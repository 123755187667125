import apiClient from "../Util/ApiClient";

export default class SimApi {

    static addConfig = (simCode: string, data: any) => {
        return apiClient.post(`/sims/${simCode}/configs`, data)
    };

    static getConfig = (simCode: string) => {
        return apiClient.get(`/sims/${simCode}/configs`)
    };

    static updateConfig = (simCode: string, configCode: string, data: any) => {
        return apiClient.patch(`/sims/${simCode}/configs/${configCode}`, data)
    };
}