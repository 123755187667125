import { Component } from 'react'
import moment from 'moment'
import { unset } from 'lodash'
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select, Tooltip } from 'antd'
import Utils from '../../../Util/Utils'
import SelectSuggestStaff from '../../../Component/SelectSuggestStaff'
import DefaultPropsInterface from '../../../Interface/DefaultPropsInterface'
import SelectCustomer from '../../../Component/SelectCustomer'
import { FILTER_TYPES, PARTICIPANT_TYPES, TRANSACTION_STATUS_OPTIONS, TRANSACTION_TYPES } from '../../../Util/Constants'
import SelectPaymentReason from '../../../Component/SelectPaymentReason'

const RangePicker = DatePicker.RangePicker

const beginDateFormat = 'YYYY-MM-DD 00:00:00'
const endDateFormat = 'YYYY-MM-DD 23:59:59'

interface FormInterface {
    transactionType?: any
    query?: string // = code / voucher / ref / memo
    preparedBy?: string
    approvedBy?: string
    preparedAtFrom?: string
    preparedAtTo?: string
    timestampFrom?: string
    timestampTo?: string
    approvedAtFrom?: string
    approvedAtTo?: string
    approvedAt?: string
    project?: string
    participant?: string
    amountFrom?: string | number
    amountTo?: string | number
    amount?: string | number
    participantType?: string
    paymentReason?: string
    invoice?: string
    referenceTx?: string
    filterAmountType?: string
    participantUsername?: string
    reviewStatus?: string
    reviewedBy?: string
    onyParent?: boolean
    status?: string
}

interface Props extends DefaultPropsInterface { }

interface State {
    form: FormInterface
    isCollapse: boolean
    isShow: boolean
}

class FilterForm extends Component<Props, State> {
    state: State = {
        form: {
            participantType: 'inside',
            filterAmountType: 'between',
            ...Utils.getQueryFromLocation(this.props.location),
        },
        isCollapse: true,
        isShow: false,
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.setState({
                form: {
                    ...this.state.form,
                    ...Utils.getQueryFromLocation(this.props.location),
                },
            })
        }
    }

    handleChangeNumber = (field: string, value: any) => {
        const form: any = { ...this.state.form }

        form[field] = value

        if (field === 'amount') {
            if (form.filterAmountType === 'lte') {
                form.amountTo = value
            } else if (form.filterAmountType === 'gte') {
                form.amountFrom = value
            } else if (form.filterAmountType === 'eq') {
                form.amountFrom = value
                form.amountTo = value
            }
        }

        this.setState({ form })
    }

    handleChangeInput = (field: string, e: any) => {
        let form: any = this.state.form
        if (field === 'query') {
            form = { ...form }
        }
        form[field] = e.target.value
        this.setState({ form })
    }

    handleChangeSelect = (field: string, value: any) => {
        const form: any = this.state.form
        form[field] = field === 'participantUsername' ? value?.trim() : value

        if (field === 'participantType' || field === 'project') {
            unset(form, 'participant')
        }

        if (field === 'filterAmountType') {
            unset(form, 'amount')
            unset(form, 'amountFrom')
            unset(form, 'amountTo')
        }

        if (field === 'participantType' && value === 'outside') {
            unset(form, 'project')
        }

        if (!value) {
            unset(form, 'participantUsername')
            unset(form, 'participant')
        }

        if (field === 'reviewStatus' && !value) {
            unset(form, 'status')
        }

        this.setState({ form })
    }

    handleChangeRangePicker = (field: string, values: any) => {
        const { form }: any = this.state

        if (field === 'timestamp') {
            form['timestampFrom'] = values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null
            form['timestampTo'] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null
        } else if (field === 'preparedAt') {
            form['preparedAtFrom'] = values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null
            form['preparedAtTo'] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null
        } else if (field === 'approvedAt') {
            form['approvedAtFrom'] = values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null
            form['approvedAtTo'] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null
        }

        this.setState({ form })
    }

    handleFilter = () => {
        const filter = { ...this.state.form }

        if (filter.filterAmountType === 'gte') {
            unset(filter, 'amountTo')
        } else if (filter.filterAmountType === 'lte') {
            unset(filter, 'amountFrom')
        } else if (filter.filterAmountType === 'between') {
            unset(filter, 'amount')
        }

        if (filter.participantUsername && filter.project) {
            filter.participant = `c:${filter.project}:${filter.participantUsername}`
        }
        delete filter.status
        if (filter.reviewStatus === 'REJECTED') {
            filter.status = 'REJECTED'
            delete filter.reviewStatus
        }

        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, { ...filter, offset: null, page: null })
    }

    clearFilter = () => {
        this.setState({
            form: {
                participantType: 'inside',
                filterAmountType: 'between',
            },
        })
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {})
    }

    toggleFilterCollapse = () => {
        this.setState({ isCollapse: !this.state.isCollapse })
    }

    toggleFilterShow = () => {
        this.setState({ isShow: !this.state.isShow })
    }

    checkDisableSubmit = ({ ...form }: FormInterface) => {
        delete form.filterAmountType
        delete form.participantType

        return Utils.checkDisableSubmit(form)
    }

    render() {
        const form: FormInterface = this.state.form
        const { isShow } = this.state

        const disableSubmit: boolean = this.checkDisableSubmit(form)

        return (
            <div className="search-form">
                <div
                    className="font-medium capitalize title pointer"
                    onClick={this.toggleFilterShow}>
                    <span className="fsz-16px">Tìm kiếm</span>
                    {isShow ? <i className="fa-solid fa-chevron-up" /> : <i className="fa-solid fa-chevron-down" />}
                </div>

                {isShow && (
                    <Form
                        className="mg-t-20 bottom-item-12"
                        labelCol={{ flex: '132px' }}>
                        <Row gutter={24}>
                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={
                                        <>
                                            Từ khóa
                                            <Tooltip
                                                title={'Mã giao dịch / Mã phiếu / Mã bút toán / Nội dung giao dịch / Username'}
                                                placement={'top'}>
                                                <i className="fa-regular fa-circle-question mg-l-3" />
                                            </Tooltip>
                                        </>
                                    }
                                    labelAlign="left">
                                    <Input
                                        autoFocus
                                        placeholder={'Tìm kiếm theo mã giao dịch, mã phiếu, mã bút toán, nội dung giao dịch, username'}
                                        value={form.query}
                                        onChange={this.handleChangeInput.bind(this, 'query')}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Số hoá đơn'}
                                    labelAlign="left"
                                    className={''}>
                                    <Input
                                        placeholder={'Tìm kiếm theo số hoá đơn'}
                                        value={form.invoice}
                                        onChange={this.handleChangeInput.bind(this, 'invoice')}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Người tạo'}
                                    labelAlign="left"
                                    className="">
                                    <SelectSuggestStaff
                                        selectProps={{
                                            placeholder: 'Tìm kiếm theo người tạo',
                                        }}
                                        value={form.preparedBy}
                                        onChange={(value) => {
                                            this.handleChangeSelect('preparedBy', value)
                                            this.handleFilter()
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Người duyệt'}
                                    labelAlign="left"
                                    className="">
                                    <SelectSuggestStaff
                                        selectProps={{
                                            placeholder: 'Tìm kiếm theo người duyệt',
                                        }}
                                        value={form.reviewedBy}
                                        onChange={(value) => {
                                            this.handleChangeSelect('reviewedBy', value)
                                            this.handleFilter()
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Người xác nhận GD'}
                                    labelAlign="left"
                                    className=" item-overflow-hidden">
                                    <SelectSuggestStaff
                                        selectProps={{
                                            placeholder: 'Tìm kiếm theo người xác nhận giao dịch',
                                        }}
                                        value={form.approvedBy}
                                        onChange={(value) => {
                                            this.handleChangeSelect('approvedBy', value)
                                            this.handleFilter()
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Mục đích chi'}
                                    labelAlign="left"
                                    className="">
                                    <SelectPaymentReason
                                        selectProps={{
                                            placeholder: 'Tìm kiếm theo mục đích chi',
                                        }}
                                        value={form.paymentReason}
                                        onChange={(value) => {
                                            this.handleChangeSelect('paymentReason', value)
                                            this.handleFilter()
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={16}>
                                <Form.Item
                                    label={'Người nhận/nộp'}
                                    labelAlign="left"
                                    labelCol={{ flex: '130px' }}>
                                    <Row gutter={4}>
                                        <Col span={8}>
                                            <Select
                                                value={form.participantType}
                                                onChange={(value) => {
                                                    this.handleChangeSelect('participantType', value)
                                                    this.handleFilter()
                                                }}>
                                                {PARTICIPANT_TYPES.map((item) => (
                                                    <Select.Option
                                                        key={item.key}
                                                        value={item.key}>
                                                        {item.text}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>

                                        <Col span={16}>
                                            {form.participantType === 'inside' ? (
                                                <SelectCustomer
                                                    valueCustomer={form.participantUsername}
                                                    valueProject={form.project}
                                                    onChangeProject={this.handleChangeSelect.bind(this, 'project')}
                                                    onChangeCustomer={(value) => {
                                                        this.handleChangeSelect('participantUsername', value)
                                                    }}
                                                    onCustomerPressEnter={this.handleFilter}
                                                />
                                            ) : (
                                                <Input
                                                    placeholder={'Thông tin người nhận/nộp'}
                                                    value={form.participant}
                                                    onChange={this.handleChangeInput.bind(this, 'participant')}
                                                    onPressEnter={this.handleFilter}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Thời gian tạo'}
                                    labelAlign="left"
                                    className={''}>
                                    <RangePicker
                                        className={'width-100pc'}
                                        value={[form.timestampTo ? moment(form.timestampTo) : null, form.timestampFrom ? moment(form.timestampFrom) : null]}
                                        placeholder={['Từ', 'Đến']}
                                        format={'DD/MM/YYYY'}
                                        onChange={this.handleChangeRangePicker.bind(this, 'timestamp')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Giá trị'}
                                    labelAlign="left"
                                    labelCol={{ flex: '130px' }}>
                                    <Input.Group compact>
                                        <Select
                                            onChange={(value) => {
                                                this.handleChangeSelect('filterAmountType', value)
                                                this.handleFilter()
                                            }}
                                            value={form.filterAmountType}
                                            style={{ width: '25%', minWidth: 95 }}>
                                            {FILTER_TYPES.map((item) => (
                                                <Select.Option
                                                    key={item.code}
                                                    value={item.code}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>

                                        {form.filterAmountType !== 'between' && (
                                            <InputNumber
                                                style={{ width: '70%', textAlign: 'center' }}
                                                value={form.amount}
                                                onChange={this.handleChangeNumber.bind(this, 'amount')}
                                                onPressEnter={this.handleFilter}
                                                placeholder="Giá trị"
                                            />
                                        )}

                                        {form.filterAmountType === 'between' && (
                                            <InputNumber
                                                style={{ width: '30%', textAlign: 'center' }}
                                                value={form.amountFrom}
                                                onChange={this.handleChangeNumber.bind(this, 'amountFrom')}
                                                onPressEnter={this.handleFilter}
                                                placeholder="Từ"
                                            />
                                        )}

                                        {form.filterAmountType === 'between' && (
                                            <Input
                                                className="site-input-split"
                                                style={{
                                                    width: '10%',
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: 'none',
                                                    textAlign: 'center',
                                                }}
                                                placeholder="-"
                                                disabled
                                            />
                                        )}

                                        {form.filterAmountType === 'between' && (
                                            <InputNumber
                                                className="site-input-right"
                                                value={form.amountTo}
                                                style={{
                                                    width: '31%',
                                                    textAlign: 'center',
                                                }}
                                                onChange={this.handleChangeNumber.bind(this, 'amountTo')}
                                                onPressEnter={this.handleFilter}
                                                placeholder="Đến"
                                            />
                                        )}
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            {console.log('form.reviewStatus', form?.reviewStatus, form.status)}
                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Trạng thái giao dịch'}
                                    labelAlign="left"
                                    className="">
                                    <Select
                                        onChange={(value) => {
                                            this.handleChangeSelect('reviewStatus', value)
                                            this.handleFilter()
                                        }}
                                        value={form?.reviewStatus || form?.status}
                                        allowClear
                                        placeholder="Tìm kiếm theo trạng thái GD"
                                        options={TRANSACTION_STATUS_OPTIONS}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Loại giao dịch'}
                                    labelAlign="left"
                                    className="">
                                    <Select
                                        value={form?.transactionType}
                                        placeholder={'Tìm kiếm theo loại GD'}
                                        options={[
                                            {
                                                value: 'ORDER',
                                                label: TRANSACTION_TYPES['ORDER'],
                                            },
                                            {
                                                value: 'CUSTOMER',
                                                label: TRANSACTION_TYPES['CUSTOMER'],
                                            }]
                                        }
                                        onChange={(value) => {
                                            this.handleChangeSelect('transactionType', value)
                                            this.handleFilter()
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={24}
                                md={24}
                                lg={12}
                                xl={8}>
                                <Form.Item
                                    label={'Trạng thái'}
                                    labelAlign="left"
                                    className="">
                                    <Radio.Group
                                        onChange={({ target: { value } }: RadioChangeEvent) => {
                                            this.handleChangeSelect('referenceTx', value)
                                            this.handleFilter()
                                        }}
                                        value={form.referenceTx}
                                        defaultValue={undefined}>
                                        <Radio value={undefined}>Tất cả</Radio>
                                        <Radio value={'true'}>Đã nạp</Radio>
                                        <Radio value={'false'}>Chưa nạp</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="flex space-between items-center">
                            <span
                                onClick={this.toggleFilterShow}
                                className={'font-size-12 cursor-pointer'}>
                                <i className="fa-solid fa-chevron-up mg-r-3" /> Thu gọn
                            </span>

                            <div>
                                {!disableSubmit && (
                                    <Button
                                        type={'text'}
                                        onClick={this.clearFilter}
                                        icon={<i className="fa-solid fa-repeat pd-r-4" />}
                                        className="font-size-12px">
                                        Làm mới bộ lọc
                                    </Button>
                                )}

                                <Button
                                    disabled={disableSubmit}
                                    type="primary"
                                    onClick={this.handleFilter}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </div>
        )
    }
}

export default FilterForm
