import {convertNumberToText} from './libs/number2word.lib'

const provider: string = 'number2word.lib';

let numberToWord: (value: any) => any;

switch (provider) {
    case 'number2word.lib':
    default:
        numberToWord = convertNumberToText;
        break;
}

export {numberToWord}
