import {
    DraggableModal as DraggableModalLib,
    DraggableModalProps as DraggableModalLibProps
} from "./components/DraggableModal";
import React, {forwardRef, ReactNode, useImperativeHandle, useMemo, useState} from "react";

import {DraggableModalProvider} from "./context/DraggableModalProvider";
import './styles.scss'

interface DraggableModalProps extends DraggableModalLibProps {
    handleCancel: () => void;
    children?: ReactNode;
}

export interface DraggableModalRef {
    setVisible: (visible: boolean) => void
}

export const DraggableModal: React.ForwardRefExoticComponent<DraggableModalProps & React.RefAttributes<any>> = forwardRef(
    ({
         handleCancel,
         ...props
     }: DraggableModalProps, ref?: any) => {
        const [visible, setVisible] = useState<boolean>(true)

        const initialWidth = useMemo(() => {
            const SCREEN_SM_MAX = 767
            const MODAL_WIDTH_ON_LARGE_SCREEN = 660
            if (window.innerWidth < SCREEN_SM_MAX) {
                return (window.innerWidth / 100) * 90
            }

            return MODAL_WIDTH_ON_LARGE_SCREEN
        }, [])

        useImperativeHandle(ref, (): DraggableModalRef => ({
            setVisible
        }))

        return (
            <>
                {!visible && (
                    <div className="hidden-modal" onClick={() => setVisible(true)}>
                    <span>
                        {props.title}
                    </span>
                        <i className="fa-solid fa-xmark px-8 py-8 " onClick={handleCancel}/>
                    </div>
                )}

                <DraggableModalProvider>
                    <DraggableModalLib
                        initialWidth={initialWidth}
                        closeIcon={<>
                            <i className="fa-solid fa-minus px-8 py-8" onClick={() => setVisible(false)}/>
                            <i className="fa-solid fa-xmark px-8 py-8 mg-r-20" onClick={handleCancel}/>
                        </>}
                        {...props}
                        visible={visible}
                    >
                        {props.children}
                    </DraggableModalLib>
                </DraggableModalProvider>
            </>
        )
    })
