import React from 'react';
import {Card} from "antd";
import Barcode from "../../../Component/Barcode";
import ReceiptVoucherDomain from "../../../Domain/ReceiptVoucherDomain";

interface Props {
    receiptVoucher: ReceiptVoucherDomain
}

class VoucherBarcode extends React.Component<Props, any> {

    render() {
        const {receiptVoucher} = this.props;
        return (
            <Card title={"Mã vạch"} className={'mg-bt-12 capitalize'}>
                <Barcode value={receiptVoucher.code} width={160} />
            </Card>
        )
    }
}

export default VoucherBarcode;