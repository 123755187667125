export default interface RoleDomain {
    id: string
    code: string,
    name: string,
    status: string
}

export const DefaultRoleModel: RoleDomain = {
    id: '',
    code: "",
    name: "",
    status: "ENABLE"
}
