import React from "react";
import UserDomain from "../../Domain/UserDomain";
import {Select, SelectProps} from "antd";
import SuggestStaffQueryCriteria from "../../QueryCriteria/SuggestStaffQueryCriteria";
import StaffApi from "../../Api/StaffApi";
import DefaultPropsInterface from "../../Interface/DefaultPropsInterface";

interface Props {
    value?: any,
    onChange?: (value: any) => void,
    selectProps?: SelectProps<any>,
    filteredItems?: (items: Array<UserDomain>) => Array<UserDomain>,

}

interface State {
    loading: boolean,
    items: Array<UserDomain>
}

interface Props extends DefaultPropsInterface{}


class SelectSuggestStaff extends React.Component<Props, State> {

    state = {
        loading: false,
        items: [],
    }

    componentDidMount() {
        this.fetchSuggestStaff();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.value !== prevProps.value) {
            this.fetchSuggestStaff();
        }
    }

    fetchSuggestStaff = (query: SuggestStaffQueryCriteria = {}) => {
        this.setState({loading: true});
        StaffApi.getSuggestStaff({...query,limit:1000,offset:0})
            .then(response => {
                this.setState({
                    items: response.data
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleSearch = (value: any) => {
        if (value && value.toString().length >= 3) {
            this.fetchSuggestStaff({
                query: value
            })
        }
        else if (!value) {
            this.fetchSuggestStaff();
        }
    };

    handleFilteredItems = (items: Array<UserDomain>) => {
        return this.props.filteredItems? this.props.filteredItems(items) : items;
    }

    render() {
        const {onChange, selectProps, value} = this.props;
        const {items} = this.state;
        const filteredItems = this.handleFilteredItems(items);
        return (
            <Select
                allowClear
                value={value}
                filterOption={false}
                showSearch={true}
                onChange={onChange}
                onSearch={this.handleSearch}
                placeholder={"Chọn nhân viên"}
                {...selectProps}
            >
                {filteredItems.map((item: UserDomain) => (
                    <Select.Option key={item.username} value={item.username}>{item.username}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default SelectSuggestStaff;
