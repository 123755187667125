import React from 'react';
import {Button, Card, Empty, Switch, Table} from "antd";
import AccountApi from "../../../Api/AccountApi";
import lodash from "lodash";
import {WebhookQueryCriteria} from "../../../QueryCriteria/WebhookQueryCriteria";
import { NotificationCommon } from '../../../Component/Notification';

interface Props {
    accountCode: string
}

interface State {
    loading: boolean
    webhooks: Array<any>
    pagination: {
        currentPage: number,
        pageSize: number,
        total: number
    }
    columns: Array<any>
}

export class ConfigWebhook extends React.Component<Props, State> {

    state: State = {
        loading: false,
        webhooks: [],
        pagination: {
            currentPage: 1,
            pageSize: 10,
            total: 0
        },
        columns: [
            {
                key: "channel",
                title: "Ứng dụng",
                dataIndex: "channel"
            },
            {
                key: "enabled",
                title: "Trạng thái",
                dataIndex: "enabled",
                render: (enabled: any, row: any) => <Switch size={'small'} checked={enabled} onChange={this.handleEnableWebhook.bind(this, row)} />
            }
        ]
    }

    componentDidMount() {
        // this.fetchWebhooks()
    }

    fetchWebhooks = (criteria: WebhookQueryCriteria = {}) => {
        const {accountCode} = this.props;
        const {pagination} = this.state;
        this.setState({loading: true})
        criteria = {
            offset: 0,
            limit: pagination.pageSize,
            sort: 'createdAt:desc',
            ...criteria
        }
        AccountApi.getWebhooks(accountCode, criteria)
            .then(response => {
                this.setState({
                    webhooks: response.data,
                    pagination: {
                        currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                        pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                        total: parseInt(lodash.get(response, 'headers.x-total-count'))
                    }
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleCreateWebhook = () => {
        const {accountCode} = this.props;
        this.setState({loading: true})
        AccountApi.createWebhook(accountCode, {channel: 'CASSO'})
            .then(() => {
                NotificationCommon.success({
                    message: "Tạo thành công"
                })

                this.fetchWebhooks()
            })
            .catch(() => {
                NotificationCommon.error({
                    message: "Tạo thất bại"
                })
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    handleEnableWebhook = (item: any) => {
        const {accountCode} = this.props;
        const {pagination} = this.state;
        this.setState({loading: true});
        AccountApi.updateWebhook(accountCode, item.id,{enabled: !item.enabled})
            .then(() => {
                NotificationCommon.success({
                    message: 'Cập nhật thành công'
                })

                this.fetchWebhooks({
                    offset: (pagination.currentPage - 1) * pagination.pageSize,
                    limit: pagination.pageSize
                })
            })
            .catch(() => {
                NotificationCommon.error({
                    message: "Cập nhật thất bại"
                })
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    handleChangePage = (page: any, pageSize: any) => {
        this.fetchWebhooks({
            offset: (page - 1) * pageSize,
            limit: pageSize
        })
    }

    render() {
        const {webhooks, pagination, columns, loading} = this.state;
        return (
            <Card
                className={'account-info'}
                title={`Webhook`}
                extra={<Button size={'small'} type={'link'} onClick={this.handleCreateWebhook} className={'font-size-12'}>
                    <span><i className="fa-solid fa-plus mg-r-6"/></span>
                    <span>Tạo Webhook</span>
                </Button>}
            >
                {webhooks.length ? (
                    <Table
                        loading={loading}
                        columns={columns}
                        rowKey={'id'}
                        dataSource={webhooks}
                        pagination={{
                            current: pagination.currentPage,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            onChange: this.handleChangePage,
                        }}
                    />
                ) : (
                    <Empty description={'Chưa cấu hình webhook'} />
                )}
            </Card>
        )
    }
}
