import {Card, Timeline} from 'antd';
import React from 'react';

class ActionLog extends React.Component {

    render() {
        return (
            <Card title={"Lịch sử thao tác"} className={'mg-bt-20 hide capitalize'}>
                <Timeline>
                    <Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
                    <Timeline.Item>Solve initial network problems 2015-09-01</Timeline.Item>
                    <Timeline.Item>Technical testing 2015-09-01</Timeline.Item>
                    <Timeline.Item>Network problems being solved 2015-09-01</Timeline.Item>
                </Timeline>
            </Card>
        );
    }
}

export default ActionLog;