import apiClient from "../Util/ApiClient";
import {PaymentReasonCreateCommand} from "../Command/PaymentReasonCreateCommand";
import {PaymentReasonUpdateCommand} from "../Command/PaymentReasonUpdateCommand";

export default class PaymentReasonApi {

    static filter = (query = {}) => {
        return apiClient.get('/payment-reasons', {
            params: query
        })
    };

    static autocomplete = (query = {}) => {
        return apiClient.get('/payment-reasons', {
            params: query
        })
    };

    static create = (data: PaymentReasonCreateCommand) => {
        return apiClient.post('/payment-reasons', data)
    };

    static getByCode = (code: string) => {
        return apiClient.get(`/payment-reasons/${code}`)
    };

    static update = (code: string, data: PaymentReasonUpdateCommand) => {
        return apiClient.patch(`/payment-reasons/${code}`, data)
    }
}