import {Alert} from "antd";
import React, {useEffect, useState} from "react";
import PaymentVoucherDomain from "../../../Domain/PaymentVoucherDomain";
import {Link} from "react-router-dom";
import AccountApi from "../../../Api/AccountApi";

interface Props {
    voucher: PaymentVoucherDomain
}

export const VoucherWarning = (props: Props) => {
    const {voucher} = props;
    const [countWarning, setCountWarning] = useState(0);

    useEffect(() => {
        AccountApi.getPaymentVoucherWarnings(voucher.account.code, voucher.paymentReason.code, voucher.invoice)
            .then(response => {
                setCountWarning(response.data.length)
            })
    }, [voucher])

    let message;
    switch (voucher.status.code) {
        case "PENDING":
            message = <div>Đã có {countWarning} lần chi cho hóa đơn <Link to={`/payment-vouchers?invoice=${voucher.invoice}`}>{voucher.invoice}</Link>, bạn vui lòng kiểm tra kỹ trước khi duyệt</div>
            break;
        case "APPROVED":
            message = <div>Đã có {countWarning} lần chi cho hóa đơn <Link to={`/payment-vouchers?invoice=${voucher.invoice}`}>{voucher.invoice}</Link></div>
            break;
        default:
            break
    }

    return (
        <Alert
            className={'mg-bt-10'}
            message={message} type="warning" />
    )
}
