import React, { Key } from 'react'
import { Badge, Button, Card, Divider, Spin, Tooltip } from 'antd'
import { CrownFilled } from '@ant-design/icons'
import AccountDomain, { IPrivilage } from '../../../Domain/AccountDomain'
import StaffApi from '../../../Api/StaffApi'
import lodash from 'lodash'
import AccountApi from '../../../Api/AccountApi'
import { AxiosError } from 'axios'
import RoleApi from '../../../Api/RoleApi'
import RoleDomain from '../../../Domain/RoleDomain'
import { NotificationCommon } from '../../../Component/Notification'
import { ModalConfirm } from '../../../Component/ModalCommon/ModalConfirm'
import { SelectAccount } from '../components/SelectAccount'

interface Props {
    username: string
}

interface IAccoundOption {
    value: string
    label: React.ReactNode
    owner: boolean
    privilege: IPrivilage
    display: string
}

interface State {
    loading: boolean
    accounts: IAccoundOption[]
    roles: Array<RoleDomain>
    isHasRole: undefined | boolean
    selectedRowKey: Key[]
    isShowAssignRoleModal: boolean
    accountSelectedByRole: {
        [roleCode in string]: AccountDomain['code'][] // accountCode[]
    }
    accountNameMapping: { [key in string]: string }
}

class AccountList extends React.Component<Props, State> {
    state: State = {
        loading: false,
        accounts: [],
        roles: [],
        isHasRole: undefined,
        selectedRowKey: [],
        isShowAssignRoleModal: false,
        accountSelectedByRole: {},
        accountNameMapping: {},
    }

    componentDidMount() {
        this.fetchRoles()
        this.fetchAccounts()
    }

    fetchAccounts() {
        this.setState({ loading: true })
        StaffApi.getAccountsByUsername(this.props.username)
            .then((response) => {
                let roleTemp: any = {}
                let accountName: any = {}
                const accountParsed: any = response.data.accounts.map((a) => {
                    if (a.privilege) {
                        const roleCode: string = a.privilege.role?.code
                        if (!roleTemp[roleCode]) {
                            roleTemp[roleCode] = [a.account.code]
                        } else {
                            roleTemp[roleCode].push(a.account.code)
                        }
                    }
                    const display = `${a.account.name} (${a.account.code})`
                    accountName[a.account.code] = display
                    return {
                        value: a.account.code,
                        label: (
                            <span>
                                {a.owner && (
                                    <Tooltip title={'Chủ tài khoản quỹ'}>
                                        <CrownFilled className={'text-danger mg-r-4'} />
                                    </Tooltip>
                                )}
                                {display}
                            </span>
                        ),
                        disabled: a.owner,
                        display: display,
                        privilege: a.privilege,
                        owner: a.owner,
                    }
                })
                this.setState({ accounts: accountParsed, accountSelectedByRole: roleTemp, accountNameMapping: accountName })
            })
            .catch((err) => {
                console.log('err', err)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchRoles = (query: any = {}) => {
        let temp = {
            ...query,
            offset: 0,
            limit: 1000,
        }
        RoleApi.filter(temp).then((response) => {
            this.setState({
                roles: response.data,
            })
        })
    }

    handleAddRole = (accountCode: string, roleCode: string) => {
        if (this.state.loading) return

        this.setState({ loading: true })
        const { accountSelectedByRole, accounts } = this.state
        AccountApi.addPrivilege(accountCode, { staffs: [this.props.username], role: roleCode })
            .then((res) => {
                NotificationCommon.success({
                    message: `Cập nhật thành công`,
                    icon: <i className="fa-solid fa-circle-check" />,
                })

                this.setState({
                    accountSelectedByRole: {
                        ...accountSelectedByRole,
                        [roleCode]: [...(accountSelectedByRole[roleCode] || []), accountCode],
                    },
                    accounts: accounts.map((a) =>
                        a.value === accountCode
                            ? {
                                  ...a,
                                  privilege: res.data[0],
                              }
                            : a
                    ),
                })
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'privilege_existed') {
                        NotificationCommon.error({
                            message: 'Đã tồn tại vai trò khác trong quỹ đã chọn. Vui lòng kiểm tra lại.',
                            icon: <i className="fa-solid fa-triangle-exclamation" />,
                        })
                    } else {
                        NotificationCommon.error({
                            message: 'Cập nhật thất bại',
                            icon: <i className="fa-solid fa-triangle-exclamation" />,
                        })
                    }
                }
                this.setState({
                    accountSelectedByRole: {
                        ...accountSelectedByRole,
                        [roleCode]: accountSelectedByRole[roleCode] || [],
                    }
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleRemoveRole = (accountCode: string, privilegeId: string, roleCode: string) => {
        const { accountSelectedByRole } = this.state
        ModalConfirm.confirm({
            title: 'Xóa Vai trò của nhân viên',
            content: <>Bạn có chắc chắn muốn xoá vai trò này của nhân viên?</>,
            onOk: () => {
                this.setState({ loading: true })
                AccountApi.removePrivilege(accountCode, privilegeId)
                    .then(() => {
                        NotificationCommon.success({
                            message: 'Xoá vai trò thành công',
                            icon: <i className="fa-solid fa-circle-check" />,
                        })

                        this.setState({
                            accountSelectedByRole: {
                                ...accountSelectedByRole,
                                [roleCode]: accountSelectedByRole[roleCode].filter((a) => a !== accountCode),
                            },
                        })
                    })
                    .catch(({ response }: AxiosError<any>) => {
                        let msg = 'Xoá vai trò thất bại'
                        if (response?.status === 403) {
                            msg = 'Bạn không có quyền thực hiện thao tác này'
                        }
                        if (response?.data && response?.data?.title === 'unable_privilege_self_delete') {
                            msg = 'Không thể xóa chính mình ra khỏi quỹ'
                        }
                        NotificationCommon.error({
                            message: msg,
                        })
                    })
                    .finally(() => {
                        this.setState({ isHasRole: undefined, loading: false })
                    })
            },
            okButtonProps: { danger: true },
            okText: 'Xóa',
        })
    }

    removeRoleByCodes = (accountCodeForDelete: string[], roleCode?: string) => {
        this.setState({ loading: true })
        AccountApi.removeAllPrivilege({
            accounts: accountCodeForDelete,
            staff: this.props.username,
        })
            .then(({ data }) => {
                if (data.length === accountCodeForDelete.length) {
                    NotificationCommon.success({
                        message: 'Xóa vai trò thành công',
                    })
                } else {
                    NotificationCommon.warning({
                        message: 'Xóa vai trò thất bại trên một số quỹ. Không thể xóa nếu nhân viên là chủ sở hữu duy nhất của quỹ hoặc bạn không có quyền xóa.',
                        duration: 10,
                    })
                }

                if (roleCode) {
                    const { accountSelectedByRole } = this.state

                    this.setState({
                        accountSelectedByRole: {
                            ...accountSelectedByRole,
                            [roleCode]: [],
                        },
                        loading: false,
                    })
                } else {
                    this.fetchAccounts()
                }
            })
            .catch(() => {
                NotificationCommon.error({
                    message: 'Hủy quyền thất bại',
                    icon: <i className="fa-solid fa-triangle-exclamation" />,
                })
                this.setState({ loading: false })
            })
            .finally(() => {
                this.setState({ isHasRole: undefined })
            })
    }

    onRemoveAllRole = () => {
        const { accounts } = this.state
        const accountCodeForDelete: string[] = []
        accounts.forEach((a) => {
            if (a.privilege) {
                accountCodeForDelete.push(a.value)
            }
        })

        ModalConfirm.confirm({
            title: 'Hủy Quyền Quỹ Của Nhân Viên',
            content: (
                <div>
                    <div>Bạn có chắc chắn muốn xoá nhân viên này ra khỏi tất cả các quỹ mà tài khoản hiện tại đang có quyền?</div>
                    <div className="mg-t-8 font-size-12">
                        <span className="text-warning">
                            <i className="fa-light fa-triangle-exclamation"></i> Lưu ý:
                        </span>{' '}
                        <span className="text-secondary-color">
                            Không thể xóa vai trò khỏi quỹ nếu nhân viên là chủ sở hữu duy nhất của quỹ. Vai trò của nhân viên sẽ bị xoá khỏi các quỹ còn lại.
                        </span>
                    </div>
                </div>
            ),
            width: 560,
            onOk: () => this.removeRoleByCodes(accountCodeForDelete),
            okButtonProps: { danger: true },
            okText: 'Xóa khỏi quỹ',
        })
    }

    onRemoveMultipleRole = (roles: string[], roleCode: string) => {
        ModalConfirm.confirm({
            title: 'Xóa Vai trò của nhân viên',
            content: <>Bạn có chắc chắn muốn xoá vai trò này của nhân viên trên các quỹ?</>,
            onOk: () => this.removeRoleByCodes(roles, roleCode),
            okButtonProps: { danger: true },
            okText: 'Xóa',
        })
    }

    render() {
        const { accounts, loading, roles, accountSelectedByRole } = this.state
        return (
            <>
                <Card
                    title={
                        <div className="flex justify-content-space-between align-items-center gap-2 flex-wrap">
                            <div className='flex align-items-center'>
                                <span className={'capitalize mg-r-4'}>Danh sách vai trò</span>
                                <Badge count={roles.length} overflowCount={99}/>
                            </div>
                            <div className="flex gap-3">
                                <Button
                                    icon={<i className="fa-regular fa-xmark"></i>}
                                    disabled={loading || Object.keys(accountSelectedByRole).length < 1}
                                    danger
                                    onClick={this.onRemoveAllRole}>
                                    Hủy quyền tất cả các quỹ
                                </Button>
                            </div>
                        </div>
                    }
                    bodyStyle={{ overflow: 'auto' }}>
                    <div style={{ minWidth: 500 }}>
                        <Spin spinning={loading}>
                            <div className="role-row role-title">
                                <span className="font-medium">Vai trò</span>
                                <span className="font-medium">Tài khoản quỹ</span>
                            </div>

                            {roles.map((role) => (
                                <div
                                    key={role.code}
                                    className="pd-l-12 pd-r-12">
                                    <div className="role-row">
                                        <div>{role.name}</div>
                                        <SelectAccount
                                            loading={loading}
                                            roleCode={role.code}
                                            privilegeId={role.id}
                                            options={accounts as any}
                                            accountSelectedByRole={accountSelectedByRole}
                                            handleAddRole={this.handleAddRole}
                                            handleRemoveRole={this.handleRemoveRole}
                                            onRemoveMultipleRole={this.onRemoveMultipleRole}
                                        />
                                    </div>
                                    <Divider orientationMargin={12} />
                                </div>
                            ))}
                        </Spin>
                    </div>
                </Card>
            </>
        )
    }
}

export default AccountList
