import { Layout, Spin } from 'antd'
import lodash from 'lodash'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import AccountApi from '../../Api/AccountApi'
import { AppContext } from '../../Context/AppContext'
import AccountDomain, { AccountModel } from '../../Domain/AccountDomain'
import UserDomain from '../../Domain/UserDomain'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import localStore from '../../Util/LocalStore'
import SecurityService from '../../Util/SecurityService'
import Utils from '../../Util/Utils'
import Header, { BreadcrumbInterface } from './Header'
import SideBar from './Sidebar'
import ProfileApi from '../../Api/ProfileApi'
import { AxiosResponse } from 'axios'

const { Content } = Layout

interface Props extends DefaultPropsInterface {
    loading?: boolean
    breadcrumb?: BreadcrumbInterface[]
}

interface State {
    account: AccountDomain
    accounts: Array<AccountDomain>
    loading: boolean
    collapsed: boolean
    user: UserDomain | null
    permissions: string[]
}

export const hiddenPaths = [
    '/accounts',
    '/devices',
    '/security',
    '/payment-vouchers/:code',
    '/receipt-vouchers/:code',
    '/staffs',
    '/staffs/:username',
    '/payment-reasons',
    '/noaccount-sms'
]

class DefaultLayout extends Component<Props, State> {
    static defaultProps = {
        loading: false,
    }

    static contextType = AppContext

    state: State = {
        account: Utils.getAppContext(this).state.account,
        accounts: [],
        loading: false,
        collapsed: true,
        user: SecurityService.getUser(),
        permissions: localStore.getJson('permissions') || [],
    }

    componentDidMount() {
        this.fetchMyAccounts({}, true)
        if (this.state.account.code) {
            Utils.getAppContext(this).func.updateAccountPermission(this.state.account.code)
        } else {
            localStore.setJson('accountPermissions', { owner: false, permissions: undefined })
        }
        this.fetchPermissions()
    }

    fetchPermissions = () => {
        ProfileApi.getPermissions().then((response) => {
            localStore.setJson('permissions', response.data)
            this.setState({
                permissions: response.data,
            })
        })
    }

    unsetAccount = () => {
        Utils.getAppContext(this).func.updateAccount({ ...AccountModel })
        this.setState({
            account: { ...AccountModel },
        })
    }

    isShowSelectAccount = () => {
        for (let path of hiddenPaths) {
            if (lodash.startsWith(this.props.path, path)) {
                return false
            }
        }
        return true
    }

    fetchMyAccounts = (query = {}, isIninial = false) => {
        if (!this.isShowSelectAccount()) {
            return
        }

        query = {
            sort: 'name:ASC',
            offset: 0,
            limit: 1300,
            ...query,
        }
        this.setState({ loading: true })
        AccountApi.autocomplete(query)
            .then((response: AxiosResponse<any>) => {
                if(response && response.data) {
                    this.setState({
                        accounts: response.data,
                    })
                } else {
                    this.setState({
                        accounts: [],
                    })
                }

                if (!isIninial) {
                    return
                }

                if (response.data.length) {
                    const { account } = this.state

                    const isAccountExist = response.data.findIndex((a: AccountDomain) => a.code === account.code)

                    const accountCode = !account.code || isAccountExist === -1 ? lodash.get(response.data, '0.code') : account.code

                    this.fetchAccountByCode(accountCode)
                } else {
                    this.unsetAccount()
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchAccountByCode = (code: string, callback?: () => void) => {
        if (code) {
            const context: any = Utils.getAppContext(this)
            context.func.setLoading(true)
            AccountApi.findByCode(code)
                .then((response) => {
                    this.setState({ account: response.data })
                    localStore.setJson('account', response.data)
                    Utils.getAppContext(this).func.updateAccount(response.data)
                    Utils.getAppContext(this).func.updateAccountPermission(lodash.get(response.data, 'code'))
                    callback && callback()
                })
                .catch(() => {
                    // localStore.removeItem('account');
                    // Utils.getAppContext(this).func.updateAccount({...AccountModel})
                })
        }
    }

    handleChangeAccount = (accountCode: string) => {
        this.fetchAccountByCode(accountCode, () => {
            setTimeout(() => {
                window.location.reload()
            }, 500)
        })
    }

    handleSearchAccount = (value: any) => {
        if (value && value.length >= 3) {
            const accounts = this.state.accounts.filter((account: any) => {
                return lodash.concat(account.name, account.code).toString().toLowerCase().indexOf(value.toLowerCase()) >= 0
            })
            this.setState({
                accounts,
            })
            this.fetchMyAccounts({ query: value })
        } else if (!value) {
            this.fetchMyAccounts()
        }
    }

    toggle = (isColapsed?: boolean, callback?: () => void) => {
        this.setState(
            {
                ...this.state,
                collapsed: isColapsed !== undefined ? isColapsed : !this.state.collapsed,
            },
            () => {
                if (typeof callback === 'function') {
                    callback()
                }
            }
        )
    }

    render() {
        const { loading, breadcrumb } = this.props
        const { collapsed, user, account, accounts, permissions } = this.state

        return (
            <DocumentTitle title={this.props.hasOwnProperty('title') && this.props.title !== undefined ? this.props.title : 'M7 Hamel'}>
                <Layout className={'main-layout'}>
                    <SideBar
                        collapsed={collapsed}
                        history={this.props.history}
                        match={this.props.match}
                        toggle={this.toggle}
                        account={account}
                        permissions={permissions}
                    />
                    <Layout>
                        <Header
                            accountLoading={this.state.loading}
                            collapsed={collapsed}
                            toggle={this.toggle}
                            user={user}
                            account={account}
                            accounts={accounts}
                            breadcrumb={breadcrumb}
                            onChangeAccount={this.handleChangeAccount}
                            onSearchAccount={this.handleSearchAccount}
                            {...this.props}
                        />
                        <Content className={'main-content '}>
                            <div className={loading ? 'loading-container ' : 'main-inner '}>
                                <Spin
                                    tip={'Đang tải...'}
                                    spinning={loading}
                                    className={''}>
                                    {this.props.children}
                                </Spin>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </DocumentTitle>
        )
    }
}

export default DefaultLayout
