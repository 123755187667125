import React from 'react';
import {Card, Modal} from "antd";
import PaymentVoucherDomain from "../../../Domain/PaymentVoucherDomain";

interface Props {
    paymentVoucher: PaymentVoucherDomain
}

interface State {
    previewVisible: boolean
    previewImage: string
}

class InvoiceAttachment extends React.Component<Props, State> {

    state: State = {
        previewVisible: false,
        previewImage: ""
    }

    preview = (url: string) => {
        this.setState({
            previewVisible: true,
            previewImage: url
        })
    }

    handleCancelPreview = () => {
        this.setState({
            previewVisible: false,
            previewImage: ""
        })
    }

    render() {
        const {attachmentInvoices} = this.props.paymentVoucher;
        const {previewVisible, previewImage} = this.state;
        return (
            <Card title={"Ảnh hoá đơn"} className={'mg-bt-12 capitalize'}>
                <div className="voucher-invoice-attachment-list">
                    {attachmentInvoices && Array.isArray(attachmentInvoices) && attachmentInvoices.map(url => (
                        <div key={url} className={'voucher-invoice-attachment pointer mg-r-5'}>
                            <img onClick={this.preview.bind(this, url)} className={'pointer voucher-invoice-attachment-img img-cover'} src={url} alt={''} />
                        </div>
                    ))}
                </div>

                <Modal
                    visible={previewVisible}
                    title={'Ảnh hoá đơn'}
                    closeIcon={<i className="fa-solid fa-xmark"  />}
                    footer={null}
                    onCancel={this.handleCancelPreview}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Card>
        )
    }
}

export default InvoiceAttachment;
