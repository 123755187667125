import React from "react";
import UserDomain from "../../../Domain/UserDomain";
import {Col, Image, Row} from "antd";
import moment from "moment";
import md5 from "md5";

interface Props {
    staff: UserDomain
}

interface State {

}

export class StaffInfo extends React.Component<Props, State> {

    renderAvatar = (user: any) => {
        const hash = user.email ? md5(user.email) : md5(user.username);
        return `https://www.gravatar.com/avatar/${hash}?d=robohash&s=200`;
    }

    render() {
        const {staff} = this.props;
        return (
            <Row gutter={12}>
                <Col xs={10} lg={8} className={'staff-info'}>
                    <Image
                        style={{width: '100%', height: 'auto'}}
                        className={'img-cover staff-detail-img-cover mg-bt-10'}
                        preview={{
                            mask: 'Xem ảnh'
                        }}
                        src={this.renderAvatar(staff)}
                    />
                </Col>
                <Col xs={14} lg={16} className={'staff-info'}>
                    <div className={'pd-l-12'}>
                        <div className={'mg-bt-5'}>
                            <span className={'staff-detail-info-label'}>Username:</span>
                            <span className={'staff-detail-info-content'}>{staff.username}</span>
                        </div>
                        <div className={'mg-bt-5'}><span
                            className={'staff-detail-info-label'}>Email:</span>
                            <span className={'staff-detail-info-content'}>{staff.email}</span>
                        </div>
                        <div className={'mg-bt-5'}><span
                            className={'staff-detail-info-label'}>Số điện thoại:</span>
                            <span className={'staff-detail-info-content'}>{staff.mobile ? staff.mobile : '--'}</span>
                        </div>
                        <div className={'mg-bt-5'}><span
                            className={'staff-detail-info-label'}>Họ và tên:</span>
                            <span className={'staff-detail-info-content'}>{staff.name}</span>
                        </div>
                        <div className={'mg-bt-5'}><span
                            className={'staff-detail-info-label'}>Giới tính:</span>
                            <span className={'staff-detail-info-content'}>{staff.gender === 'female' ? 'Nữ' : (staff.gender === 'male' ? 'Nam' : 'N/A')}</span>
                        </div>
                        <div className={'mg-bt-5'}><span
                            className={'staff-detail-info-label'}>Ngày tạo:</span>
                            <span className={'staff-detail-info-content'}>{staff.createdAt ? moment(staff.createdAt).format('DD/MM/YYYY') : '--'}</span>
                        </div>
                        <div className={'mg-bt-5'}>
                            <span className={'staff-detail-info-label'}>Sinh nhật:</span>
                            <span className={'staff-detail-info-content'}>{staff.dob ? staff.dob : '--'}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
