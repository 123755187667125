import {Checkbox, Col, Modal, Row, Spin} from 'antd';
import {AxiosError, AxiosResponse} from "axios";
import lodash, {uniq} from 'lodash';
import React, {ReactNode} from 'react';
import PermissionApi from "../../Api/PermissionApi";
import RoleApi from "../../Api/RoleApi";
import RoleDeletePermissionCommand from "../../Command/RoleDeletePermissionCommand";
import RoleGrantPermissionCommand from "../../Command/RoleGrantPermissionCommand";
import {NotificationCommon} from '../../Component/Notification';
import PermissionDomain from "../../Domain/PermissionDomain";
import RoleDomain from "../../Domain/RoleDomain";
import {PERMISSION_GROUP_NAME} from "./const";

interface State {
    form: RoleGrantPermissionCommand,
    loading: boolean,
    permissions: Array<PermissionDomain>,
    groupAllPermission:any,
    groupRender: ReactNode,
    setting:boolean,
}

interface Props {
    onVisibleChange: (visible: boolean) => void,
    role: RoleDomain
}

class GrantPermission extends React.Component<Props, State> {
    state: State = {
        form: {
            permissions: []
        },
        loading: false,
        permissions: [],
        groupAllPermission:{},
        groupRender: undefined,
        setting:false,
    }

    async componentDidMount() {
        await this.fetchPermissions();
        await this.fetchRolePermissions();
        this.handleRenderRolePermissionGroup();
    }

    fetchPermissions = async () => {
        this.setState({loading: true});

        try {
            const response: AxiosResponse = await PermissionApi.filter({limit: 1000});
            this.setState({
                permissions: response.data,
                groupAllPermission:this.handleGroupPermission(response.data),
            });

        } catch (error) {}
        this.setState({loading: false});
    }

    fetchRolePermissions = async () => {
        this.setState({loading: true});
        try {
            const response: AxiosResponse = await RoleApi.getPermissions(this.props.role.code);
            this.setState({
                form: {
                    ...this.state.form,
                    permissions: response.data.map((p: any) => p.code)
                },
            });
        } catch (error) {}
        this.setState({loading: false});
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value
            }
        })
    };

    handleAddPermission = (command: RoleGrantPermissionCommand) => {
        const {role} = this.props;
        this.setState({loading: true});
        RoleApi.grantPermissions(role.code, command)
            .then(() => {
                this.handleUpdateSuccess();
            })
            .catch((error: AxiosError) => {
                this.handleUpdateFail(error);
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleRemovePermission = (command: RoleDeletePermissionCommand) => {
        const {role} = this.props;
        this.setState({loading: true});
        RoleApi.deletePermissions(role.code, command)
            .then(() => {
                this.handleUpdateSuccess();
            })
            .catch((error: AxiosError) => {
                this.handleUpdateFail(error);
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleUpdateSuccess = () => {
        NotificationCommon.success({
            key: 'grant_permission',
            message: "Cập nhật vai trò thành công",
            icon: <i className="fa-solid fa-circle-check"/>,
        });
    }

    handleUpdateFail = (error: AxiosError) => {
        NotificationCommon.error({
            key: 'grant_permission',
            message: "Cập nhật vai trò lỗi: " + error.message,
            icon: <i className="fa-solid fa-triangle-exclamation"/>,
        })
    }

    handleCheckAllChange = (e: any) => {
        const {permissions, form} = this.state;
        if (e.target.checked) {
            form.permissions = permissions.map(p => p.code);
            this.handleAddPermission(form);
        } else {
            form.permissions = [];
            this.handleRemovePermission({
                permissions: permissions.map(p => p.code)
            });
        }
        this.setState({form,setting:!this.state.setting});
        this.handleRenderRolePermissionGroup()
    }

    handleCheckboxChange = (permission: PermissionDomain, e: any) => {
        const {form} = this.state;
        if (e.target.checked) {
            form.permissions.push(permission.code);
            this.handleAddPermission({
                permissions: [permission.code]
            });
        } else {
            form.permissions = form.permissions.filter((code: any) => code !== permission.code);
            this.handleRemovePermission({
                permissions: [permission.code]
            });
        }
        form.permissions = uniq(form.permissions);
        this.setState({form,setting:!this.state.setting});
        this.handleRenderRolePermissionGroup()
    }

    handleGroupPermission = (permissions?: Array<PermissionDomain>) => {
        let outputResult:any;
        let newResult:any = {};
        if (permissions) {
            outputResult = permissions.reduce((r: any, a: any) => {
                r[a.code.split(':')[0]] = r[a.code.split(':')[0]] || [];
                r[a.code.split(':')[0]].push(a);
                return r;
            }, Object.create(null));

            Object.keys(outputResult).forEach((k)=> {
                let newKey:any = k.split('-');
                if (newResult[newKey[0]]) newResult[newKey[0]].push(...outputResult[k])
                else newResult[newKey[0]] = outputResult[k]
            })
        }
        return newResult;
    }

    handleRenderRolePermissionGroup = () => {
        const {groupAllPermission, form} = this.state;
        this.setState({
            groupRender: lodash.map(groupAllPermission, (value, key) => {
                return (<>
                    <Col className={'mg-bt-6'} key={key}>
                        <div className={`mg-bt-10 group-label`}>
                            {this.getGroupName(key)}
                        </div>
                        <Row>
                            {groupAllPermission[key].map((p: any) => {
                                let checked = form.permissions.includes(p.code)
                                return (
                                    <Col xs={12} sm={8} lg={8}
                                         xl={groupAllPermission[key].length <= 2 ? 12 : 8}
                                         key={p.code}
                                    >
                                        <div className={'mg-bt-6 px-12'}>
                                            <Checkbox
                                                checked={checked}
                                                onChange={this.handleCheckboxChange.bind(this, p)}
                                                value={p.code}>{p.name}</Checkbox>
                                        </div>
                                    </Col>
                                )}
                            )}
                        </Row>
                    </Col>
                </>)
            })
        });
    }

    getGroupName = (keyCode: string) => {
        let item = PERMISSION_GROUP_NAME.find((i) => i.key === keyCode);
        return <span className={'font-size-14 bold'}>{item ? item.name : keyCode}</span>;
    }

    render() {
        const {loading,permissions,form} = this.state;
        const {role} = this.props;
        const checkAll = form.permissions.length === permissions.length;
        return (
            <Modal
                className={'grand-permission-modal'}
                width={900}
                visible={true}
                title={<span>Vai trò <span style={{color: '#339DFF'}}>{role.name}</span> - Phân quyền</span>}
                onCancel={() => this.props.onVisibleChange(false)}
                footer={false}
                closeIcon={<i className="fa-solid fa-xmark"/>}
                centered
            >
                <Spin spinning={loading}>
                    <Checkbox className={'select-all-area'} onChange={this.handleCheckAllChange} checked={checkAll}>
                        Chọn tất cả
                    </Checkbox>
                    {this.state.groupRender}
                </Spin>
            </Modal>
        )
    }
}

export default GrantPermission;
