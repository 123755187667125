import React from 'react';
import {uniqueId} from 'lodash';
import BwipJs from "bwip-js";

interface Props {
    value: string,
    width?: number,
    height?: number
}

interface State {
    canvasId: string
}

class Barcode extends React.Component<Props, any> {

    static defaultProps: Props = {
        value: "",
        width: 150,
        height: 30
    }

    state: State = {
        canvasId: uniqueId("barcode_")
    }

    componentDidMount() {
        this.generateBarcode();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.value !== this.props.value) {
            this.generateBarcode();
        }
    }

    generateBarcode = () => {
        const {value, width, height} = this.props;
        const {canvasId} = this.state;
        if (value) {
            try {
                // The return value is the canvas element
                BwipJs.toCanvas(canvasId, {
                    bcid: 'code128',       // Barcode type
                    text: value,    // Text to encode
                    scale: 1,               // 3x scaling factor
                    height: height,              // Bar height, in millimeters
                    width: width
                })
            } catch (e) {

            }
        }
    }

    render() {
        const {canvasId} = this.state;
        return (
            <canvas id={canvasId} className={'barcode'}/>
        );
    }
}

export default Barcode;
