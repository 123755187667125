import { Modal as ModalAntd, ModalProps, ModalFuncProps } from "antd"
import React, { FC } from "react"

export interface ModalCommonProps extends ModalProps {
    children?: React.ReactNode
}

const Modal: FC<ModalCommonProps> = ({ children, okButtonProps, cancelButtonProps, ...props }: ModalCommonProps) => {
    return (
        <ModalAntd
            okButtonProps={{ icon: <i className="fa-solid fa-check pd-r-8" />, ...okButtonProps }}
            cancelButtonProps={{
                icon: <i className="fa-solid fa-xmark pd-r-8" />,
                type: "ghost",
                ...cancelButtonProps,
            }}
            {...props}
        >
            {children}
        </ModalAntd>
    )
}

Modal.defaultProps = {
    closeIcon: <i className="fa-solid fa-xmark" />,
    okText: "Xác Nhận",
    cancelText: "Hủy Bỏ",
    width: 660,
    centered: true,
}

export default Modal

export class ModalCommon {
    static confirm({
        okText = "Xác nhận",
        cancelText = "Hủy bỏ",
        okButtonProps,
        cancelButtonProps,
        ...props
    }: ModalFuncProps) {
        ModalAntd.confirm({
            okText,
            cancelText,
            closeIcon: null,
            okButtonProps: {
                tabIndex: 2,
                icon: <i className="fa-solid fa-check mg-r-8" />,
                ...okButtonProps,
            },
            cancelButtonProps: {
                tabIndex: 3,
                icon: <i className="fa-solid mg-r-8 fa-xmark" />,
                type: "ghost",
                ...cancelButtonProps,
            },
            centered: true,
            ...props,
        })
    }
}
