import numeral from 'numeral'
import queryString from 'query-string'
import lodash, { omitBy } from 'lodash'
import { Component } from 'react'
import { AppContextValueInterface } from '../Context/AppContext'
import { numberToWord } from './NumberToWord'
import moment from 'moment'
import { ChangedValuesInterface } from '../Interface/LogInterface'

const isEmptyObject = (object: object | null | undefined) => {
    return lodash.isObject(object) && !Object.keys(lodash.omitBy(object, (value) => lodash.isNil(value))).length
}

class Utils {
    static currencyFormat = (amount: any, format = '0,0') => {
        return numeral(amount).format(format)
    }

    static currencyFormatByCurrency = (amount: any, currency: any) => {
        let format = currency === 'VND' ? '0,0' : '0,0[.]00'
        return Utils.currencyFormat(amount, format)
    }

    static getUrlQueryByName = (name: any, location: any) => {
        return lodash.get(Utils.getQueryFromLocation(location), name)
    }

    static getQueryFromLocation = (location: any) => {
        return queryString.parse(location.search, { arrayFormat: 'bracket' })
    }

    static pushQueryToHistory = (history: any, location: any, filter = {}) => {
        history.push(location.pathname + '?' + queryString.stringify(filter, { arrayFormat: 'bracket' }))
    }

    static pushCleanQueryToHistory = (history: any, location: any, filter = {}) => {
        filter = omitBy(filter, (value) => value === '' || value === null || value === undefined)
        Utils.pushQueryToHistory(history, location, filter)
    }

    static getFilter = (filter: Partial<any>) => {
        filter = lodash.omitBy(filter, (value) => value === '' || isEmptyObject(value))
        return filter
    }

    static checkDisableSubmit = ({...formState}: any, except: string[] = []) => {
        let form: any = {...formState}
        let search = window.location.search
        let params = new URLSearchParams(search)
        params.forEach((value, key) => {
            form[key] = value
        })
        except.forEach((key) => {
            delete form[key]
        })
        delete form.offset
        delete form.page
        delete form.limit
        return Object.values(form).every((x) => !x)
    }

    static parseLogReceiverPayer = (userIndex: number, userRefIndex: number, parseChangedValues: ChangedValuesInterface[]) => {
        const user = parseChangedValues[userIndex] || {}
        try {
            if (user.left?.startsWith('c:')) {
                const split: string[] = user.left.split(':')
                parseChangedValues[userIndex].left = `${split[2]} (${split[1]})`
            }

            if (user.right?.startsWith('c:')) {
                const split: string[] = user.right.split(':')
                parseChangedValues[userIndex].right = `${split[2]} (${split[1]})`
            }

            if (userIndex !== -1 && userRefIndex !== -1) {
                parseChangedValues.splice(userRefIndex, 1)
            }
        } catch (e) {
            console.log(e)
        }

        return parseChangedValues
    }

    static parseDateTime = (time: string | number, format = 'DD/MM/YYYY HH:mm:ss') => {
        return time ? moment(time).format(format) : ''
    }

    static buildUrl = (location: any, path: string, query: any = {}) => {
        const qs = Object.keys(query).length ? `?${queryString.stringify(query)}` : ''
        return `${location.protocol}//${location.host}${path}${qs}`
    }

    static getUsernameByRef = (ref: string) => {
        const [, , username] = ref.split(':')
        return username
    }

    static moneyForHuman = (money: any) => {
        let arr = money.toString().split('.')
        let _int = arr[0] ? parseInt(arr[0]) : undefined
        let _decimal = arr[1] ? parseInt(arr[1]) : undefined

        if (_decimal !== undefined) {
            return numberToWord(_int) + ' lẻ ' + numberToWord(_decimal)
        }

        return numberToWord(_int)
    }

    /**
     * Get app context from child component
     * @param component
     * @return AppContextValueInterface
     */
    static getAppContext = (component: Component): AppContextValueInterface => {
        return component.context
    }

    static getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }

    static getCustomerProjectUrl = (projects: any[], projectCode: string, customerUsername: string) => {
        let customerProject = projects.find((p) => p.code === projectCode)
        let customerUrl: any
        if (customerProject) {
            const compile = lodash.template(customerProject.baseUrl)
            customerUrl = compile({ username: `${customerUsername}` })
        }
        return customerUrl
    }

    static normalize = (str: string | undefined | null) => {
        return String(str || '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd')
            .replace(/Đ/g, 'D')
            .trim()
            .toLowerCase()
    }
}

export default Utils
