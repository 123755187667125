import React from "react";
import {CopyOutlined} from "@ant-design/icons";
import {message} from 'antd';

export interface ClipboardProps{
    value?:string|number,
    className?:string,
    color?:string,
    fontSize?:number,
    successMessage?:string    
}
const Clipboard = (props:ClipboardProps) =>{
    const {value, className, color, fontSize, successMessage} = props;
    return(
        <CopyOutlined
            style={{fontSize: fontSize, color: color}}
            className={`mgl5 mgr5 _btCopy ${className}`}
            onClick={()=>{
                const textField = document.createElement('textarea');
                textField.innerText = value?value.toString():'';
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                message.success(successMessage ? successMessage : 'Copied');
            }}
        />
    );
}
Clipboard.defaultProps={
    color:'gray',
    fontSize:16
}
export default Clipboard;
