import React from "react";
import AccountDomain, {AccountModel} from "../Domain/AccountDomain";
import {Tenant, TenantModel} from "../Domain/TenantDomain";

export interface AppContextValueInterface {
    state: AppContextValueStateInterface,
    func: AppContextValueFuncInterface
}

interface AppContextValueFuncInterface {
    updateAccount: (account: AccountDomain) => void,
    fetchAccountAndUpdateState: (accountCode: string) => void,
    fetchCurrentAccountTimeout: () => void
    updateAccountPermission:(accountCode:string) => void,
    setLoading: (isLoading: boolean) => void
}

interface AppContextValueStateInterface {
    loading: boolean,
    account: AccountDomain,
    tenant: Tenant
    accountPermissions: {owner:boolean,permissions:string[]}
}

export const defaultAppContextValue: AppContextValueInterface = {
    state: {
        loading: false,
        account: {...AccountModel},
        tenant: {...TenantModel},
        accountPermissions:{
            owner: true,
            permissions: ["string"]
        }
    },
    func: {
        updateAccount: () => {},
        fetchAccountAndUpdateState: () => {},
        fetchCurrentAccountTimeout: () => {},
        updateAccountPermission: () => {},
        setLoading: () => {}
    }
}

const AppContext = React.createContext({...defaultAppContextValue});

export {AppContext};
