import {ExclamationCircleOutlined} from "@ant-design/icons"
import {
    AutoComplete,
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input, InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Spin
} from "antd"
import {AxiosError} from "axios"
import lodash, {get} from "lodash"
import moment from "moment"
import React from "react"
import CustomerApi from "../../../Api/CustomerApi"
import PaymentVoucherApi from "../../../Api/PaymentVoucherApi"
import ProjectApi from "../../../Api/ProjectApi"
import PaymentVoucherUpdateCommand from "../../../Command/PaymentVoucherUpdateCommand"
import DefaultLayout from "../../../Component/Layout/Default"
import CustomerDomain from "../../../Domain/CustomerDomain"
import PaymentVoucherDomain, {PaymentVoucherModel} from "../../../Domain/PaymentVoucherDomain"
import ProjectDomain from "../../../Domain/ProjectDomain"
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface"
import SuggestCustomerQueryCriteria from "../../../QueryCriteria/SuggestCustomerQueryCriteria"
import ActionLog from "./ActionLog"
import PaymentVoucherReadOnly from "./Readonly"
import VoucherBarcode from "./VoucherBarcode"
import VoucherQrCode from "./VoucherQrCode"
import SelectPaymentReason from "../../../Component/SelectPaymentReason"
import {invoiceConfig} from "../../../Config/Invoice"
import InvoiceAttachment from "./InvoiceAttachment"
import {ACCOUNT_PERMISSIONS, PAYMENT_VOUCHER_WARNING, REVIEW_STATUSES_CODE} from "../../../Util/Constants"
import {VoucherWarning} from "./VoucherWarning"
import {AppContext} from "../../../Context/AppContext"
import Utils from "../../../Util/Utils"
import AccountDomain from "../../../Domain/AccountDomain"
import {LogModal} from "../../../Component/LogModal"
import {ListItem} from "./LogListItem"
import {NotificationCommon} from "../../../Component/Notification"
import PaymentReasonApi from "../../../Api/PaymentReasonApi"
import {PaymentReasonDomain} from "../../../Domain/PaymentReasonDomain"
import {LogResrouceType} from "../../../QueryCriteria/LogQueryCriterial"
import {LogModalRef} from "../../../Component/LogModal/type";
import SecurityService from "../../../Util/SecurityService";
import PaymentVoucherCreateCommand from "../../../Command/PaymentVoucherCreateCommand"
import ModalPaymentVoucherCreate from "../../../Component/ModalPaymentVoucherCreate"

interface Props extends DefaultPropsInterface {
    history: any
}

interface State {
    account: AccountDomain
    loading: boolean
    paymentVoucher: PaymentVoucherDomain
    update: boolean
    form: PaymentVoucherUpdateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    receiverType: string
    project: any
    customerLoading: boolean
    isChange: boolean
    paymentReason?: PaymentReasonDomain[]
    isShowModalCreate?: boolean
}

class PaymentVoucherDetail extends React.Component<Props, State> {
    static contextType = AppContext
    formRef = React.createRef<FormInstance>()
    logRef = React.createRef<LogModalRef>()

    state: State = {
        account: Utils.getAppContext(this).state.account,
        loading: false,
        paymentVoucher: {...PaymentVoucherModel},
        update: false,
        form: {},
        projects: [],
        customers: [],
        receiverType: "system",
        project: undefined,
        customerLoading: false,
        isChange: false,
        paymentReason: undefined,
        isShowModalCreate: false
    }

    componentDidMount() {
        this.fetchPaymentVoucher()
        this.fetchProjects({})

        if (this.props.location.search.includes('status=PENDING')) {
            NotificationCommon.warning({message: 'Xác nhận giao dịch không thành công!'})
        }

        this.props.history.replace({
            search: ''
        })
    }

    fetchProjects = (filter = {}) => {
        this.setState({loading: true})
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .catch((error) => {
                NotificationCommon.error({
                    message: "Không tải được danh sách dự án. Lỗi: " + error.message,
                })
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    getCode = () => {
        return this.props.match.params.code
    }

    fetchPaymentReasons = (query = {}) => {
        this.setState({loading: true});
        query = {
            offset: 0,
            limit: 100,
            ...query,
        }
        PaymentReasonApi.autocomplete(query)
            .then(response => {
                this.setState({paymentReason: response.data});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    fetchPaymentVoucher = () => {
        this.setState({loading: true})
        PaymentVoucherApi.getByCode(this.getCode())
            .then((response) => {
                const data = response.data
                const project = data.receiverRef ? get(data, "receiverRef.project") : data.project !== '' || null ? get(data, 'project') : undefined;
                const username = get(data, "receiverRef.username")
                this.setState({
                    paymentVoucher: data,
                    form: {
                        ...data,
                        receiverRef: get(data.receiverRef, "username"),
                        paymentReason: get(data.paymentReason, "code"),
                    },
                    receiverType: project ? "system" : "person",
                    project,
                    isChange: false
                })
                this.formRef.current?.setFieldsValue({amount: data.amount, memo: data.memo})
                if (project && data.receiverRef) {
                    this.fetchCustomers({project, query: username})
                }
            })
            .catch((err) => {
                if (get(err, "response.status") === 404) {
                    NotificationCommon.error({message: "Không tìm thấy phiếu chi!"})
                } else {
                    NotificationCommon.error({
                        message: "Không tải được chi tiết phiếu chi. Lỗi: " + err.message,
                    })
                }
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    onReject = () => {
        this.setState({loading: true})
        PaymentVoucherApi.reject(this.getCode())
            .then(() => {
                NotificationCommon.success({
                    message: "Hủy phiếu chi thành công!",
                })
                this.fetchPaymentVoucher()
            })
            .catch(() => {
                NotificationCommon.error({message: "Hủy phiếu chi thất bại!"})
                this.setState({loading: false})
            })
    }

    // Hành động xác nhận giao dịch
    onApprove = () => {
        this.setState({loading: true})
        PaymentVoucherApi.approve(this.getCode())
            .then(() => {
                NotificationCommon.success({
                    message: "Xác nhận giao dịch thành công!",
                })
                this.fetchPaymentVoucher()
            })
            .catch((error: AxiosError) => {
                let message: any = "Xác nhận giao dịch thất bại!"
                if (lodash.get(error.response, "status") === 400) {
                    if (lodash.get(error.response, "data.title") === "ref_exist") {
                        const transactionCode = lodash.get(error.response, "data.transactionCode")
                        message = (
                            <span>Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b></span>
                        )
                        NotificationCommon.error({message})
                    } else if (lodash.get(error.response, "data.title") === "source_exist") {
                        NotificationCommon.error({
                            message: "Không thể xác nhận vì đã tồn tại giao dịch",
                        })
                    }  else if (lodash.get(error, "response.data.title") === "project_inactive") {
                        NotificationCommon.error({
                            message: "Hệ thống ngừng hoạt động. Vui lòng kiểm tra lại.",
                        })
                    } else if (lodash.get(error, "response.data.title") === "invalid_customer") {
                        NotificationCommon.error({
                            message: "Sai định dạng username",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
                        let details = lodash.get(error,'response.data.detail').split(/'/);
                        NotificationCommon.error({
                            message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
                        })
                    } else if (lodash.get(error, "response.data.title") === "payment_ineligible") {
                        NotificationCommon.error({
                            message: "Giao dịch đã được xác nhận. Vui lòng kiểm tra lại",
                        })
                    } else {
                        NotificationCommon.error({
                            message: message,
                        })
                    }
                } else if (lodash.get(error.response, "status") === 403) {
                    NotificationCommon.error({
                        message: "Bạn không có quyền thực hiện thao tác này",
                    })
                } else {
                    NotificationCommon.error({message})
                }
                this.setState({loading: false})
            })
    }

    // Hành động duyệt phiếu
    onAcceptReview = () => {
        this.setState({loading: true})
        PaymentVoucherApi.review(this.getCode()).then(() => {
            NotificationCommon.success({
                message: "Duyệt phiếu thành công!",
            })
            this.fetchPaymentVoucher()
        }).catch((err) => {
            let message: string;
            let error = err.response.data
            if (error.title === 'payment_voucher_not_found') {
                message = 'Phiếu chi không tồn tại!'
            } else if (error.title === "payment_voucher_reviewed") {
                message = "Giao dịch đã được duyệt. Vui lòng kiểm tra lại"
            } else if (error.title === "project_inactive") {
                message = "Hệ thống ngừng hoạt động. Vui lòng kiểm tra lại."
            } else if (error.status === 403) {
                message = 'Bạn không có quyền thực hiện thao tác này'
            } else if(err.response.data.title === 'review_payment_voucher_ineligible' || err.response.data.title === 'transaction_rejected'){
                message = 'Giao dịch đã bị hủy. Vui lòng kiểm tra lại'
            } else {
                message = 'Duyệt phiếu thất bại. Vui lòng kiểm tra lại'
            }
            NotificationCommon.error({
                message
            })
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    confirmReject = () => {
        Modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined/>,
            content: "Bạn chắc chắn muốn hủy phiếu?",
            okText: "Đồng ý",
            cancelText: "Hủy bỏ",
            onOk: this.onReject,
            okButtonProps: {
                icon: <i className="fa-solid fa-check pd-r-8"/>,
            },
            cancelButtonProps: {
                icon: <i className="fa-solid fa-xmark pd-r-8"/>,
                type: "ghost",
            },
        })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
            isChange: true,
        })
    }

    handleChangeNumber = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
            isChange: true,
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: field === 'receiverRef' ? value?.trim() : value,
            },
            isChange: true,
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
            isChange: true,
        })
    }

    handleSubmit = () => {
        const {form} = this.state
        if (SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE))
            this.handleUpdatePaymentVoucher(form)
    }

    handleUpdatePaymentVoucher = (command: PaymentVoucherUpdateCommand) => {
        const form = {...command}
        const {receiverType, paymentVoucher, project} = this.state
        const code = get(paymentVoucher, "code")
        if (receiverType === "person") {
            lodash.unset(form, "receiverRef");
            lodash.unset(form, 'project');
        } else {
            lodash.unset(form, "receiver")
            if (project && form.receiverRef) {
                form.receiverRef = `c:${project}:${form.receiverRef}`
            } else if (project) {
                form.project = project
            }
        }

        this.setState({loading: true})
        PaymentVoucherApi.update(code, form)
            .then(() => {
                NotificationCommon.success({
                    message: "Cập nhật phiếu thành công",
                })
                this.fetchPaymentVoucher()
            })
            .catch((error: AxiosError) => {
                let message: any = "Có lỗi. Vui lòng kiểm tra lại thông tin trên form"
                if (lodash.get(error.response, "status") === 400) {
                    if (lodash.get(error.response, "data.title") === "ref_exist") {
                        const transactionCode = lodash.get(error.response, "data.transactionCode")
                        message = (
                            <span>
                                Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b>
                            </span>
                        )
                        NotificationCommon.error({message})
                    } else if (lodash.get(error.response, "data.title") === "payment_ineligible") {
                        message = "Không thể cập nhật thông tin phiếu trước thời gian chốt sổ gần nhất"
                        NotificationCommon.error({message})
                    } else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
                        this.confirmForceUpdateVoucher(form, error)
                    } else {
                        NotificationCommon.error({message})
                    }
                } else if(lodash.get(error.response, "status") === 500) {
                    NotificationCommon.error({message: 'Đã xảy ra lỗi. Vui lòng thử lại.'})
                } else {
                    NotificationCommon.error({message})
                }
                this.setState({loading: false})
            })
    }

    confirmForceUpdateVoucher = (form: PaymentVoucherUpdateCommand, error: AxiosError) => {
        const voucherCodes = lodash.get(error.response, "data.voucherCodes")
        const renderVoucherCodes = voucherCodes.map((_code: any, _index: any) => (
            <>
                <b className={_index < lodash.size(voucherCodes) - 1 ? "has-comma" : ""}>{_code}</b>
            </>
        ))
        const message = (
            <span>Mã bút toán đã nằm trong phiếu chi {renderVoucherCodes}. Bạn có muốn tiếp tục cập nhật không?</span>
        )
        Modal.confirm({
            title: message,
            okText: "Xác nhận",
            cancelText: "Hủy bỏ",
            onOk: () => {
                this.handleUpdatePaymentVoucher({
                    ...form,
                    force: true,
                })
            },
            centered: true,
            okButtonProps: {
                icon: <i className="fa-solid fa-check pd-r-8"/>,
            },
            cancelButtonProps: {
                icon: <i className="fa-solid fa-xmark pd-r-8"/>,
                type: "ghost",
            },
        })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({customerLoading: true})
        CustomerApi.getSuggestCustomers({limit: "300", ...filter})
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({customerLoading: false})
            })
    }

    handleChangeProject = (value: any) => {
        this.setState({
            project: value || undefined,
            form: {...this.state.form, project: value, receiverRef: ''},
            isChange: true,
            customers: []
        })
    }

    handleSearchStaff = (value: any) => {
        if (this.state.project) {
            if (value && value.toString().length >= 3) {
                this.fetchCustomers({
                    query: value,
                    project: this.state.project,
                })
            } else if (!value) {
                this.fetchCustomers({
                    project: this.state.project,
                    query: "---",
                })
            }
        }
    }

    handleChangeReceiverType = (e: any) => {
        this.setState({
            receiverType: e.target.value,
            isChange: true,
            form: {...this.state.form, receiver: '', receiverRef: '', project: undefined},
            project: undefined
        })
    }

    handlePrint = () => {
        window.open(`/payment-vouchers/${this.getCode()}/print`, "_blank")
    }

    renderAction = () => {
        const paymentVoucher: PaymentVoucherDomain = this.state.paymentVoucher
        const {loading, form, isChange} = this.state
        const disabled: boolean = get(paymentVoucher, "reviewStatus") !== "PENDING" || loading
        const disableSubmit: boolean = form.amount === undefined || form.amount === "" || !form.memo

        return (
            <Row gutter={12}>
                {!loading && get(paymentVoucher, "account.type") !== "BANK" && (
                    <Col>
                        <Button type={"link"} onClick={this.handlePrint}>
                            In phiếu
                        </Button>
                    </Col>
                )}
                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_CREATE) && <Col>
                    <Button
                        type="link"
                        className="pd-r-6"
                        onClick={() => this.setIsShowModalCreate(true)}
                        >
                        <i className="fa-regular fa-clone mg-r-4" /> Nhân bản phiếu
                    </Button>
                </Col>}
                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE) && <Col>
                    <Button
                        disabled={!isChange || disableSubmit}
                        onClick={this.handleSubmit}
                        icon={<i className="fa-solid fa-pen-to-square mg-r-8"/>}
                    >
                        Cập nhật
                    </Button>
                </Col>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_APPROVE) && <Col>
                    <Button
                        onClick={this.onApprove}
                        disabled={get(paymentVoucher, 'status.code') !== 'PENDING'}
                        icon={<i className="fa-solid fa-check mg-r-8"/>}
                    >
                        Xác nhận giao dịch
                    </Button>
                </Col>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_REVIEW) && <Col>
                    <Button
                        onClick={this.onAcceptReview}
                        disabled={disabled || get(paymentVoucher,'status.code') === 'REJECTED' || get(paymentVoucher, 'status.code') === 'PENDING'}
                        icon={<i className="fa-regular fa-file-check mg-r-8"/>}
                    >
                        Duyệt phiếu
                    </Button>
                </Col>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_REJECT) && <Col>
                    <Button
                        danger
                        onClick={this.confirmReject}
                        disabled={get(paymentVoucher, 'status.code') === 'APPROVED' || get(paymentVoucher,'status.code') === 'REJECTED'}
                        icon={<i className="fa-solid fa-xmark mg-r-8"/>}
                    >
                        Hủy phiếu
                    </Button>
                </Col>}

            </Row>
        )
    }

    formatInvoiceLink = () => {
        const {paymentVoucher} = this.state
        const invoiceTemplate = lodash.get(paymentVoucher, "paymentReason.invoiceTemplate")
        if (paymentVoucher.invoice && invoiceTemplate) {
            return invoiceTemplate.replace(invoiceConfig.invoicePattern, paymentVoucher.invoice)
        }
    }

    openLogModal = () => {
        const {account, paymentVoucher, paymentReason} = this.state
        if (!paymentReason) {
            this.fetchPaymentReasons()
        }

        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.PAYMENT_VOUCHER,
            resourceCode: paymentVoucher.code,
        })
    }

    confirmForceCreateVoucher = (form: PaymentVoucherCreateCommand, error: AxiosError) => {
		const voucherCodes = lodash.get(error.response, "data.voucherCodes")
		Modal.confirm({
			title: `Mã bút toán đã nằm trong phiếu chi ${voucherCodes.join(",")}. Bạn có muốn tiếp tục tạo phiếu không?`,
			okText: "Xác nhận",
			cancelText: "Bỏ qua",
			onOk: () => {
				this.handleCreatePaymentVoucher({
					...form,
					force: true,
				})
			},
		})
	}

    handleCreatePaymentVoucher = (form: PaymentVoucherCreateCommand) => {
		this.setState({ loading: true })
		PaymentVoucherApi.create(form)
			.then(() => {
				NotificationCommon.success({
					message: "Tạo phiếu chi thành công",
				})
				this.setState({ isShowModalCreate: false })
			})
			.catch((error: AxiosError) => {
				if (lodash.get(error, "response.status") === 400) {
					if (lodash.get(error, "response.data.title") === "payment_ineligible") {
						NotificationCommon.error({
							message: "Thời gian giao dịch không hợp lệ do đã chốt sổ",
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist") {
						NotificationCommon.error({
							message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(
								error.response,
								"data.transactionCode"
							)}`,
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
						this.confirmForceCreateVoucher(form, error)
					} else if (lodash.get(error, "response.data.title") === "file_not_image_file") {
						NotificationCommon.error({
							message: "File không phải là file ảnh",
						})
					} else if (lodash.get(error, "response.data.title") === "invalid_project") {
						NotificationCommon.error({
							message: "Hệ thống nhận tiền không hợp lệ",
						})
					}
                    else if (lodash.get(error, "response.data.title") === "invalid_customer") {
                        NotificationCommon.error({
                            message: "Sai định dạng username",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
                        let details = lodash.get(error,'response.data.detail').split(/'/);
                        NotificationCommon.error({
                            message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
                        })
                    }
                    else {
						NotificationCommon.error({
							message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form",
						})
					}
				} else {
					NotificationCommon.error({
						message: "Lỗi: " + error.message,
					})
				}
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	}

    setIsShowModalCreate = (visible: boolean) => {
        this.setState({
            isShowModalCreate: visible
        })
    }

    render() {
        const paymentVoucher = this.state.paymentVoucher
        const {form, projects, customers, receiverType, project, loading, account, paymentReason, isShowModalCreate} = this.state
        const isShowDuplicateReasonWarning =
            paymentVoucher.invoice &&
            paymentVoucher.warning &&
            paymentVoucher.warning === PAYMENT_VOUCHER_WARNING.DUPLICATE_PAYMENT_REASON
        const accountProjects = account.projects || []

        // @ts-ignore
        return (
            <DefaultLayout
                {...this.props}
                title={"Chi tiết phiếu chi"}
                breadcrumb={[
                    {title: "Danh sách phiếu chi", href: "/payment-vouchers"},
                    {title: `Thông tin phiếu chi ${get(paymentVoucher, "code")}`},
                ]}
            >
                <Spin spinning={loading}>
                    <div className="main-content payment-details mx-12 my-12">
                        {isShowDuplicateReasonWarning && <VoucherWarning voucher={paymentVoucher}/>}
                        <Row>
                            <Col xs={24} xl={18}>
                                <Card
                                    title={
                                        <span className="capitalize">
                                            <Button
                                                type="text"
                                                onClick={() => this.props.history.goBack()}
                                                className="pd-l-0 pd-r-4"
                                            >
                                                <i className="fa-solid fa-angle-left"/>
                                            </Button>
                                            Thông tin phiếu chi
                                            {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_LOG_VIEW) && <>
                                                <Button type="link" className="text-blue pd-l-7 underline mg-l-4" onClick={this.openLogModal.bind(this)}>
                                                    <i className="fa-regular fa-file-lines dpl-il mg-r-4"/>Log
                                                </Button>
                                            </>}
                                        </span>
                                    }
                                    className={`mg-bt-12 ${window.innerWidth >= 1025 && 'mg-r-12'}`}
                                    extra={!loading && this.renderAction()}
                                >
                                    {get(paymentVoucher, "status.code") !== 'PENDING' ? (
                                        <PaymentVoucherReadOnly
                                            paymentVoucher={paymentVoucher}
                                            formatInvoiceLink={this.formatInvoiceLink}
                                        />
                                    ) : (
                                        <Form labelCol={{xs: 6, sm: 8, lg: 6}} labelAlign={"left"} ref={this.formRef}
                                              className={'payment-infos horizontal-form'}>
                                            <Form.Item label={"Tài khoản quỹ"}>
                                                <span>
                                                    {get(paymentVoucher, "account.name")} (
                                                    {get(paymentVoucher, "account.code")})
                                                </span>
                                            </Form.Item>

                                            <Form.Item label={"Thời gian tạo"}>
                                                <span>
                                                    {get(paymentVoucher, "preparedAt")
                                                        ? moment(get(paymentVoucher, "preparedAt")).format(
                                                            "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                        : "--"}
                                                </span>
                                            </Form.Item>

                                            <Form.Item label={"Người tạo"}>
                                                <span>
                                                    {get(paymentVoucher, "preparedBy.username")
                                                        ? get(paymentVoucher, "preparedBy.username")
                                                        : "--"}
                                                </span>
                                            </Form.Item>

                                            {get(paymentVoucher,'reviewStatus') === 'REVIEWED' && <>
                                                <Form.Item label={"Thời gian duyệt"}>
                                                <span>
                                                    {get(paymentVoucher, "reviewedAt")
                                                        ? moment(get(paymentVoucher, "reviewedAt")).format(
                                                            "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                        : "--"}
                                                </span>
                                                </Form.Item>

                                                <Form.Item label={"Người duyệt"}>
                                                    <span>
                                                        {get(paymentVoucher, "reviewedBy.username")
                                                            ? get(paymentVoucher, "reviewedBy.username")
                                                            : "--"}
                                                    </span>
                                                </Form.Item>
                                            </>}

                                            <Form.Item label={"Trạng thái giao dịch"}>
                                                <span>
                                                    {paymentVoucher && paymentVoucher.reviewStatus ?
                                                        REVIEW_STATUSES_CODE.find((x) => paymentVoucher.reviewStatus === x.code)?.name : "--"}
                                                </span>
                                            </Form.Item>

                                            <Form.Item label={"Mã giao dịch"}>
                                                <span>{get(paymentVoucher, "transaction") || "--"}</span>
                                            </Form.Item>

                                            <Form.Item label={"Người nhận tiền"}>
                                                <Row className={"mg-bt-10"}>
                                                    <Radio
                                                        disabled={!accountProjects.length || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                        value={"system"}
                                                        checked={receiverType === "system"}
                                                        onChange={this.handleChangeReceiverType}
                                                        className="mg-r-8 pd-t-5"
                                                    />
                                                    <div className="flex1">
                                                        <Row gutter={12}>
                                                            <Col span={12}>
                                                                <Select
                                                                    disabled={
                                                                        !accountProjects.length ||
                                                                        receiverType !== "system"
                                                                    }
                                                                    className={"width-100pc"}
                                                                    placeholder={"Hệ thống"}
                                                                    value={loading ? undefined : project}
                                                                    allowClear
                                                                    onChange={this.handleChangeProject}
                                                                >
                                                                    {projects
                                                                        .filter((item) =>
                                                                            accountProjects.includes(item.code)
                                                                        )
                                                                        .map((item: ProjectDomain) => (
                                                                            <Select.Option
                                                                                key={item.code}
                                                                                value={item.code}
                                                                            >
                                                                                {item.name}
                                                                            </Select.Option>
                                                                        ))}
                                                                </Select>
                                                            </Col>
                                                            <Col span={12}>
                                                                <AutoComplete
                                                                    disabled={
                                                                        !accountProjects.length ||
                                                                        receiverType !== "system"
                                                                    }
                                                                    className={"width-100pc"}
                                                                    placeholder={"Username"}
                                                                    value={form.receiverRef}
                                                                    showSearch={true}
                                                                    filterOption={false}
                                                                    allowClear
                                                                    onChange={this.handleChangeSelect.bind(
                                                                        this,
                                                                        "receiverRef"
                                                                    )}
                                                                    onSearch={lodash.debounce(
                                                                        this.handleSearchStaff,
                                                                        500
                                                                    )}
                                                                >
                                                                    {customers.map((item: CustomerDomain) => (
                                                                        <AutoComplete.Option
                                                                            key={item.username}
                                                                            value={item.username}
                                                                        >
                                                                            {item.fullname} ({item.username})
                                                                        </AutoComplete.Option>
                                                                    ))}
                                                                </AutoComplete>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Radio
                                                        value={"person"}
                                                        checked={receiverType === "person"}
                                                        onChange={this.handleChangeReceiverType}
                                                        className="mg-r-8 pd-t-5"
                                                        disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                    />
                                                    <div className="flex1">
                                                        <Input
                                                            disabled={receiverType !== "person" || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                            placeholder={"Người nhận tiền"}
                                                            value={project ? undefined : form.receiver}
                                                            onChange={this.handleChangeInput.bind(this, "receiver")}
                                                            onPressEnter={() => this.handleSubmit()}
                                                        />
                                                    </div>
                                                </Row>
                                            </Form.Item>

                                            <Form.Item
                                                label={"Số tiền"}
                                                name="amount"
                                                rules={[{required: true, message: "Số tiền không được để trống"}]}
                                            >
                                                <InputNumber
                                                    placeholder={"Vui lòng nhập số tiền"}
                                                    value={form.amount}
                                                    min={0}
                                                    formatter={(value:any) => {
                                                        if (!value) {
                                                            return ''
                                                        }
                                                        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }}
                                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                    onChange={this.handleChangeNumber.bind(this, "amount")}
                                                    className={"width-100pc"}
                                                    disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label={"Nội dung giao dịch"}
                                                name="memo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Nội dung giao dịch không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    value={form.memo}
                                                    onChange={this.handleChangeInput.bind(this, "memo")}
                                                    onPressEnter={() => this.handleSubmit()}
                                                    placeholder="Vui lòng nhập nội dung giao dịch"
                                                    disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Ghi chú"}>
                                                <Input
                                                    value={form.remark}
                                                    onChange={this.handleChangeInput.bind(this, "remark")}
                                                    placeholder="Vui lòng nhập ghi chú"
                                                    disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Thời gian giao dịch"}>
                                                <DatePicker
                                                    className={"width-100pc"}
                                                    format={"DD/MM/YYYY HH:mm:ss"}
                                                    value={form.timestamp ? moment(form.timestamp) : null}
                                                    onChange={this.handleChangeDatePicker.bind(this, "timestamp")}
                                                    disabledDate={(current) => current && current > moment()}
                                                    showTime
                                                    placeholder="Vui lòng chọn thời gian giao dịch"
                                                    disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Mã bút toán"}>
                                                <Input
                                                    disabled={get(paymentVoucher, "account.type") !== "BANK" || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                    min={0}
                                                    className={"width-100pc"}
                                                    value={form.ref}
                                                    onChange={this.handleChangeInput.bind(this, "ref")}
                                                    placeholder="Vui lòng nhập mã bút toán"
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label={"Số hoá đơn"}
                                                extra={
                                                    this.formatInvoiceLink() && (
                                                        <span>
                                                            Link hoá đơn:{" "}
                                                            <a
                                                                rel={"noreferrer"}
                                                                target={"_blank"}
                                                                href={this.formatInvoiceLink()}
                                                            >
                                                                {this.formatInvoiceLink()}
                                                            </a>
                                                        </span>
                                                    )
                                                }
                                            >
                                                <Input
                                                    className={"width-100pc"}
                                                    value={form.invoice}
                                                    onChange={this.handleChangeInput.bind(this, "invoice")}
                                                    placeholder="Vui lòng nhập số hóa đơn"
                                                    disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE)}
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Mục đích chi"}>
                                                <SelectPaymentReason
                                                    value={form.paymentReason}
                                                    onChange={this.handleChangeSelect.bind(this, "paymentReason")}
                                                    selectProps={{
                                                        placeholder: "Vui lòng chọn mục đích chi",
                                                        disabled:!SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_UPDATE),
                                                    }}
                                                />
                                            </Form.Item>
                                        </Form>
                                    )}
                                </Card>
                            </Col>

                            <Col xs={24} xl={6}>
                                <VoucherQrCode paymentVoucher={paymentVoucher}/>
                                <VoucherBarcode paymentVoucher={paymentVoucher}/>
                                <InvoiceAttachment paymentVoucher={paymentVoucher}/>
                                <ActionLog/>
                            </Col>
                        </Row>
                    </div>
                </Spin>

                <LogModal
                    ref={this.logRef}
                    title="Log phiếu chi"
                    ListItemContent={(props) => <ListItem {...props} paymentReason={paymentReason} account={account}/>}
                />

                {isShowModalCreate && (
                    <ModalPaymentVoucherCreate
                        loading={loading}
                        voucherCloneCode={paymentVoucher.code}
                        onVisibleChange={this.setIsShowModalCreate}
                        onSubmit={this.handleCreatePaymentVoucher}
                    />
                )}



            </DefaultLayout>
        )
    }
}

export default PaymentVoucherDetail
