import { Button, Col, DatePicker, Form, Input, Row, Select, Tooltip } from 'antd'
import moment from 'moment'
import { Component, RefObject, createRef } from 'react'
import SelectDevice from '../../Component/SelectDevice'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import { MESSAGE_STATUSES } from '../../Util/Constants'
import Utils from '../../Util/Utils'

const RangePicker = DatePicker.RangePicker

interface FormInterface {
    device?: string
    sim?: string
    timestampFrom?: string | null
    timestampTo?: string | null
    messageIncomingAtFrom?: string | null
    messageIncomingAtTo?: string | null
    status?: string
    sender?: string
    content?: string
    account?: string
    id?: string
    username?: string
    query?: string
}

interface Props extends DefaultPropsInterface {
    isNoAccountSMS?: boolean
    collapsed: boolean
    handleCollapseFilters: () => void
}

interface State {
    form: FormInterface
}

class FilterForm extends Component<Props, State> {
    firstFieldRef: RefObject<Input> = createRef<Input>()

    state: State = {
        form: {
            ...Utils.getQueryFromLocation(this.props.location),
        },
    }

    componentDidUpdate(prevProps: Props) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.setState({ form: Utils.getQueryFromLocation(this.props.location) })
        }

        if (this.props.collapsed !== prevProps.collapsed && prevProps.collapsed) {
            this.firstFieldRef.current?.focus()
        }
    }

    handleChangeInput = (field: string, e: any) => {
        const form: any = this.state.form
        form[field] = e.target.value
        this.setState({ form })
    }

    handleChangeSelect = (field: string, value: any) => {
        const form: any = this.state.form
        form[field] = value
        this.setState({ form })
    }

    handleChangeDateRangePicker = (field: string, values: any) => {
        const { form } = this.state
        if (field === 'timestamp') {
            form.timestampFrom = values && values[0] ? moment(values[0].format('YYYY-MM-DD 00:00:00')).toISOString() : null
            form.timestampTo = values && values[1] ? moment(values[1].format('YYYY-MM-DD 23:59:59')).toISOString() : null
        } else if (field === 'messageIncomingAt') {
            form.messageIncomingAtFrom = values && values[0] ? moment(values[0].format('YYYY-MM-DD 00:00:00')).toISOString() : null
            form.messageIncomingAtTo = values && values[1] ? moment(values[1].format('YYYY-MM-DD 23:59:59')).toISOString() : null
        }
        this.setState({ form })
    }

    handleFilter = () => {
        let newFilter = {
            ...this.state.form,
            offset: 0,
            page: 1,
        }
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, newFilter)
    }

    clearFilter = () => {
        this.setState({
            form: {},
        })
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {})
    }

    renderKeyworkSearch = () => {
        const { isNoAccountSMS } = this.props
        const { form } = this.state
        const keywordTitle = `Username/ Mã SMS${isNoAccountSMS ? '' : '/ Mã GD'}/ Nội dung`
        return (
            <Col
                xs={24}
                lg={12}
                xl={9}>
                <Form.Item
                    label={
                        <div>
                            Từ khóa{' '}
                            <Tooltip
                                title={keywordTitle}
                                placement={'top'}>
                                <i className="fa-regular fa-circle-question mg-l-3" />
                            </Tooltip>
                        </div>
                    }>
                    <Input
                        ref={this.firstFieldRef}
                        className={'form-input'}
                        allowClear
                        placeholder={`Tìm kiếm theo ${keywordTitle}`}
                        value={form.query}
                        onChange={this.handleChangeInput.bind(this, 'query')}
                        onPressEnter={() => this.handleFilter()}
                    />
                </Form.Item>
            </Col>
        )
    }

    render() {
        const form: FormInterface = this.state.form
        const disbaleSubmit: boolean = Utils.checkDisableSubmit(form)
        const { isNoAccountSMS } = this.props

        return (
            <Form
                layout={'horizontal'}
                className={'message-container__filters-form'}
                labelAlign={'left'}
                colon={true}>
                <Row gutter={36}>
                    <Col
                        xs={24}
                        lg={12}
                        xl={9}>
                        <Form.Item label={'Nhận sms lúc'}>
                            <RangePicker
                                autoFocus
                                allowClear
                                className={'width-100pc'}
                                placeholder={['Từ', 'Đến']}
                                value={[
                                    form.messageIncomingAtFrom ? moment(form.messageIncomingAtFrom) : null,
                                    form.messageIncomingAtTo ? moment(form.messageIncomingAtTo) : null,
                                ]}
                                onChange={this.handleChangeDateRangePicker.bind(this, 'messageIncomingAt')}
                                suffixIcon={<i className="fa-solid fa-calendar-range" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        lg={12}
                        xl={8}>
                        <Form.Item label={'Hệ thống nhận'}>
                            <RangePicker
                                allowClear
                                className={'width-100pc'}
                                placeholder={['Từ', 'Đến']}
                                value={[form.timestampFrom ? moment(form.timestampFrom) : null, form.timestampTo ? moment(form.timestampTo) : null]}
                                onChange={this.handleChangeDateRangePicker.bind(this, 'timestamp')}
                                suffixIcon={<i className="fa-solid fa-calendar-range" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        lg={12}
                        xl={7}>
                        <Form.Item label={'Thiết bị'}>
                            <SelectDevice
                                value={form.device}
                                onChange={this.handleChangeSelect.bind(this, 'device')}
                                selectProps={{
                                    placeholder: 'Tìm kiếm theo thiết bị',
                                }}
                            />
                        </Form.Item>
                    </Col>

                    {isNoAccountSMS && this.renderKeyworkSearch()}

                    <Col
                        xs={24}
                        lg={12}
                        xl={isNoAccountSMS ? 8 : 9}>
                        <Form.Item label={'Sim'}>
                            <Input
                                className={'form-input'}
                                allowClear
                                placeholder={'Tìm kiếm theo Sim'}
                                value={form.sim}
                                onChange={this.handleChangeInput.bind(this, 'sim')}
                                onPressEnter={() => this.handleFilter()}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        lg={12}
                        xl={isNoAccountSMS ? 7 : 8}>
                        <Form.Item label={'Sender'}>
                            <Input
                                className={'form-input'}
                                allowClear
                                placeholder={'Tìm kiếm theo Sender'}
                                value={form.sender}
                                onChange={this.handleChangeInput.bind(this, 'sender')}
                                onPressEnter={() => this.handleFilter()}
                            />
                        </Form.Item>
                    </Col>
                    {!isNoAccountSMS && (
                        <Col
                            xs={24}
                            lg={12}
                            xl={7}>
                            <Form.Item label={'Trạng thái'}>
                                <Select
                                    allowClear
                                    value={form.status}
                                    placeholder={'Tìm kiếm theo trạng thái'}
                                    onChange={this.handleChangeSelect.bind(this, 'status')}>
                                    {Object.keys(MESSAGE_STATUSES).map((status) => (
                                        <Select.Option
                                            key={status}
                                            value={status}>
                                            {status}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}

                    {!isNoAccountSMS && this.renderKeyworkSearch()}
                </Row>

                <div className="flex space-between items-center pd-r-10">
                    <span
                        onClick={this.props.handleCollapseFilters}
                        className={'font-size-12 cursor-pointer'}>
                        <i className="fa-solid fa-chevron-up mg-r-3" /> Thu gọn
                    </span>

                    <Row
                        gutter={18}
                        justify={'end'}>
                        {!disbaleSubmit && (
                            <Button
                                type={'text'}
                                onClick={this.clearFilter}
                                className="font-size-12px">
                                <i className="fa-regular fa-repeat mg-r-5" />
                                Làm mới bộ lọc
                            </Button>
                        )}
                        <Button
                            disabled={disbaleSubmit}
                            type={'primary'}
                            onClick={this.handleFilter}>
                            Tìm kiếm
                        </Button>
                    </Row>
                </div>
            </Form>
        )
    }
}

export default FilterForm
