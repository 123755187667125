import React, { Component, ReactNode } from 'react'
import { Breadcrumb, Button, Layout, Popover, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import lodash from 'lodash'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import UserDomain from '../../Domain/UserDomain'
import { Link } from 'react-router-dom'
import AccountDomain from '../../Domain/AccountDomain'
import { hiddenPaths } from './Default'

export interface BreadcrumbInterface {
    title: string | ReactNode
    href?: string
    jsxPassing?: boolean
    isShowLastBreadcrumbOnMdScreen?: boolean
}

interface Props extends DefaultPropsInterface {
    accountLoading: boolean
    collapsed: boolean
    toggle: (collapse?: boolean) => void
    user: UserDomain | null
    account: any
    accounts: Array<AccountDomain>
    breadcrumb?: BreadcrumbInterface[]
    onChangeAccount: (accountCode: string) => void
    onSearchAccount: (keyword: string) => void
}

interface State {
    loading: boolean
}

class Header extends Component<Props, State> {
    state = {
        loading: false,
    }

    renderDropDownUser = () => {
        return (
            <Link
                to={'/logout'}
                className={'pointer'}>
                {'Thoát'}
            </Link>
        )
    }

    handleChangeAccount = (accountCode: string) => {
        this.props.onChangeAccount(accountCode)
    }

    handleSearchAccount = (value: any) => {
        this.props.onSearchAccount(value)
    }

    isShowSelectAccount = () => {
        for (let path of hiddenPaths) {
            if (lodash.startsWith(this.props.path, path)) {
                return false
            }
        }
        return true
    }

    render() {
        let { user, account, accounts = [], breadcrumb = [], accountLoading } = this.props
        const { loading } = this.state

        const isShowSelectAccount: boolean = this.isShowSelectAccount()
        return (
            <Layout.Header className={'header'}>
                <div className="header-inner">
                    <div className="header-inner-inner">
                        <div className="right-header">
                            <div className="flex items-center">
                                <Button
                                    type="link"
                                    className="only-show-to-xs bar-button pd-l-0 pd-r-10"
                                    onClick={() => this.props.toggle(undefined)}>
                                    <i className="fa-solid fa-bars" />
                                </Button>

                                {breadcrumb.length > 0 && (
                                    <Breadcrumb
                                        separator={<i className="fa-solid fa-dash" />}
                                        className={`breadcumb-header flex`}>
                                        {breadcrumb?.map((b: BreadcrumbInterface, index: number) => {
                                            if (!b.jsxPassing) {
                                                let className = ''
                                                if (index === breadcrumb.length - 1) {
                                                    className += 'text-secondary-color-2'
                                                    if (isShowSelectAccount) {
                                                        className += ' only-show-from-md'
                                                    }
                                                } else {
                                                    className += 'text-dark-color only-show-from-md'
                                                }

                                                return (
                                                    <Breadcrumb.Item
                                                        className={className}
                                                        key={`breadcumb-item-${index}`}>
                                                        {b.href ? <Link to={b.href}>{b.title}</Link> : b.title}
                                                    </Breadcrumb.Item>
                                                )
                                            } else {
                                                return <Breadcrumb.Item key={`breadcumb-item-${index}`}>{b.title}</Breadcrumb.Item>
                                            }
                                        })}
                                    </Breadcrumb>
                                )}
                            </div>

                            <div className="flex-end flex1">
                                {isShowSelectAccount && (
                                    <div className="selector-fund">
                                        <Select
                                            loading={loading || accountLoading}
                                            filterOption={false}
                                            showSearch={true}
                                            value={!loading || !accountLoading ? lodash.get(account, 'code') : undefined}
                                            onChange={this.handleChangeAccount}
                                            onSearch={lodash.debounce(this.handleSearchAccount, 500)}>
                                            {accounts?.map((item: AccountDomain) => (
                                                <Select.Option
                                                    key={item.code}
                                                    value={item.code}>
                                                    {item.name} ({item.code})
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                                <div className="user pointer item mg-l-12 text-dark-color">
                                    <Popover
                                        placement="bottom"
                                        content={this.renderDropDownUser()}
                                        trigger="click">
                                        <UserOutlined /> <span className="font-regular only-show-from-md pd-l-4">{lodash.get(user, 'name')}</span>{' '}
                                        <i className="fa-solid fa-angle-down pd-l-4" />
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout.Header>
        )
    }
}

export default Header
