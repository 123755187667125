import React from 'react';
import AccountApi from "../../../Api/AccountApi";
import {AccountModel} from "../../../Domain/AccountDomain";
import DefaultLayout from "../../../Component/Layout/Default";
import {Col, Row} from "antd";
import AccountInformation from './AccountInformation';
import AccountUser from "./AccountUser";
import AccountOwner from "./AccountOwner";
import AccountBoxStatistic from "../../../Component/AccountBoxStatistic";
import {ConfigWebhook} from "./ConfigWebhook";
import {Project} from "./Project";
import {events} from "../../../Util/Event";
import {ACCOUNT_EVENT, ACCOUNT_PERMISSIONS} from "../../../Util/Constants";
import {NotificationCommon} from '../../../Component/Notification';
import Update from "../Update";
import Utils from '../../../Util/Utils';
import {AppContext} from '../../../Context/AppContext';
import Error403 from "../../../Component/403";

class AccountDetail extends React.Component<any, any> {
    static contextType = AppContext;
    state: any = {
        loading: true,
        isShowModalUpdate: false,
        account: {...AccountModel},
        accountPermissions: {},
        isFetchingPermission: true
    }

    componentWillMount() {
        this.fetchAccountPermissions();
    }

    componentDidMount() {
        this.fetchAccountDetail();
        this.eventListeners();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.state.account !== prevState.account) {
            this.fetchAccountPermissions();
        }
    }

    handleModalUpdateVisibleChange = (visible: boolean) => {
        this.setState({
            isShowModalUpdate: visible
        });
    }

    onUpdateSuccess = () => {
        this.fetchAccountDetail()
        this.handleModalUpdateVisibleChange(false);
    }

    fetchAccountDetail = () => {
        this.setState({loading: true});
        AccountApi.findByCode(this.getCode())
            .then(response => {
                this.setState({account: response.data})
            })
            .catch((err) => {
                this.setState({account:{...AccountModel}})
                if (err.response && err.response.data.status !== 403) {
                    NotificationCommon.error({
                        message: 'Không thể tải được thông tin chi tiết tài khoản'
                    })
                }
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    eventListeners = () => {
        events.on(ACCOUNT_EVENT.ADD_PROJECT, () => {
            this.fetchAccountDetail()
        })
        events.on(ACCOUNT_EVENT.REMOVE_PROJECT, () => {
            this.fetchAccountDetail()
        })
    }

    getCode = () => {
        return this.props.match.params.code;
    }

    handleChangeDebitTransaction = (enabled: boolean) => {
        this.setState({loading: true})
        AccountApi.debitTransactionConfigure(this.getCode(), {enabled})
            .then(() => {
                NotificationCommon.success({
                    message: "Cấu hình thành công"
                })
                this.fetchAccountDetail()
            })
            .catch(() => {
                NotificationCommon.error({
                    message: "Cấu hình thất bại"
                })
            })
            .finally(() => this.setState({loading: false}))
    }

    fetchAccountPermissions = () => {
        AccountApi.getAccountPermissions(this.getCode()).then((response) => {
            this.setState({accountPermissions: response.data})
        })
        .finally(() => this.setState({isFetchingPermission: false}))
    }

    handlePermissionChecking = (permission: string) => {
        let permissionObj = this.state.accountPermissions;
        if (permissionObj.owner) return true
        else return permissionObj.permissions?.includes(permission)
    }

    render() {
        const {account, loading, isShowModalUpdate, isFetchingPermission} = this.state;
        const accountLoading: boolean = Utils.getAppContext(this).state.loading

        return (
            <DefaultLayout
                {...this.props}
                title={`Chi tiết tài khoản ${account.name} - ${account.code}`}
                breadcrumb={[{
                    title: 'Danh sách tài khoản',
                    href: '/accounts'
                }, {title: `Tài khoản ${account.name} - ${account.code}`}]}
            >
                {!isFetchingPermission && <div className="main-content">
                    {!this.handlePermissionChecking(ACCOUNT_PERMISSIONS.ACCOUNT_VIEW)? <Error403/> : <>
                        <div className="white-box px-12 py-12">
                            <AccountBoxStatistic
                                account={account}
                            />
                        </div>
                        <div className="mg-t-12 mg-l-12 mg-r-12 mg-bt-12">
                            {!loading && <Row gutter={12}>
                                <Col xs={24} lg={15}>
                                    <AccountInformation
                                        loading={loading}
                                        account={account}
                                        accountPermissions={this.state.accountPermissions}
                                        onChangeDebitTransaction={this.handleChangeDebitTransaction}
                                        getAccountDetail={this.fetchAccountDetail}
                                        showModalUpdate={() => this.handleModalUpdateVisibleChange(true)}
                                    />
                                </Col>
                                <Col xs={24} lg={9}>
                                    {this.handlePermissionChecking(ACCOUNT_PERMISSIONS.PRIVILEGE_VIEW) && !accountLoading &&
                                    <div className="mg-bt-12">
                                        <AccountUser accountCode={this.getCode()} account={account}
                                                     handlePermissionChecking={this.handlePermissionChecking}/>
                                    </div>}

                                    {this.handlePermissionChecking(ACCOUNT_PERMISSIONS.ACCOUNT_OWNER_VIEW) && !accountLoading &&
                                    <div className="mg-bt-12">
                                        <AccountOwner account={account} accountCode={this.getCode()}
                                                      handlePermissionChecking={this.handlePermissionChecking}/>
                                    </div>}

                                    {account.type === 'BANK' && (
                                        <div className="mg-bt-12">
                                            <ConfigWebhook accountCode={this.getCode()}/>
                                        </div>
                                    )}
                                    <div className="mg-bt-12">
                                        <Project account={account}/>
                                    </div>
                                </Col>
                            </Row>}
                        </div>
                    </>}
                </div>}

                {isShowModalUpdate && (
                    <Update
                        code={account.code}
                        onUpdateSuccess={this.onUpdateSuccess}
                        onVisibleChange={this.handleModalUpdateVisibleChange}
                    />
                )}
            </DefaultLayout>
        );
    }
}

export default AccountDetail;
