import React from 'react';
import ReceiptVoucherApi from "../../../Api/ReceiptVoucher";
import {get, lowerCase, uniqueId} from "lodash";
import moment from 'moment';
import {Col, Row} from "antd";
import ReceiptVoucherDomain, {ReceiptVoucherModel} from '../../../Domain/ReceiptVoucherDomain';
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface";
import Utils from '../../../Util/Utils';
import Barcode from "../../../Component/Barcode";
import QrCode from "../../../Component/QrCode";
import { NotificationCommon } from '../../../Component/Notification';

interface Props extends DefaultPropsInterface {

}

interface State {
    receiptVoucher: ReceiptVoucherDomain | null,
    loading: boolean,
    qrCodeDataImg: string,
    barcodeDataImg: string
}

class ReceiptVoucherPrint extends React.Component<Props, State> {

    state = {
        receiptVoucher: {...ReceiptVoucherModel},
        loading: false,
        qrCodeDataImg: "",
        barcodeDataImg: ""
    }

    componentDidMount() {
        this.fetchReceiptVoucher();
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('print-layout');
        body.classList.add('A4');
        body.classList.add('landscape');

        setTimeout(() => {
            window.print();
        }, 600);

        window.onfocus = function () {
            window.close();
        }
    }

    getCode = () => {
        return this.props.match.params.code;
    }

    fetchReceiptVoucher = () => {
        this.setState({loading: true});
        ReceiptVoucherApi.getByCode(this.getCode())
            .then(response => {
                this.setState({
                    receiptVoucher: response.data
                });
            })
            .catch((err) => {
                if (get(err, 'response.status') === 404) {
                    NotificationCommon.error({message: "Không tìm thấy phiếu thu!"})
				}
				else {
					NotificationCommon.error({
						message: 'Lỗi: ' + err.message
					})
				}
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    render() {
        const {receiptVoucher} = this.state;
        const currency = get(receiptVoucher, 'currency.code');
        const currencyName = get(receiptVoucher, 'currency.name');
        const clones = [0, 1];
        return (
            <>
                {clones.map(clone => (
                    <div className="main-content sheet" key={uniqueId('voucher-print_')}>
                        <div className="mg-t-20 mg-bt-20 mg-r-20 mg-l-20">
                            <div className="voucher-header text-center">
                                PHIẾU {receiptVoucher.code}
                            </div>
                            <div className="voucher-body">
                                <div className={'text-center bold voucher-title'}>PHIẾU THU</div>
                                <div
                                    className={'text-center italic voucher-date'}>(Ngày {moment(receiptVoucher.timestamp).format("DD")} Tháng {moment(receiptVoucher.timestamp).format("MM")} Năm {moment(receiptVoucher.timestamp).format("YYYY")})
                                </div>
                                <div className={'text-right lien-luu'}>Liên
                                    0{clone + 1}: {clone === 0 ? 'Lưu' : 'Khách'}</div>
                                <Row gutter={20} className={'mg-t-30'}>
                                    <Col span={14}>
                                        <div className="detail-item">
                                            <div className={'detail-item--label'}>Người nộp:</div>
                                            <div className={'detail-item--value'}>{receiptVoucher.payer}</div>
                                        </div>
                                        <div className="detail-item">
                                            <div className={'detail-item--label'}>Nội dung giao dịch:</div>
                                            <div className={'detail-item--value'}>{receiptVoucher.memo}</div>
                                        </div>
                                        <div className="detail-item">
                                            <div className={'detail-item--label'}>Số tiền:</div>
                                            <div
                                                className={'detail-item--value'}>{Utils.currencyFormatByCurrency(receiptVoucher.amount, currency)} {currency}</div>
                                        </div>
                                        <div className="detail-item">
                                            <div className={'detail-item--label'}>(Viết bằng chữ):</div>
                                            <div
                                                className={'detail-item--value first-letter-uppercase'}>{Utils.moneyForHuman(receiptVoucher.amount)} {lowerCase(currencyName)}</div>
                                        </div>
                                        <div className="detail-item">
                                            <div className={'detail-item--label'}>Kèm theo chứng từ gốc:</div>
                                            <div className={'detail-item--value'}/>
                                        </div>
                                    </Col>
                                    <Col span={10}>
                                        <div className="voucher-qr-code">
                                            <QrCode value={Utils.buildUrl(window.location, `/receipt-vouchers/${this.getCode()}`)}  />
                                            <Barcode value={receiptVoucher.code} />
                                            <div className={'text-right barcode-text'}>{receiptVoucher.code}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="voucher-signed mg-t-60">
                                    <div className="voucher-signed-column">
                                        <div className="bold">Giám đốc</div>
                                        <div>(Ký, họ tên, đóng dấu)</div>
                                    </div>
                                    <div className="voucher-signed-column">
                                        <div className="bold">Kế toán trưởng</div>
                                        <div>(Ký, họ tên)</div>
                                    </div>
                                    <div className="voucher-signed-column">
                                        <div className="bold">Người nộp tiền</div>
                                        <div>(Ký, họ tên)</div>
                                    </div>
                                    <div className="voucher-signed-column">
                                        <div className="bold">Người lập phiếu</div>
                                        <div className={'mg-bt-60'}>(Ký, họ tên)</div>
                                        <div className={'bold'}>{get(receiptVoucher, 'preparedBy.name')}</div>
                                        <div>({get(receiptVoucher, 'preparedBy.username')})</div>
                                    </div>
                                    <div className="voucher-signed-column">
                                        <div className="bold">Thủ quỹ</div>
                                        <div>(Ký, họ tên)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }
}

export default ReceiptVoucherPrint;
