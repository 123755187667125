import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { AxiosError } from 'axios'

export class NotificationCommon {
    static error({ message, key }: ArgsProps) {
        notification.error({
            message: 'Rất tiếc!',
            description: message,
            icon: <i className="fa-solid fa-triangle-exclamation" />,
            closeIcon: <i className="fa-solid fa-xmark" />,
            key,
        })
    }

    static success({ message }: ArgsProps) {
        notification.success({
            message: 'Chúc mừng!',
            description: message,
            icon: <i className="fa-solid fa-circle-check" />,
            closeIcon: <i className="fa-solid fa-xmark" />,
        })
    }

    static warning({ message,...args }: ArgsProps) {
        notification.warning({
            message: 'Cảnh báo!',
            description: message,
            icon: <i className="fa-solid fa-triangle-exclamation" />,
            closeIcon: <i className="fa-solid fa-xmark" />,
            ...args
        })
    }

    static fetchError(res: AxiosError<{ title: string }>) {
        if (res && Number(res.status) < 500) {
            this.error({ message: res.response?.data.title })
        }
    }
}
