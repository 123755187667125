import {FC, memo, useMemo} from "react"
import AccountDomain from "../../../Domain/AccountDomain"
import {AccountEvent, ChangedValuesInterface, LogInterface} from "../../../Interface/LogInterface"
import lodash, {difference, get} from "lodash";
import BankDomain from "../../../Domain/BankDomain";
import ProjectDomain from "../../../Domain/ProjectDomain";
import RoleDomain from "../../../Domain/RoleDomain";
import {LogItemInterface} from "../../../Component/LogModal/type";
import UserDomain from "../../../Domain/UserDomain";
import Utils from "../../../Util/Utils";
import {LogChangedIcon} from "../../../Component/LogModal/ChangedIcon";

interface ListItemProps extends LogItemInterface<LogInterface<AccountEvent, AccountDomain>> {
    bankList: BankDomain[]
    projectList: ProjectDomain[]
    roleList: RoleDomain[]
    account: AccountDomain
}

const ListItem: FC<ListItemProps> = memo(
    ({item, changedValues, newObject, bankList, projectList = [], roleList, account}: ListItemProps) => {
        const oldValues: any = changedValues[0]?.left
        const newValues: any = changedValues[0]?.right
        const currency = lodash.get(account, 'currency.code');

        const roleNameMapping: any = useMemo(() => {
            let roleNameMappingTemp: any = {}
            roleList.forEach((a: RoleDomain) => {
                roleNameMappingTemp[a.code] = a.name
            })

            return roleNameMappingTemp
        }, [roleList])

        const projectNameMapping: any = useMemo(() => {
            let projectNameMappingTemp: any = {}
            projectList.forEach((p: ProjectDomain) => {
                projectNameMappingTemp[p.code] = p.name
            })

            return projectNameMappingTemp
        }, [projectList])

        const renderGrantedProject = () => {
            const projectAdded: string[] = difference(newValues, oldValues);

            let projectString: string = ''
            projectList.forEach((p: ProjectDomain) => {
                if (projectAdded.includes(p.code)) {
                    projectString += projectString === '' ? p.name : `, ${p.name}`
                }
            })

            return projectString
        }

        const renderRevokeProject = () => {
            return projectNameMapping[newValues]
        }

        const renderGrantRoleStaff = () => {
            if (item.activity !== AccountEvent.GRANT_ROLE_STAFF_ACCOUNT_OWNER || typeof newObject !== 'object') {
                return
            }

            let staffName: string = getStaffNameFromChanged(newObject, roleNameMapping)
            return <>
                Thêm nhân viên: <span className="font-medium">{staffName}</span>
            </>
        }

        const renderChangeRoleStaffLog = () => {
            if (item.activity !== AccountEvent.CHANGE_ROLE_STAFF_ACCOUNT_OWNER) {
                return
            }

            const oldRole: string = roleNameMapping[changedValues[1].left]
            const newRole: string = roleNameMapping[changedValues[1].right]

            return (
                <>Thay đổi vai trò của nhân viên: <span
                    className="font-medium">{oldValues}</span> từ <span
                    className="font-medium">{oldRole}</span> thành <span
                    className="font-medium">{newRole}</span></>
            )
        }

        const renderRevokeRoleStaff = () => {
            const removedObject: UserDomain[] = get(item, 'change.removedObject[0]')
            if (item.activity !== AccountEvent.REVOKE_ROLE_STAFF_ACCOUNT_OWNER || typeof removedObject !== 'object') {
                return
            }

            let staffName: string = getStaffNameFromChanged(removedObject, roleNameMapping)
            return <>
                Xóa nhân viên: <span className="font-medium">{staffName}</span>
            </>
        }

        const renderGrantAccountOwner = () => {
            if (item.activity !== AccountEvent.GRANT_ACCOUNT_OWNER || typeof newObject !== 'object') {
                return
            }

            let staffName: string = getOwnerNameFromChanged(newObject)
            return <>
                Thêm chủ sở hữu: <span className="font-medium">{staffName}</span>
            </>
        }

        const renderRevokeAccountOwner = () => {
            const removedObject: UserDomain[] = get(item, 'change.removedObject[0]')
            if (item.activity !== AccountEvent.REVOKE_ACCOUNT_OWNER || typeof removedObject !== 'object') {
                return
            }

            let staffName: string = getOwnerNameFromChanged(removedObject)
            return <>
                Xóa chủ sở hữu: <span className="font-medium">{staffName}</span>
            </>
        }

        return (<div>{
            {
                [AccountEvent.CREATE]: <>Khởi tạo tài khoản quỹ <span
                    className="font-medium">{newObject.name} - {newObject.code}</span>
                    {newObject.description ? <>
                        {" "}| Ghi chú: <span className="font-medium">{newObject.description}</span>  </> : null}</>,

                [AccountEvent.UPDATE]: <UpdatedAccount item={item} bankList={bankList}/>,

                [AccountEvent.GRANT_PROJECT]: <>Thêm hệ thống <span
                    className="font-medium">{renderGrantedProject()}</span> vào tài khoản quỹ</>,

                [AccountEvent.REVOKE_PROJECT]: <>Xóa hệ thống <span
                    className="font-medium">{renderRevokeProject()}</span> trong tài khoản quỹ</>,

                [AccountEvent.ENABLE_DEBIT_TRANSACTION]: <>Cấu hình <span
                    className="font-medium">{newValues ? 'cho phép ' : 'không '}phát sinh</span> giao dịch trừ tiền từ
                    SMS</>,

                [AccountEvent.TRANSACTION_EXPIRE_CONFIG]: <>Cấu hình thời gian kiểm tra trùng mã bút toán trong <span
                    className={"font-medium"}> {newValues} ngày</span></>,

                [AccountEvent.GRANT_ACCOUNT_OWNER]: renderGrantAccountOwner(),

                [AccountEvent.REVOKE_ACCOUNT_OWNER]: renderRevokeAccountOwner(),

                [AccountEvent.CHANGE_ACCOUNT_OWNER]: null,

                [AccountEvent.GRANT_ROLE_STAFF_ACCOUNT_OWNER]: renderGrantRoleStaff(),

                [AccountEvent.REVOKE_ROLE_STAFF_ACCOUNT_OWNER]: renderRevokeRoleStaff(),

                [AccountEvent.CHANGE_ROLE_STAFF_ACCOUNT_OWNER]: renderChangeRoleStaffLog(),

                [AccountEvent.SETTLEMENT]: <>
                    Chốt sổ tài khoản: <span className="font-medium">
                    {newObject.account}
                    </span><br/>
                    Thời gian: <span className="font-medium">
                        {Utils.parseDateTime(newObject.settledAt)}
                    </span><br/>
                    Số dư: <span
                    className={newObject.settledBalance >= 0 ? "font-medium positive-money" : "font-medium negative-money"}>
                    {Utils.currencyFormatByCurrency(newObject.settledBalance, currency)} ({currency})
                    </span><br/>
                    Nội dung chốt: <span
                    className="font-medium">{newObject.name === "The first settlement" ? "Chốt số lần đầu" : newObject.name === 'Auto settlement' ? 'Chốt sổ tự động' : newObject.name}</span>
                </>,

                [AccountEvent.CHANGE_AVAILABLE_BALANCE]: null,

                [AccountEvent.CHANGE_PENDING_AMOUNT]: null,

                [AccountEvent.CHANGE_REVIEW_PENDING_AMOUNT]: null,
            }[item.activity]
        }</div>)
    }
)

export {ListItem}

interface UpdatedAccountProps {
    item: LogInterface<AccountEvent, AccountDomain>
    bankList: BankDomain[]
}

const keyMapping: any = {
    name: 'Tên tài khoản',
    currency: "Đơn vị tiền tệ",
    bank: "Ngân hàng",
    accountNumber: "Số tài khoản",
    accountHolder: "Chủ tài khoản",
    description: "Ghi chú",
}

const UpdatedAccount: FC<UpdatedAccountProps> = ({item, bankList}: UpdatedAccountProps) => {
    const changedValues: ChangedValuesInterface[] = get(item, "change.valueChange.changedValues")
    const bankListMapping: any = useMemo(() => {
        const banks: any = {}
        bankList.forEach((bank: BankDomain) => {
            banks[bank.code] = bank.name
        })
        return banks
    }, [bankList])

    const renderValues = ({left, right, key}: ChangedValuesInterface) => {
        if (key === "bank") {
            left = bankListMapping[left]
            right = bankListMapping[right]
        }

        return <><span className="font-medium">{left || "---"}</span> <LogChangedIcon/> <span
            className="font-medium"> {right || "---"}</span></>
    }

    return (
        <>
            <span>Cập nhật tài khoản quỹ</span><br/>
            {changedValues.map((change: ChangedValuesInterface, index: number) => (
                <span key={`value_${index}`}>
                    {keyMapping[change.key] || change.key}
                    {": "}
                    {renderValues(change)}
                    {index < changedValues.length - 1 && <br/>}
                </span>
            ))}
        </>
    )
}

const getStaffNameFromChanged = (users: UserDomain[], roleNameMapping: any) => {
    let staffName: string = ''
    users.forEach((user: any, index: number) => {
        if (index > 0) {
            staffName += ', '
        }
        staffName += `${user.staff} (${roleNameMapping[user.role]})`
    })
    return staffName
}

const getOwnerNameFromChanged = (users: UserDomain[]) => {
    let staffName: string = ''
    users.forEach((user: any, index: number) => {
        if (index > 0) {
            staffName += ', '
        }
        staffName += user.owner
    })
    return staffName
}
