import { Select, SelectProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import AccountDomain from '../../../Domain/AccountDomain'
import Utils from '../../../Util/Utils'

interface IProps extends SelectProps {
    roleCode: string
    privilegeId: string
    accountSelectedByRole: {
        [roleCode in string]: AccountDomain['code'][] // accountCode[]
    }
    loading: boolean
    handleAddRole: (accountCode: string, roleCode: string) => void
    handleRemoveRole: (accountCode: string, privilegeId: string, roleCode: string) => void
    onRemoveMultipleRole: (roleCodes: string[], roleCode: string) => void
}

export const SelectAccount: FC<IProps> = ({
    roleCode,
    privilegeId,
    accountSelectedByRole,
    handleAddRole,
    handleRemoveRole,
    onRemoveMultipleRole,
    options,
    ...props
}) => {
    const [optionInternal, setOptionInternal] = useState(options)

    useEffect(() => {
        const excludeCodes: string[] = []
        const temp: any = JSON.parse(JSON.stringify(accountSelectedByRole))
        delete temp[roleCode]

        Object.values(temp).forEach((codes: any) => {
            excludeCodes.push(...codes)
        })

        setOptionInternal(options?.filter((o) => !excludeCodes.includes(o.value as string)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, accountSelectedByRole])

    const roleCodeValue = accountSelectedByRole[roleCode] ? JSON.parse(JSON.stringify(accountSelectedByRole[roleCode]))  : undefined

    useEffect(() => {
        changeCloseIcon(roleCode)
    }, [roleCode, roleCodeValue, props.loading])
    
    return (
        <div
            className="select-container width-100pc"
            id={roleCode}>
            <Select
                mode="multiple"
                className="width-100pc"
                placeholder="Chọn tài khoản quỹ để thêm vai trò"
                filterOption={(input, option) => Utils.normalize(option?.display as string).includes(Utils.normalize(input))}
                onSelect={(value: any) => {
                    handleAddRole(value, roleCode)
                }}
                onDeselect={(value: string, option: any) => {
                    handleRemoveRole(value, option.privilege?.id, roleCode)
                    console.log('option', option)
                }}
                suffixIcon={<i className="fa-solid fa-xmark "></i>}
                value={roleCodeValue}
                options={optionInternal}
                getPopupContainer={(element) => element.parentElement}
                {...props}
            />
            {roleCodeValue?.length > 0 && (
                <span
                    className="clear-icon"
                    onClick={() => onRemoveMultipleRole(roleCodeValue, roleCode)}>
                    <i className="fa-solid fa-xmark "></i>
                </span>
            )}
        </div>
    )
}

const changeCloseIcon = (roleCode: string) => {
    const icons = document.querySelectorAll('.ant-select-selection-item-remove .anticon-close')
    if (!icons) return
    icons.forEach((icon) => {
        icon.innerHTML = '<i class="fa-solid fa-xmark "></i>'
    })
}
