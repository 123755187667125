import DefaultComponent from "../../../Component/DefaultComponent";
import DefaultLayout from "../../../Component/Layout/Default";
import {Card, Col, Row} from "antd";
import React from "react";
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface";
import UserDomain from "../../../Domain/UserDomain";
import StaffApi from "../../../Api/StaffApi";
import {StaffInfo} from "./StaffInfo";
import AccountList from "./AccountList";
import {Link} from "react-router-dom";

interface Props extends DefaultPropsInterface {

}

interface State {
    loading: boolean,
    staff: null | UserDomain
}

class StaffDetail extends DefaultComponent<Props, State> {

    state: State = {
        loading: false,
        staff: null
    }

    componentDidMount() {
        this.fetchStaff(this.getUsername())
    }

    getUsername() {
        return this.props.match.params.username
    }

    fetchStaff(username: any) {
        this.setState({loading: true});
        StaffApi.getStaffByUsername(username)
            .then(response => {
                this.setState({staff: response.data})
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    render() {
        const {staff} = this.state;
        return (
            <DefaultLayout
                title={`Nhân viên ${this.getUsername()}`}
                {...this.props}
                breadcrumb={[{title:'Danh Sách Nhân Viên',href:'/staffs'},{title:`Nhân viên ${this.getUsername()}`}]}
            >
                <div className="main-content staff-detail-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={10}>
                            <div className={'white-box staff-detail-container__staff-card'}>
                                <Card title={<Link to={`/staffs`}><div><i className="fa-solid fa-chevron-left mg-r-5"/>Nhân viên</div></Link>}>
                                    {staff && (<div><StaffInfo staff={staff}/></div>)}
                                </Card>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={13} xl={13} xxl={14}>
                            <div className={'white-box staff-detail-container__account-list'}>
                                <AccountList username={this.getUsername()}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </DefaultLayout>
        );
    }
}

export default StaffDetail;
