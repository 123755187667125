import {Component, ComponentProps, ComponentState} from 'react';
import queryString from "query-string";
import lodash from 'lodash';

abstract class DefaultComponent<P = {}, S = {}> extends Component<P & ComponentProps<any>, S & ComponentState> {

    getQueryFromLocation = () => {
        return queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
    };

    getUrlQueryByName = (name: any, _default: any) => {
        return lodash.get(this.getQueryFromLocation(), name, _default);
    };

    pushQueryToHistory = (filter = {}) => {
        this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(filter, {arrayFormat: 'bracket'}));
    };

    pushCleanQueryToHistory = (filter = {}) => {
        filter = lodash.omitBy(filter, value => value === '');
        this.pushQueryToHistory(filter);
    };
}

export default DefaultComponent;
