import React from "react";
import {Pagination, PaginationProps, Select} from "antd";

type CustomPaginationProps = {
    pagination:PaginationProps | any;
    onPageChange:(page: number, pageSize?: number) => void;
    pageSizes:number[];
    filter:any
}


function CustomPagination (props:CustomPaginationProps) {
    const {
        pagination,pageSizes,filter,
        onPageChange,
    } = props;

    return(
        <div className={'ant-pagination ant-table-pagination ant-table-pagination-right'}>
            <Pagination
                {...pagination}
                onChange={onPageChange}
                showSizeChanger={false}
                hideOnSinglePage
            />
            <Select className={'mg-l-8'}
                    style={{width:'15%'}}
                    value={filter.limit}
                    onChange={(value) => {
                        onPageChange(filter.page || 1,value)
                    }}
            >
                {pageSizes.map((s,i) => <Select.Option key={s+i} value={s}>
                    {s}/ page
                </Select.Option>)}
            </Select>
        </div>

    )
}

export default CustomPagination;
