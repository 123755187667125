import React, {RefObject} from 'react'
import moment from 'moment'
import lodash from 'lodash'
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Upload
} from 'antd'
import ProjectApi from '../../Api/ProjectApi'
import PaymentVoucherCreateCommand from '../../Command/PaymentVoucherCreateCommand'
import ProjectDomain from '../../Domain/ProjectDomain'
import CustomerDomain from '../../Domain/CustomerDomain'
import SuggestCustomerQueryCriteria from '../../QueryCriteria/SuggestCustomerQueryCriteria'
import CustomerApi from '../../Api/CustomerApi'
import Utils from '../../Util/Utils'
import {AppContext} from '../../Context/AppContext'
import SelectPaymentReason from '../SelectPaymentReason'
import AccountDomain from '../../Domain/AccountDomain'
import {RcFile, UploadFile} from 'antd/lib/upload/interface'
import InvoiceApi from '../../Api/InvoiceApi'
import AccountApi from '../../Api/AccountApi'
import {DraggableModal, DraggableModalRef} from '../DraggableModal'
import SecurityService from '../../Util/SecurityService'
import {ACCOUNT_PERMISSIONS} from '../../Util/Constants'
import PaymentVoucherDomain from '../../Domain/PaymentVoucherDomain'
import {ModalCommon} from '../ModalCommon'
import PaymentVoucherApi from '../../Api/PaymentVoucherApi'

interface Props {
    modalRef?: RefObject<DraggableModalRef>
    loading?: boolean
    voucherCloneCode?: string
    onVisibleChange: (visible: boolean) => void
    onSubmit: (data: PaymentVoucherCreateCommand) => void
}

interface State {
    loading: boolean
    receiverType: string
    project: any
    form: PaymentVoucherCreateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    account: AccountDomain
    fileList: Array<UploadFile>
    previewImage: string
    previewTitle: string
    previewVisible: boolean
    uploadedAttachments: any
    countWarning: number
    visible: boolean
}
const maxHeight: number = (window.innerHeight / 100) * 85
const innerHeight: number = maxHeight > 760 ? 760 : maxHeight

class ModalPaymentVoucherCreate extends React.Component<Props, State> {
    static contextType = AppContext
    formRef: any | undefined = React.createRef()

    state: State = {
        account: this.context.state.account,
        loading: false,
        receiverType: 'system',
        project: undefined,
        form: {
            timestamp: moment().toISOString(),
            account: lodash.get(this.context.state.account, 'code'),
            paymentReason: '',
            invoice: '',
            project: '',
        },
        projects: [],
        customers: [],
        fileList: [],
        previewImage: '',
        previewTitle: '',
        previewVisible: false,
        uploadedAttachments: {},
        countWarning: 0,
        visible: true,
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const newState = { ...state }
        if ('loading' in props) {
            newState.loading = props.loading
        }
        return newState
    }

    componentDidMount() {
        this.fetchProjects()
        const { voucherCloneCode } = this.props

        if (voucherCloneCode) {
            this.fetchVoucherDetails(voucherCloneCode)
        }
    }
    
	fetchVoucherDetails = (code: string) => {
        this.setState({loading: true})
        PaymentVoucherApi.getByCode(code)
            .then((response) => {
                this.initialFormValues(response.data)
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    initialFormValues = (values: PaymentVoucherDomain) => {
        const isHadReceiveRef = values.project

        this.setState({
            receiverType: isHadReceiveRef ? 'system' : 'person',
            project: values.project,
            form: {
                ref: values.ref,
                timestamp: moment().toISOString(),
                memo: values.memo,
                invoice: values.invoice,
                paymentReason: values.paymentReason.code,
                remark: values.remark,
                system: values.system || '',
                receiverRef: isHadReceiveRef && values.receiverRef?.username,
                receiver: isHadReceiveRef ? '' : values.receiver,
                project: values.project,
            },
        })
        
        this.formRef?.current?.setFieldsValue({memo: values.memo})
    }

    fetchProjects = (filter = {}) => {
        this.setState({ loading: true })
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({ loading: true })
        CustomerApi.getSuggestCustomers({ limit: "200", ...filter })
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        })
    }

    handleChangeNumber = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: field === 'receiverRef' ? value?.trim() : value,
            },
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
        })
    }

    handleChangeReceiverType = (e: any) => {
        const {form} = this.state
        this.setState({
            receiverType: e.target.value,
            project: undefined,
            form: {
                ...form,
                receiverRef: '',
                receiver: '',
                project: undefined,
            }
        })
    }

    handleChangeProject = (value: any) => {
        this.setState({
            project: value,
        })

        const { form } = this.state
        form.receiverRef = ''
        this.setState({
            form: { ...form, project: value },
            customers: [],
        })
    }

    handleSearchStaff = (value: any) => {
        if (this.state.project) {
            if (value && value.toString().length >= 3) {
                this.fetchCustomers({
                    query: value,
                    project: this.state.project,
                })
            }
        }
    }

    handleSubmit = (isSubmidAndConfirm?: boolean) => {
        const { form, receiverType, uploadedAttachments, account, customers, project } = this.state
        const data = { approve: isSubmidAndConfirm, account: account.code, ...form }

        if (receiverType === 'person') {
            lodash.unset(data, 'receiverRef')
        } else {
            lodash.unset(data, 'receiver')
            const customer = customers.find((c) => c.username === data.receiverRef)
            if (project && data.receiverRef) {
                data.receiverRef = `c:${project}:${data.receiverRef}`
            }
            if (customer) {
                data.receiver = `${customer.fullname} (${customer.project})`
            }
        }

        if (!data.paymentReason) {
            data.paymentReason = 'other'
        }

        if (Object.keys(uploadedAttachments).length) {
            data.invoiceAttachments = lodash.map(uploadedAttachments, (value) => value)
        }

        if (data.paymentReason && data.invoice) {
            AccountApi.getPaymentVoucherWarnings(account.code, data.paymentReason, data.invoice).then((response) => {
                if (response.data.length > 0) {
                    this.setState({ countWarning: response.data.length }, () => {
                        Modal.confirm({
                            title: (
                                <div>
                                    Đã có {this.state.countWarning} lần chi cho hóa đơn{' '}
                                    <a
                                        target={'_blank'}
                                        href={`/payment-vouchers?invoice=${form.invoice}`}
                                        rel={'noreferrer'}>
                                        {data.invoice}
                                    </a>
                                    , bạn vui lòng kiểm tra kỹ trước khi tạo phiếu
                                </div>
                            ),
                            centered: true,
                            onOk: () => {
                                this.setState({ countWarning: 0 })
                                this.props.onSubmit(data)
                            },
                            onCancel: () => {
                                this.setState({ countWarning: 0 })
                            },
                        })
                    })
                } else this.props.onSubmit(data)
            })
            return
        }

        if (!!data.receiverRef) {
            this.checkUsernameExist(data, isSubmidAndConfirm)
            return
        }
        
        this.props.onSubmit(data)
    }

    checkUsernameExist = (data: any, isSubmitAndConfirm?: boolean) => {
        const {customers,form} = this.state
        const userSelectedIndex: any = customers.findIndex(( customer ) => customer.username === data.receiverRef.split(':')[2])
        console.log('userSelectedIndex', userSelectedIndex)
        if (userSelectedIndex !== -1) {
            this.props.onSubmit(data)
            return
        }

        if (userSelectedIndex === -1 && !isSubmitAndConfirm) {
            ModalCommon.confirm({
                title: <span>Username <span className={'bold'}>{form.receiverRef}</span> không tồn tại trong hệ thống <span className={'bold'}>{form.project}</span>. Bạn vẫn muốn tiếp tục?</span>,
                onOk: () => {
                    this.props.onSubmit(data)
                },
            })
        }
        else {
            this.props.onSubmit(data)
        }

    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    handlePreviewImage = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.getBase64(file.originFileObj)
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    handleUploadChange = (info: any) => {
        this.setState({
            fileList: info.fileList,
        })
        return false
    }

    handleCancelPreview = () => {
        this.setState({
            previewVisible: false,
            previewTitle: '',
            previewImage: '',
        })
    }

    handleBeforeUpload = (file: RcFile) => {
        const { uploadedAttachments } = this.state
        this.setState({ loading: true })
        InvoiceApi.attachment([file]).then((response) => {
            uploadedAttachments[file.uid] = response.data[0]
            this.setState({ uploadedAttachments })
        })
        return false
    }

    handleRemoveFile = (file: UploadFile) => {
        const { uploadedAttachments } = this.state
        lodash.unset(uploadedAttachments, file.uid)
        this.setState({ uploadedAttachments })
    }

    render(): React.ReactNode {
        const form: PaymentVoucherCreateCommand = this.state.form
        const { loading, receiverType, projects, customers, project, account, fileList, previewVisible, previewTitle, previewImage } = this.state
        const currency = lodash.get(account, 'currency.code')
        const accountType = lodash.get(account, 'type')
        const accountProjects = account.projects || []

        return (
            <DraggableModal
                ref={this.props.modalRef}
                title="Tạo phiếu chi"
                initialHeight={innerHeight}
                handleCancel={this.handleCancel}
                footer={
                    <>
                        <Button
                            key="back"
                            tabIndex={12}
                            disabled={loading}
                            icon={<i className="fa-solid fa-xmark pd-r-8" />}
                            type={'ghost'}
                            onClick={this.handleCancel}
                            className="sm-mg-t-8">
                            Hủy Bỏ
                        </Button>
                        {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_APPROVE) && (
                            <Button
                                key="submitAndApprove"
                                className="sm-mg-t-8"
                                tabIndex={11}
                                disabled={loading || form.amount === undefined || form.amount === null || !form.memo}
                                icon={<i className="fa-solid fa-check pd-r-8" />}
                                onClick={() => this.handleSubmit(true)}>
                                Tạo Phiếu & Xác Nhận
                            </Button>
                        )}
                        <Button
                            key="submit"
                            className="sm-mg-t-8"
                            type="primary"
                            tabIndex={11}
                            disabled={loading || form.amount === undefined || form.amount === null || !form.memo}
                            icon={<i className="fa-solid fa-check pd-r-8" />}
                            onClick={() => this.handleSubmit()}>
                            Tạo phiếu
                        </Button>
                    </>
                }>
                <Form
                    labelCol={{ span: 6 }}
                    labelAlign="left"
                    ref={this.formRef}
                    autoComplete={'on'}>
                        <Spin spinning={loading}>
                    <div className={'mg-bt-12 font-medium fsz-16px capitalize'}>Thông tin giao dịch</div>

                    <Form.Item label={'Mã bút toán'}>
                        <Input
                            disabled={accountType === 'CASH' || loading}
                            autoFocus={accountType !== 'CASH'}
                            tabIndex={1}
                            min={0}
                            className={'width-100pc'}
                            placeholder="Vui lòng nhập mã bút toán"
                            value={form.ref}
                            onChange={this.handleChangeInput.bind(this, 'ref')}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item
                        label={`Số tiền (${currency})`}
                        name="amount"
                        rules={[{ required: true, message: 'Số tiền không được để trống' }]}>
                        <InputNumber
                            autoFocus={accountType === 'CASH'}
                            tabIndex={2}
                            min={0}
                            className={'width-100pc'}
                            formatter={(value) => {
                                if (!value) {
                                    return ''
                                }

                                return currency === 'VND' ? Utils.currencyFormat(value, '0,0') : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            value={form.amount}
                            disabled={loading}
                            placeholder={`Vui lòng nhập số tiền (${currency})`}
                            onChange={this.handleChangeNumber.bind(this, 'amount')}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item label={'Thời gian giao dịch'}>
                        <DatePicker
                            tabIndex={3}
                            className={'width-100pc'}
                            format={'DD/MM/YYYY HH:mm:ss'}
                            value={form.timestamp ? moment(form.timestamp) : null}
                            onChange={this.handleChangeDatePicker.bind(this, 'timestamp')}
                            disabledDate={(current) => current && current > moment()}
                            showTime
                            placeholder="Vui lòng chọn thời gian giao dịch"
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Nội dung giao dịch'}
                        name="memo"
                        initialValue={form.memo}
                        rules={[{ required: true, message: 'Nội dung giao dịch không được để trống' }]}>
                        <Input
                            tabIndex={4}
                            value={form.memo}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, 'memo')}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder="Vui lòng nhập nội dung giao dịch"
                        />
                    </Form.Item>

                    <Form.Item label={'Số hoá đơn'}>
                        <Input
                            value={form.invoice}
                            disabled={loading}
                            placeholder="Vui lòng nhập số hoá đơn"
                            onChange={this.handleChangeInput.bind(this, 'invoice')}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item label={'Mục đích chi'}>
                        <SelectPaymentReason
                            value={form.paymentReason}
                            selectProps={{
                                placeholder: 'Vui lòng nhập mục đích chi',
                            }}
                            onChange={this.handleChangeSelect.bind(this, 'paymentReason')}
                        />
                    </Form.Item>

                    <Form.Item label={'Ghi chú'}>
                        <Input.TextArea
                            rows={3}
                            tabIndex={5}
                            value={form.remark}
                            placeholder="Vui lòng nhập ghi chú"
                            onChange={this.handleChangeInput.bind(this, 'remark')}
                            autoComplete={'on'}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Ảnh hoá đơn'}
                        className="label-align-center">
                        <Upload
                            disabled={loading}
                            accept={'image/*'}
                            listType="picture-card"
                            multiple
                            fileList={fileList}
                            beforeUpload={this.handleBeforeUpload}
                            onPreview={this.handlePreviewImage}
                            onChange={this.handleUploadChange}
                            onRemove={this.handleRemoveFile}>
                            <i className="fa-solid fa-plus-large" />
                            Chọn ảnh từ máy tính
                        </Upload>
                        <Modal
                            closeIcon={<i className="fa-solid fa-xmark" />}
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.handleCancelPreview}
                            centered>
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewImage}
                            />
                        </Modal>
                    </Form.Item>

                    <div className={'mg-bt-12 font-medium fsz-16px capitalize'}>Người nhận tiền</div>

                    <Row
                        gutter={12}
                        className={'mg-bt-10'}>
                        <Col span={2}>
                            <Radio
                                tabIndex={6}
                                value={'system'}
                                className="pd-l-8"
                                checked={receiverType === 'system'}
                                disabled={!accountProjects.length}
                                onChange={this.handleChangeReceiverType}
                            />
                        </Col>
                        <Col span={11}>
                            <Select
                                tabIndex={7}
                                disabled={!accountProjects.length || receiverType !== 'system'}
                                className={'width-100pc'}
                                placeholder={'Vui lòng nhập hệ thống'}
                                value={project}
                                allowClear
                                onChange={this.handleChangeProject}>
                                {projects
                                    .filter((item) => accountProjects.includes(item.code))
                                    .map((item: ProjectDomain) => (
                                        <Select.Option
                                            key={item.code}
                                            value={item.code}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={11}>
                            <AutoComplete
                                tabIndex={8}
                                disabled={!accountProjects.length || receiverType !== 'system'}
                                autoClearSearchValue={false}
                                className={'width-100pc'}
                                placeholder={'Vui lòng nhập Username/id'}
                                value={form.receiverRef}
                                showSearch={true}
                                filterOption={false}
                                options={customers.map((item: CustomerDomain) => {
                                    return {
                                        value: item.username,
                                        label: `${item.fullname} (${item.username})`,
                                    }
                                })}
                                allowClear
                                onChange={this.handleChangeSelect.bind(this, 'receiverRef')}
                                onSearch={lodash.debounce(this.handleSearchStaff, 1000)}
                            />
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={2}>
                            <Radio
                                tabIndex={9}
                                value={'person'}
                                className="pd-l-8"
                                checked={receiverType === 'person'}
                                onChange={this.handleChangeReceiverType}
                            />
                        </Col>
                        <Col span={22}>
                            <Input
                                autoFocus={true}
                                tabIndex={10}
                                disabled={receiverType !== 'person' || loading}
                                placeholder={'Vui lòng nhập người nhận tiền'}
                                value={form.receiver}
                                onChange={this.handleChangeInput.bind(this, 'receiver')}
                                onPressEnter={() => this.handleSubmit()}
                            />
                        </Col>
                    </Row>
                    </Spin>
                </Form>
            </DraggableModal>
        )
    }
}

export default ModalPaymentVoucherCreate
