import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import {Badge, Col, Row} from "antd";
import PaymentReasonApi from "../../Api/PaymentReasonApi";
import lodash from "lodash";
import moment from 'moment';
import FilterForm from "./FilterForm";
import {PaymentReason, PaymentReasonDomain} from "../../Domain/PaymentReasonDomain";
import Utils from "../../Util/Utils";
import PaymentReasonForm from "./PaymentReasonForm";
import CommonTable from "../../Component/CommonTable";
import SecurityService from "../../Util/SecurityService";
import { PERMISSIONS } from "../../Util/Constants";

interface State {
    loading: boolean,
    items: Array<any>,
    columns: Array<any>,
    selectedPaymentReason: PaymentReasonDomain,
    pagination: {
        pageSize: number,
        currentPage: number,
        total: number
    },
    collapse: boolean,
}

export class PaymentReasonList extends DefaultComponent<any, State> {

    state: State = {
        loading: false,
        items: [],
        selectedPaymentReason: new PaymentReason(),
        pagination: {
            pageSize: 25,
            currentPage: 1,
            total: 0
        },
        collapse: true,
        columns: [
            {
                key: "code",
                title: "Mã",
                dataIndex: 'code',
                width:'20%',
                render: (code: any) => <span className="blue-link pointer">{code}</span>
            },
            {
                key: "name",
                title: "Tên",
                dataIndex: 'name',
                width:'50%',
                render: (name: any) => <div className="blue-link pointer">{name}</div>
            },
            {
                key: "createdBy",
                title: "Người tạo",
                dataIndex: 'createdBy',
                width:'30%%',
                render: (createdBy: any, row: any) => <div>
                   <span>Bởi <strong>{createdBy}</strong> lúc {moment(row.createdAt).format('DD/MM/YYYY')}</span>
                </div>
            }
        ]
    }

    componentDidMount() {
        this.fetchPaymentReasons({
            ...this.getQueryFromLocation()
        });
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchPaymentReasons({
                ...this.getQueryFromLocation()
            });
            this.setState({
                ...this.state,
                pageSize:this.getQueryFromLocation().limit,
                currentPage:this.getQueryFromLocation().page,
            })
        }
    }

    fetchPaymentReasons = (filter: {}) => {
        filter = {
            sort: 'createdAt:desc',
            limit: this.state.pagination.pageSize,
            ...filter,
        }
        this.setState({loading: true});

        PaymentReasonApi.filter(filter)
            .then(response => {
                this.setState({
                    items: response.data,
                    pagination: {
                        currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                        pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                        total: parseInt(lodash.get(response, 'headers.x-total-count'))
                    }
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleChangePage = (page: any, pageSize: any) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            offset: (page - 1) * pageSize,
            limit: pageSize
        })
    }

    handleCreateSuccess = () => {
        const queryString = this.getQueryFromLocation();
        if (Object.keys(queryString).length) {
            this.pushCleanQueryToHistory({});
        } else {
            this.fetchPaymentReasons({});
        }

        this.setState({selectedPaymentReason: new PaymentReason()})
    }

    handleSelectPaymentReason = (paymentReason: PaymentReasonDomain) => {
        if (!SecurityService.can(PERMISSIONS.PAYMENT_REASON_UPDATE)) {
            return
        }
        this.setState({selectedPaymentReason: paymentReason})
    }

    handleCancel = () => {
        this.setState({selectedPaymentReason: new PaymentReason()})
    }

    handleOnRow = (row: any) => {
        return {
            onClick: () => this.handleSelectPaymentReason(row)
        }
    }

    handleCollapseFilter = () => {
        this.setState({collapse: !this.state.collapse})
    }

    render() {
        const {pagination, items, columns, selectedPaymentReason, loading} = this.state;
        const isCanCreate = SecurityService.can(PERMISSIONS.PAYMENT_REASON_CREATE) || SecurityService.can(PERMISSIONS.PAYMENT_REASON_UPDATE)

        return (
            <DefaultLayout
                {...this.props}
                title={'Mục đích chi'}
                breadcrumb={[{title:'Danh Sách Mục Đích Chi'}]}
            >
                <div className="main-content payment-reason-container">
                    <div>
                        <div className={'white-box payment-reason-container__filter'}>
                            <div className={'payment-reason-container__filter-title align-items-center'}
                                 onClick={this.handleCollapseFilter}>
                                <span className={'capitalize'}>Tìm kiếm</span>

                                {this.state.collapse ? <i className="fa-solid fa-chevron-down fsz-14px"/> :
                                    <i className="fa-solid fa-chevron-up fsz-14px"/>}
                            </div>
                            {!this.state.collapse && <div className={'payment-reason-container__filter-search'}>
                                <FilterForm handleCollapseFilter={this.handleCollapseFilter} {...this.props} />
                            </div>}
                        </div>
                    </div>
                    <div style={{height:window.innerHeight - 135}}>
                        <Row>
                            <Col lg={isCanCreate ? 16: 24} xl={isCanCreate ? 12: 24}>
                                <div className={'payment-reason-container__data'}>
                                    <div className={'payment-reason-container__data-header'}>
                                        <span className={'capitalize'}>Danh sách mục đích chi</span>
                                        <Badge className={'mg-l-6'} count={Utils.currencyFormat(this.state.pagination.total)} overflowCount={999}/>
                                    </div>
                                    <div className={'payment-reason-container__data-list'}>
                                        <CommonTable
                                            className={''}
                                            columns={columns}
                                            dataSource={items}
                                            rowKey={'code'}
                                            pagination={{
                                                pageSize: pagination.pageSize,
                                                total: pagination.total,
                                                current: pagination.currentPage,
                                                onChange: this.handleChangePage,
                                                showSizeChanger:true,
                                                pageSizeOptions:[10,20,25,50,100],
                                                showLessItems:true,
                                            }}
                                            emptyText="Danh sách mục đích chi đang trống"
                                            loading={loading}
                                            rowClassName={'pointer payment-reason-record'}
                                            onRow={this.handleOnRow}
                                            scroll={{y:window.innerHeight - 300}}
                                        />
                                    </div>
                                </div>
                            </Col>
                            {
                                isCanCreate && <Col xs={24} lg={8} xl={12}>
                                    <div className="payment-reason-container__create-box">
                                        <PaymentReasonForm onSuccess={this.handleCreateSuccess} onCancel={this.handleCancel} data={selectedPaymentReason}/>
                                    </div>
                                </Col>
                            }

                        </Row>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}
