import React from 'react';
import DefaultLayout from "../../Component/Layout/Default";
import DefaultComponent from "../../Component/DefaultComponent";

class Home extends DefaultComponent {

    render(): React.ReactNode {
        return (
            <DefaultLayout
                {...this.props}
                loading={false}
                title={"Bảng điều khiển"}
                breadcrumb={[{title: 'Bảng điều khiển'}]}
            >
                <div className="main-content">
                    <div className="white-box white-box--padding-15px">
                        <div>Bảng điều khiển</div>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default Home;