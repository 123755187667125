import React from "react";
import {Select, SelectProps} from "antd";
import {debounce} from 'lodash';
import AccountApi from "../../Api/AccountApi";
import AccountDomain from "../../Domain/AccountDomain";

interface Props {
    value?: any,
    onChange?: (value: any) => void,
    selectProps?: SelectProps<any>,
    initialItems?: Array<AccountDomain>
}

interface State {
    value: any,
    loading: boolean,
    items: Array<AccountDomain>
    query: string
}

class SelectMyAccount extends React.Component<Props, State> {

    state = {
        value: undefined,
        loading: false,
        items: [],
        query: ''
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }
        return newState;
    }

    componentDidMount() {
        if (this.props.initialItems) {
            this.setState({
                items: this.props.initialItems
            })
            return
        }

        this.fetchMyAccounts();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        let query = this.state.query
        if (query !== prevState.query) {
            this.fetchMyAccounts({query});
        }
    }

    fetchMyAccounts = (query = {}) => {
        query = {
            offset: 0,
            limit: 200,
            ...query
        }
        this.setState({loading: true});
        AccountApi.autocomplete(query)
            .then(response => {
                this.setState({
                    items: response.data
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleChange = (value: any) => {
        this.setState({value});
        this.props.onChange?.(value);
    };

    handleSearch = (value: any) => {
        this.setState({query: value})
    }

    render() {
        const {selectProps,} = this.props;
        const {items, value} = this.state;
        return (
            <Select
                allowClear
                showSearch={true}
                filterOption={false}
                value={value}
                onChange={this.handleChange}
                onSearch={debounce(this.handleSearch,700)}
                placeholder={"Chọn tài khoản quỹ"}
                {...selectProps}
            >
                {items.map((item: AccountDomain) => (
                    <Select.Option key={item.code} value={item.code}>{item.name} ({item.code})</Select.Option>
                ))}
            </Select>
        );
    }
}

export default SelectMyAccount;
