import apiClient from "../Util/ApiClient";
import PaymentVoucherCreateCommand from "../Command/PaymentVoucherCreateCommand";
import ReceiptVoucherCreateCommand from "../Command/ReceiptVoucherCreateCommand";
import {MessageUpdateCommand} from "../Command/MessageUpdateCommand";

export default class MessageApi {

    static filter = (query = {}) => {
        return apiClient.get('/messages', {
            params: query
        })
    };

    static retryAnalytics = (messageId: any) => {
        return apiClient.post(`/messages/${messageId}/retry-analysis`);
    };

    static createPaymentVoucher = (messageId: any, command: PaymentVoucherCreateCommand) => {
        return apiClient.post(`/messages/${messageId}/payment-vouchers`, command);
    };

    static createReceiptVoucher = (messageId: any, command: ReceiptVoucherCreateCommand) => {
        return apiClient.post(`/messages/${messageId}/receipt-vouchers`, {...command});
    };

    static update = (messageId: any, command: MessageUpdateCommand) => {
        return apiClient.patch(`/messages/${messageId}`, {...command})
    }
}
