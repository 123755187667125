export enum LogResrouceType {
    RECEIPT_VOUCHER = "receipt_voucher",
    PAYMENT_VOUCHER = "payment_voucher",
    ACOUNT = "account",
    TRANSACTION = "transaction",
    SMS = "sms",
}

export interface LogQueryCriteria {
    offset?: number
    limit?: number
    sort?: string
    account: string
    resourceType: LogResrouceType
    resourceCode: string // resourceCode: Mã code. Vd: Nếu lấy log phiếu thu thì là mã code của phiếu thu
    targetGrn?: string
}

export enum ReceiptVoucherEvent {
    CREATE = "CREATE_RECEIPT_VOUCHER",
    UPDATE = "UPDATE_RECEIPT_VOUCHER",
    APPROVED = "APPROVED_RECEIPT_VOUCHER",
    REJECTED = "REJECTED_RECEIPT_VOUCHER",
    UPDATE_PAYER = 'UPDATE_PAYER_RECEIPT_VOUCHER'
}

export enum PaymentVoucherEvent {
    CREATE = "CREATE_PAYMENT_VOUCHER",
    UPDATE = "UPDATE_PAYMENT_VOUCHER",
    APPROVED = "APPROVED_PAYMENT_VOUCHER",
    REJECTED = "REJECTED_PAYMENT_VOUCHER",
    UPDATE_RECEIVER = 'UPDATE_RECEIVER_PAYMENT_VOUCHER'
}

export interface ChangedValuesInterface {
    key: string
    left: any
    right: any
}
export interface LogInterface<EventType = string, Details = {}> {
    id: string
    application: string
    namespace: string
    module: string
    tenant: string
    subTenant: string
    activity: EventType
    timestamp: string
    actor: {
        grn: string
        username: string
        namespace: string
        module: string
        role: string
        tenant: string
        ip: string
        agent: string
        metadatda: {}
    }
    tracing: {
        tracer: string
        traceId: string
        spanId: string
    }
    change: {
        type: "NEW_OBJECT"
        newObject: [Details]
        removedObject: [{}]
        valueChange: {
            changedValues: ChangedValuesInterface[]
        }
        mapChange: {
            addedEntries: [
                {
                    key: string
                    value: string
                }
            ]
            removedEntries: [
                {
                    key: string
                    value: string
                }
            ]
            changedEntries: [
                {
                    key: string
                    left: {}
                    right: {}
                }
            ]
        }
        containerChange: {
            key: string
            addedElements: [
                {
                    value: string
                }
            ]
            removedElements: [
                {
                    value: string
                }
            ]
        }
    }
    target: {
        id: string
        grn: string
        metadata: Details
    }
}
