import apiClient from "../Util/ApiClient";
import RoleCreateCommand from "../Command/RoleCreateCommand";
import RoleUpdateCommand from "../Command/RoleUpdateCommand";
import RoleGrantPermissionCommand from "../Command/RoleGrantPermissionCommand";
import RoleDeletePermissionCommand from "../Command/RoleDeletePermissionCommand";

export default class RoleApi {

    static filter = (query = {}) => {
        return apiClient.get('/roles', {
            params: query
        })
    };

    static create = (data: RoleCreateCommand) => {
        return apiClient.post('/roles', data);
    }

    static update = (code: string, data: RoleUpdateCommand) => {
        return apiClient.patch(`/roles/${code}`, data);
    }

    static getPermissions = (code: string) => {
        return apiClient.get(`/roles/${code}/permissions`,{params:{limit:1000}});
    }

    static grantPermissions = (code: string, data: RoleGrantPermissionCommand) => {
        return apiClient.post(`/roles/${code}/permissions`, data);
    }

    static deletePermissions = (code: string, data: RoleDeletePermissionCommand) => {
        return apiClient.delete(`/roles/${code}/permissions`, {
            params: {
                permissions: data.permissions.join(',')
            }
        });
    }
}
