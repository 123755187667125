import {Badge, List, Modal, Skeleton, Tabs} from "antd"
import React, {forwardRef, ForwardRefExoticComponent, useEffect, useImperativeHandle, useState} from "react"
import {AxiosResponse} from "axios"
import LogApi from "../../Api/LogApi"
import {LogInterface} from "../../Interface/LogInterface"
import {LogQueryCriteria} from "../../QueryCriteria/LogQueryCriteria"
import {LogItem} from "./LogItem";
import {LogFilterInterface, LogModalProps, LogModalRef} from "./type";

const {TabPane} = Tabs;

const LogModal: ForwardRefExoticComponent<LogModalProps & React.RefAttributes<unknown>> = forwardRef(
    ({
         title,
         ListItemContent,
         isShowResourceCodeOnTitle = true,
         filters = [],
         initialFilter
     }: LogModalProps, ref: any) => {
        const [dataSource, setDataSource] = useState<LogInterface[]>([])
        const [loading, setLoading] = useState<boolean>(true)
        const [logQuery, setLogQuery] = useState<LogQueryCriteria>()
        const [logListFiltered, setLogListFiltered] = useState<LogInterface[][]>([])
        const [filterIndexSelected, setFilterIndexSelected] = useState<string>("0")

        const getData = () => {
            if (!logQuery) {
                return
            }

            setFilterIndexSelected("0")

            setLoading(true)
            LogApi.getLogs(logQuery)
                .then((res: AxiosResponse<LogInterface[]>) => {
                    setDataSource(res.data)
                })
                .catch(() => {
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        useImperativeHandle(ref, (): LogModalRef => ({
            setLogQuery,
            logQuery
        }))

        useEffect(() => {
            if (!logQuery) {
                setDataSource([])
                return
            }

            getData()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [logQuery])

        useEffect(() => {
            if (filters?.length < 1) {
                return
            }

            const logList: LogInterface[] = [...dataSource]
            const result: LogInterface[][] = []
            logList.forEach((log: LogInterface, index) => {
                filters.forEach((filter, indexFilter) => {
                    const {activitiesInclude = [], activitiesExclude = []} = filter
                    if (result[indexFilter] === undefined) {
                        result[indexFilter] = []
                    }

                    if (activitiesInclude.length > 0 && activitiesInclude.includes(log.activity)) {
                        result[indexFilter].push(logList[index])
                    }

                    if (activitiesExclude.length > 0 && !activitiesExclude.includes(log.activity)) {
                        result[indexFilter].push(logList[index])
                    }
                })

            })

            setLogListFiltered(result)
            // eslint-disable-next-line
        }, [dataSource])

        return (
            <Modal
                visible={!!logQuery}
                title={isShowResourceCodeOnTitle ? <>{title}{' '}<span
                    className="d-inline-block">#{logQuery?.resourceCode}</span></> : title}
                onCancel={() => setLogQuery(undefined)}
                closeIcon={<i className="fa-solid fa-xmark"/>}
                centered
                footer={false}
                width={864}
                className={filters?.length > 0 ? "body-pd-t-0 log-modal" : "body-pd-t-0"}
            >
                {loading ? (
                        <>
                            <Skeleton title={true} paragraph={{rows: 1}} active/>
                            <Skeleton title={true} paragraph={{rows: 1}} active/>
                            <Skeleton title={true} paragraph={{rows: 1}} active/>
                        </>
                    ) :
                    (filters.length > 0 ?
                        (
                            <>
                                <Tabs className="mg-t-12" activeKey={String(filterIndexSelected)}
                                      onChange={(activeKey: string) => setFilterIndexSelected(activeKey)}>{
                                    filters.map((filter: LogFilterInterface, index: number) => (
                                        <TabPane key={String(index)} tab={<>{filter.title}
                                            <Badge className="pd-l-4" size="small"
                                                   overflowCount={999}
                                                   count={logListFiltered[index]?.length || 0}/></>}>

                                        </TabPane>
                                    ))
                                }</Tabs>

                                <List
                                    dataSource={logListFiltered[Number(filterIndexSelected)]}
                                    renderItem={(item: any, index: number) =>
                                        <LogItem item={item} index={index} logQuery={logQuery}
                                                 ListItemContent={ListItemContent}/>}
                                />
                            </>
                        ) : <List
                            dataSource={dataSource}
                            renderItem={(item: any, index: number) =>
                                <LogItem item={item} index={index} logQuery={logQuery}
                                         ListItemContent={ListItemContent}/>}
                        />)
                }
            </Modal>
        )
    }
)

export {LogModal}
