import React from "react";
import ACCESS_DENIED_403 from "../resources/images/403.png";
import {Button} from "antd";
import {useHistory} from "react-router-dom";

function Error403 () {
    const history = useHistory();
    return(
        <>
            <div className="access-denied mg-t-48">
                <div className="body">
                    <div className="container">
                        <div className="content">
                            <img src={ACCESS_DENIED_403} alt={''} style={{width: '100%', height: 'auto'}}/>
                            <div className="title">Truy Cập Bị Từ Chối!</div>
                            <p className={'message'}>Rất xin lỗi, bạn không có quyền truy cập vào trang này, vui lòng
                                quay trở lại Trang Chủ hoặc liên hệ bộ phận hỗ trợ!</p>
                            <Button type={'primary'} onClick={() => history.push('/')}> <i className="fa-regular fa-house mg-r-6"/>Trở về trang chủ</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Error403;
