import {Button, Tooltip} from 'antd';
import lodash, {get} from 'lodash';
import moment from 'moment';
import React, {Component} from "react";
import Utils from "../../Util/Utils";
import ReceiptVoucherDomain from "../../Domain/ReceiptVoucherDomain";
import ReceiptVoucherApi from "../../Api/ReceiptVoucher";
import AccountDomain from "../../Domain/AccountDomain";
import {AxiosError} from "axios";
import DefaultPropsInterface from "../../Interface/DefaultPropsInterface";
import CommonTable from '../../Component/CommonTable';
import {NotificationCommon} from '../../Component/Notification';
import SecurityService from "../../Util/SecurityService";
import {ACCOUNT_PERMISSIONS} from "../../Util/Constants";

interface State {
    loading: boolean
    columns: Array<any>
    disableBtns: {[key: string]: boolean}
}

interface Props extends DefaultPropsInterface {
    account: AccountDomain
    onApproveSuccess: () => void
    loading?: boolean,
    items: Array<ReceiptVoucherDomain>
    total: number
    pageSize: number
    currentPage: number
    onPageChange: (page: number, pageSize: number) => void
}

class ReceiptVoucherList extends Component<Props, State> {

    state: State = {
        loading: false,
        disableBtns: {},
        columns: [
            {
                title: "Ngày tạo",
                key: "preparedAt",
                width: '15%',
                render: (row: any) => {
                    const preparedAt = row.preparedAt ? moment(row.preparedAt).format("DD/MM/YYYY HH:mm:ss") : "--";
                    return <span>{preparedAt}</span>
                }
            },
            {
                title: "Thời gian giao dịch",
                key: "timestamp",
                width: '15%',
                align: 'right',
                render: (row: any) => {
                    const timestamp = row.timestamp ? moment(row.timestamp).format("DD/MM/YYYY HH:mm:ss") : "--";
                    return (<span>{timestamp}</span>)
                }
            },
            {
                title: "Người nộp tiền",
                key: "payer",
                width: '12%',
                dataIndex: "payer",
                render: (payer: string, row: ReceiptVoucherDomain) => {
                    let transactionUrl = `/transactions?participantType=outside&participant=${payer}`;
                    if (row.payerRef) {
                        transactionUrl = `/transactions?participantType=inside&project=${lodash.get(row.payerRef, 'project')}&participant=${lodash.get(row.payerRef, 'code')}&participantUsername=${lodash.get(row.payerRef, 'username')}`;
                        return (
                            <Tooltip title={row.payer}
                                     placement={'top'}>
                                <a href={transactionUrl} target={'_blank'} rel={'noreferrer'}>
                                    {lodash.get(row.payerRef, 'username')}
                                </a>
                            </Tooltip>
                        )
                    }
                    return <a href={transactionUrl} target={'_blank'} rel={'noreferrer'}>{payer}</a>;
                }
            },
            {
                title: "Hệ thống",
                key: "project",
                width: '10%',
                dataIndex: 'project',
            },
            {
                title: "Nội dung giao dịch",
                key: "memo",
                width: '18%',
                dataIndex: "memo",
                render:(memo:string,row:any) => {
                    return (<div>
                        <div>{memo}</div>
                        {row.remark && (
                            <div className={'transaction-remark font-size-12'}>
                                <i className="fa-regular fa-circle-exclamation"/> {row.remark}
                            </div>
                        )}
                    </div>)
                }
            },
            {
                title: "Người tạo",
                key: "preparedBy",
                width: '12%',
                render:(row:any) => {
                    let preparedBy = lodash.get(row,'preparedBy');
                    return (<div>
                        <div className={'font-size-14'}><span className={''}>{preparedBy.username}</span></div>
                    </div>)
                }
            },
            {
                title: "Số tiền",
                key: "amount",
                width: '12%',
                dataIndex: "amount",
                align: 'right',
                render: (amount: any, row: any) => {
                    const currency = lodash.get(row, 'currency.code');
                    return  (
						<span className={'fsz-16px positive-money bold'}>
                            {Utils.currencyFormatByCurrency(amount, currency)}
                        </span>
                    )
                }
            },
            {
                title: "Thao tác",
                key: "action",
                width: '28%',
                align: 'right',
                render: (row: any) => {
                    const disabled: boolean = this.state.disableBtns[row.id]
                    return(
                        <div className={'flex justify-content-flex-end'}>
                            {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_APPROVE) && <Button
                                disabled={disabled}
                                type={'primary'}
                                onClick={this.onApprove.bind(this, row.code, row.id)}
                                icon={<i className="fa-regular fa-file-check mg-r-6"/>}
                                className="only-show-from-sm mg-r-6">
                                Xác Nhận GD
                            </Button>}

                            {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_REVIEW) && <>
                                {get(row,'reviewStatus') === 'PENDING' ?  null
                                    : <Button icon={<i className="fa-regular fa-check mg-r-6" />} disabled type={'ghost'}
                                              className={'width-100pc'}  style={{maxWidth:150}}
                                    >
                                        Đã Duyệt
                                    </Button>}
                            </>}

                        </div>
                    )
                }
            },
        ]
    };

    onApprove = (code: string, id: string) => {
        this.setState({loading: true})
        this.setState({disableBtns: {
            ...this.state.disableBtns,
            [id]: true
        }})

        ReceiptVoucherApi.approve(code)
            .then(() => {
                NotificationCommon.success({message: "Xác nhận giao dịch thu thành công!"})
                    this.props.onApproveSuccess();
            })
            .catch((error: AxiosError) => {
                let message: any = "Xác nhận giao dịch thu thất bại!";
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'ref_exist') {
                        const transactionCode = lodash.get(error.response, 'data.transactionCode');
                        message = <span>Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b></span>;
                        NotificationCommon.error({
                            message: message
                        })
                    }
                    else if (get(error.response, 'data.title') === 'source_exist') {
                        NotificationCommon.error({
                            message: 'Không thể xác nhận vì đã tồn tại giao dịch'
                        })
                    } else if (lodash.get(error, "response.data.title") === "invalid_customer") {
                        NotificationCommon.error({
                            message: "Sai định dạng username",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
                        let details = lodash.get(error,'response.data.detail').split(/'/);
                        NotificationCommon.error({
                            message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
                        })
                    } else if (lodash.get(error, "response.data.title") === "voucher_ineligible") {
                        NotificationCommon.error({
                            message: "Phiếu thu đã được xác nhận giao dịch. Vui lòng kiểm tra lại",
                        })
                    } else {
                        NotificationCommon.error({
                            message: message
                        })
                    }
                }
                else if (lodash.get(error.response,'status') === 403) {
                    NotificationCommon.error({
                        message: 'Bạn không có quyền thực hiện thao tác này'
                    })
                }
                else {
                    NotificationCommon.error({message});
                }
                this.setState({disableBtns: {
                    ...this.state.disableBtns,
                    [id]: false
                }})
            }).finally(() => {
                this.setState({loading: false})
            })
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state;
        return (
            <CommonTable
                className={'large-height-row'}
                columns={columns}
                dataSource={items}
                loading={loading}
                emptyText="Danh sách phiếu thu đang trống"
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: pageSize,
                    onChange: this.props.onPageChange
                }}
                scroll={items.length >= 3 ? { y: 240, x: 1167 } : undefined}
            />
        );
    }
}

export default ReceiptVoucherList;
