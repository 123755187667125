import React, { useEffect, useMemo, useState } from 'react'
import { Button, DatePicker } from 'antd'
import moment from 'moment'
import queryString from 'query-string'
import Utils from '../../Util/Utils'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'

const RangePicker = DatePicker.RangePicker

const beginDateFormat = 'YYYY-MM-DD 00:00:00'
const endDateFormat = 'YYYY-MM-DD 23:59:59'

interface FilterFormStateInterface {
    timestampFrom?: string
    timestampTo?: string
}

export const FilterForm = (props: DefaultPropsInterface) => {
    const [filterForm, setFilterForm] = useState<FilterFormStateInterface>({})
    const [isShow, setIsShow] = useState<boolean>(false)

    useEffect(() => {
        const query: any = queryString.parse(props.location.search)
        setFilterForm((value) => {
            return {
                ...value,
                timestampFrom: query.timestampFrom || undefined,
                timestampTo: query.timestampTo || undefined,
            }
        })
    }, [props.location])

    const handleChangedFilterForm = (key: keyof FilterFormStateInterface, value: any) => {
        filterForm[key] = value
        setFilterForm({ ...filterForm })
    }

    const handleFilter = () => {
        Utils.pushCleanQueryToHistory(props.history, props.location, filterForm)
    }

    const clearFilter = () => {
        Utils.pushCleanQueryToHistory(props.history, props.location, {
            t: Date.now(),
        })
    }

    const disableSubmit: boolean = useMemo(() =>  Utils.checkDisableSubmit(filterForm, ['t']), [filterForm])

    return (
        <div className="white-box d-flex pd-t-12 pd-bt-12 pd-l-12 pd-r-12">
            <div
                className="font-medium  capitalize title pointer space-between align-items-center"
                onClick={() => setIsShow(!isShow)}>
                <span className="fsz-16px">Tìm kiếm</span>
                {isShow ? <i className="fa-solid fa-chevron-up" /> : <i className="fa-solid fa-chevron-down" />}
            </div>

            {isShow && (
                <div className="mg-t-12">
                    <RangePicker
                        className={'mg-r-8'}
                        value={[
                            filterForm.timestampTo ? moment(filterForm.timestampTo) : null,
                            filterForm.timestampFrom ? moment(filterForm.timestampFrom) : null,
                        ]}
                        placeholder={['Từ', 'Đến']}
                        format={'DD/MM/YYYY'}
                        onChange={(values: any) => {
                            handleChangedFilterForm('timestampFrom', values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : null)
                            handleChangedFilterForm('timestampTo', values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : null)
                        }}
                    />

                    <Button
                        icon={<i className="fa-regular fa-filter pd-r-6" />}
                        type={'primary'}
                        onClick={handleFilter}
                        className="font-size-12px"
                        disabled={disableSubmit}>
                        Lọc
                    </Button>

                    {!disableSubmit && (
                        <Button
                            type={'text'}
                            onClick={clearFilter}
                            icon={<i className="fa-solid fa-repeat pd-r-4" />}>
                            Làm mới bộ lọc
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}
