import React from 'react';
import Routes from "./Routes";
import AccountDomain, {AccountModel} from "./Domain/AccountDomain";
import localStore from "./Util/LocalStore";
import {AppContext, AppContextValueInterface, defaultAppContextValue} from './Context/AppContext';
import AccountApi from "./Api/AccountApi";
import {TenantModel} from "./Domain/TenantDomain";
import axios from "axios";
import appConfig from "./Config/App";
import { notification } from 'antd';

import "./App.scss";

notification.config({
    placement: 'topRight',
    duration: 3,
    maxCount: 1,
  });
  
interface AppStateInterface {
    loading: boolean,
    account: AccountDomain,
    tenant: any,
    accountPermissions:any
}

class App extends React.Component<any, AppStateInterface> {

    state: AppStateInterface = {
        loading: false,
        account: localStore.getJson('account') || {...AccountModel},
        tenant: localStore.getJson('tenant') || {...TenantModel},
        accountPermissions:localStore.getJson('accountPermissions') || {owner:true,permissions:['string']}
    }

    componentDidMount() {
        this.fetchCurrentTenant();

        const query: URLSearchParams = new URLSearchParams(window.location.search);
        const accountCode: string | null = query.get('accountCode')

        if (accountCode) {
            this.fetchAccount(accountCode).then(() => {
                window.location.replace(window.location.pathname)
            })
        }
    }

    fetchCurrentTenant = () => {
        const tenant = localStore.getJson('tenant');
        if (!tenant) {
            axios({
                url: appConfig.apiUrl + '/tenants/current'
            })
                .then(response => {
                    this.setState({
                        tenant: response.data
                    })
                    localStore.setJson('tenant', response.data);
                })
        }
    }

    fetchAccount = (accountCode: string) => {
        this.setState({loading: true})
        return AccountApi.findByCode(accountCode)
            .then(response => {
                this.updateAccount(response.data)
            })
            .finally(() => this.setState({loading: false}))
    }

    updateAccount = (account: AccountDomain) => {
        localStore.setJson('account', account);
        this.setState({account});
    }

    fetchAccountPermissions = (accountCode:string) => {
        return AccountApi.getAccountPermissions(accountCode)
            .then(response => {
                this.setState({accountPermissions: response.data});
                localStore.setJson('accountPermissions', response.data);
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    fetchCurrentAccountTimeout = () => {
        setTimeout(() => {
            this.fetchAccount(this.state.account.code)
        }, 2000)
    }

    getContextValue = () => {
        const value: AppContextValueInterface = {...defaultAppContextValue};
        value.state.loading = this.state.loading;
        value.state.account = this.state.account;
        value.state.tenant = this.state.tenant;
        value.func.updateAccount = this.updateAccount;
        value.func.fetchAccountAndUpdateState = this.fetchAccount;
        value.func.fetchCurrentAccountTimeout = this.fetchCurrentAccountTimeout;
        value.func.updateAccountPermission = this.fetchAccountPermissions;
        value.state.accountPermissions = this.state.accountPermissions;
        return {...value};
    }

    render() {
        return (
            <AppContext.Provider value={this.getContextValue()}>
                <div className="main-body">
                    <Routes />
                </div>
            </AppContext.Provider>
        );
    }
}

export default App;
