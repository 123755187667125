import {Component} from "react";
import lodash from 'lodash';
import moment from 'moment';
import PermissionDomain from "../../Domain/PermissionDomain";
import CommonTable from "../../Component/CommonTable";

interface Props {
    loading?: boolean,
    items: Array<PermissionDomain>
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize: number) => void,
}

interface State {

}

class DataList extends Component<Props, State> {

    state = {
        columns: [
            {
                title: "Mã",
                key: "code",
                dataIndex: 'code'
            },
            {
                title: "Tên",
                key: "name",
                dataIndex: 'name'
            },
            {
                title: "Mô tả",
                key: "description",
                dataIndex: 'description'
            },
            {
                title: "Ngày tạo",
                key: "createdAt",
                dataIndex: 'createdAt',
                render: (createdAt: any) => moment(createdAt).format('DD/MM/YYYY')
            },
            {
                title: "Người tạo",
                key: "createdBy",
                dataIndex: 'createdBy'
            }
        ]
    }

    handleChangePage = (page: number, pageSize : number) => {
        this.props.onChangePage(page, pageSize)
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state;
        return (
            <div>
                <CommonTable
                    className={'a-table'}
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    emptyText="Danh sách quyền đang trống"
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        onChange: this.handleChangePage,
                        showSizeChanger:true,
                        pageSizeOptions:[10,20,25,50,100],
                        showLessItems:true,
                    }}
                    rowClassName={'role-record'}
                />
            </div>
        );
    }
}

export default DataList;
