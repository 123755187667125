import RoleDomain from "./RoleDomain"
import UserDomain from "./UserDomain"

export default interface AccountDomain {
    id: string
    code: string
    name: string
    accountNumber: string
    accountHolder: string
    bank: string
    currency: string
    note: string
    type: string
    availableBalance?: number
    status: string
    accountOwner: string
    projects: Array<string> | null
    acceptDebitTransaction: boolean
    description: string
    transactionExpire?: number
    lastSettlementAt?: string
}

export interface IAcountRecord extends AccountDomain {
    privilege: IPrivilage
    owner: boolean
}

export interface IPrivilage {
    id: string
    tenant: string
    account: string
    staff: UserDomain
    role: RoleDomain
    grantedBy: UserDomain
    grantedAt: string
    createdAt: string
    createdBy: string
    modifiedAt: string
    modifiedBy: string
    version: number
}

export const AccountModel: AccountDomain = {
    id: '',
    accountHolder: '',
    accountNumber: '',
    bank: '',
    code: '',
    currency: '',
    name: '',
    note: '',
    type: '',
    availableBalance: 0,
    status: '',
    accountOwner: '',
    projects: null,
    acceptDebitTransaction: true,
    description: '',
}
