import apiClient from "../Util/ApiClient";

export default class DeviceApi {

    static filter = (query = {}) => {
        return apiClient.get('/devices', {
            params: query
        });
    };

    static autocomplete = (query = {}) => {
        return apiClient.get('/devices/autocomplete', {
            params: query
        });
    };

    static create = (data: any) => {
        return apiClient.post('/devices', data);
    };

    static getSimsByDeviceCode = (code: string) => {
        return apiClient.get(`/devices/${code}/sims`);
    };

    static deleteDevice = (code:string) => {
        return apiClient.delete(`/devices/${code}`);
    }
}
