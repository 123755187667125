import React from 'react';
import {Card} from "antd";
import PaymentVoucherDomain from "../../../Domain/PaymentVoucherDomain";
import Barcode from "../../../Component/Barcode";

interface Props {
    paymentVoucher: PaymentVoucherDomain
}

class VoucherBarcode extends React.Component<Props, any> {

    render() {
        const {paymentVoucher} = this.props;
        return (
            <Card title={"Mã Vạch"} className={'mg-bt-12 capitalize'}>
                <Barcode value={paymentVoucher.code} width={160} />
            </Card>
        )
    }
}

export default VoucherBarcode;