import {Badge} from "antd";
import lodash from "lodash";
import React from 'react';
import PermissionApi from "../../Api/PermissionApi";
import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import PermissionDomain from "../../Domain/PermissionDomain";
import Utils from "../../Util/Utils";
import DataList from "./DataList";
import FilterForm from "./FilterForm";

interface State {
    loading: boolean,
    permissions: Array<PermissionDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    collapse: boolean,
}

class PermissionList extends DefaultComponent<any, State> {

    state: State = {
        loading: false,
        permissions: [],
        pageSize: 25,
        total: 0,
        currentPage: 1,
        collapse: true,
    }

    componentDidMount() {
        this.fetchPermissions({
            ...this.getQueryFromLocation()
        })
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchPermissions({
                ...this.getQueryFromLocation()
            });
            this.setState({
                ...this.state,
                pageSize:this.getQueryFromLocation().limit,
                currentPage:this.getQueryFromLocation().page,
            })
        }
    }

    fetchPermissions = (query = {}) => {
        this.setState({loading: true});
        const filter = {
            sort: 'createdAt:desc',
            ...query
        };
        PermissionApi.filter(filter)
            .then(response => {
                this.setState({
                    permissions: response.data,
                    currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                    pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                    total: parseInt(lodash.get(response, 'headers.x-total-count')),
                });
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    onChangePage = (page: number, pageSize: number) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize
        })
    }

    handleCollapseFilter = () => {
        this.setState({collapse: !this.state.collapse})
    }

    render() {
        const {permissions, currentPage, pageSize, total, loading} = this.state;
        return (
            <DefaultLayout
                title={"Danh sách quyền"}
                {...this.props}
                breadcrumb={[{title:'Danh Sách Quyền'}]}
            >
                <div className="main-content roles-container">
                    <div className="white-box roles-container__filter">
                        <div className={'roles-container__filter-title align-items-center'} onClick={this.handleCollapseFilter}>
                            <span>Tìm kiếm</span>
                            {this.state.collapse ? <i className="fa-solid fa-chevron-down fsz-14px"/> :
                                <i className="fa-solid fa-chevron-up fsz-14px"/>}
                        </div>
                        {!this.state.collapse && <div className={'roles-container__filter-search'}>
                            <FilterForm
                                handleCollapseFilter={this.handleCollapseFilter}
                                {...this.props}
                            />
                        </div>}
                    </div>
                    <div className="white-box roles-container__data">
                        <div className={'roles-container__data-header'}>
                            <div className={''}>
                                <span className={''}>Danh sách quyền</span>
                                <Badge className={'mg-l-6'} count={Utils.currencyFormat(total)} overflowCount={999}/>
                            </div>
                        </div>
                        <div className={'roles-container__data-list'}>
                            <DataList
                                loading={loading}
                                items={permissions}
                                total={total}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onChangePage={this.onChangePage}
                            />
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default PermissionList;
