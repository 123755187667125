import React from "react";
import UserDomain from "../../Domain/UserDomain";
import {Radio} from "antd";
import SuggestVoucherStatusCriteria from "../../QueryCriteria/SuggestVoucherStatusCriteria";
import VoucherApi from "../../Api/VoucherApi";
import VoucherDomain from "../../Domain/VoucherDomain";

interface Props {
    value?: any,
    onChange?: (value: any) => void,
}

interface State {
    loading: boolean,
    items: Array<UserDomain>
}

class SelectVoucherStatus extends React.Component<Props, State> {

    state = {
        loading: false,
        items: []
    }

    componentDidMount() {
        this.fetchVoucherStatus();
    }

    fetchVoucherStatus = (query: SuggestVoucherStatusCriteria = {}) => {
        this.setState({loading: true});
        VoucherApi.getSuggestVoucherStatus(query)
            .then(response => {
                this.setState({
                    items: response.data
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleSearch = (value: any) => {
        if (value && value.toString().length >= 3) {
            this.fetchVoucherStatus({
                query: value
            })
        } else if (!value) {
            this.fetchVoucherStatus();
        }
    };

    render() {
        const {onChange, value} = this.props;
        const {items} = this.state;
        return (
            <Radio.Group onChange={onChange} value={value}>
                <Radio value={undefined}>Tất cả</Radio>
                 {items.map((item: VoucherDomain) => (
                    <Radio key={item.code} value={item.code}>{item.name}</Radio>
                ))}
            </Radio.Group>
        );
    }
}

export default SelectVoucherStatus;