import {Button, Form, FormInstance, Modal} from "antd";
import {AxiosError, AxiosResponse} from "axios";
import lodash from 'lodash';
import React, {RefObject} from 'react';
import AccountApi from "../../../Api/AccountApi";
import {NotificationCommon} from "../../../Component/Notification";
import SelectSuggestStaff from "../../../Component/SelectSuggestStaff";
import AccountDomain from "../../../Domain/AccountDomain";
import UserDomain from "../../../Domain/UserDomain";

interface Props {
    visible: boolean,
    account: AccountDomain,
    onVisibleChange: (visible: boolean) => void,
    fetchAccountOwners: () => void
}

interface State {
    loading: boolean,
    owners: Array<any>,
}

class AddOwnerModal extends React.Component<Props, State> {

    formRef: RefObject<FormInstance> = React.createRef();

    state: State = {
        loading: false,
        owners: [],
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            this.fetchOwners();
        }
    }

    fetchOwners = () => {
        const {account} = this.props;
        AccountApi.getOwners(account.code)
            .then((response: AxiosResponse) => {
                this.setState({owners: response.data});
            });
    }

    handleSubmit = () => {
        const {account} = this.props;
        this.formRef.current?.validateFields()
            .then(values => {
                this.setState({loading: true});
                AccountApi.addOwner(account.code, values.owners)
                    .then(() => {
                        NotificationCommon.success({
                            message: `Thêm thành công`
                        });

                        this.formRef.current?.resetFields();
                        this.props.onVisibleChange(false);
                        this.props.fetchAccountOwners();
                    })
                    .catch((error: AxiosError) => {
                        if (lodash.get(error.response, 'status') === 400) {
                            if (lodash.get(error.response, 'data.title') === 'privilege_existed') {
                                NotificationCommon.error({
                                    message: 'Chủ sở hữu này đã tồn tại'
                                })
                            } else {
                                NotificationCommon.error({
                                    message: 'Thêm chủ sở hữu thất bại'
                                })
                            }
                        } else {
                            NotificationCommon.error({
                                message: 'Thêm chủ sở hữu thất bại'
                            })
                        }
                    })
                    .finally(() => {
                        this.setState({loading: false});
                    })
            })
            .catch(() => {
                NotificationCommon.error({
                    message: 'Kiểm tra lại thông tin nhập vào'
                })
            })
    }

    handleCancel = () => {
        this.props.onVisibleChange(false);
    }

    exceptAccountOwnerFromStaffs = (staffs: Array<UserDomain>) => {
        const {owners} = this.state;
        const ownerUsernames = lodash.map(owners, item => lodash.get(item, 'owner.username'));
        return staffs.filter((staff: UserDomain) => ownerUsernames.indexOf(staff.username) < 0);
    }

    render() {
        const {visible} = this.props;
        const {loading} = this.state;
        return (
            <Modal
                visible={visible}
                title={'Thêm chủ sở hữu'}
                centered
                destroyOnClose={true}
                onCancel={this.handleCancel}
                footer={null}
            >
                <Form ref={this.formRef!} layout={'vertical'} onFinish={this.handleSubmit}>
                    <Form.Item label={'Nhân viên'} name={'owners'} required={true}
                               rules={[
                                   {required: true, message: 'Nhân viên không được để trống!'}
                               ]}
                    >
                        <SelectSuggestStaff
                            selectProps={{
                                mode: 'multiple',
                                className:'ant-select-multiple',
                                autoFocus: true
                            }}
                            filteredItems={this.exceptAccountOwnerFromStaffs}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {(f) => {
                            return  (<div className={'flex justify-content-flex-end'}>
                                <Button type={'ghost'} className={'mg-r-10'} disabled={loading}
                                        onClick={this.handleCancel}
                                >
                                    Bỏ qua
                                </Button>
                                <Button type={'primary'}
                                        disabled={
                                            !this.formRef?.current?.isFieldsTouched(true)
                                            || !!this.formRef?.current?.getFieldsError().filter(({ errors }) => errors.length).length
                                        }
                                        loading={loading}
                                        onClick={this.handleSubmit}
                                >
                                    Cập nhật
                                </Button>
                            </div>)}
                        }
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default AddOwnerModal;
