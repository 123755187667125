import React from "react";
import {Select, SelectProps} from "antd";
import RoleApi from "../../Api/RoleApi";
import RoleDomain from "../../Domain/RoleDomain";

interface Props {
    value?: any,
    onChange?: (value: any) => void,
    selectProps?: SelectProps<any>
}

interface State {
    loading: boolean,
    items: Array<RoleDomain>
}

class RoleSelector extends React.Component<Props, State> {

    state = {
        loading: false,
        items: []
    }

    componentDidMount() {
        this.fetchRoles();
    }

    fetchRoles = (query: any = {}) => {
        this.setState({loading: true});
        let temp = {
            ...query,
            limit:1000,
            offset:0,
        }
        RoleApi.filter(temp)
            .then(response => {
                this.setState({
                    items: response.data
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleSearch = (value: any) => {
        if (value && value.toString().length >= 2) {
            this.fetchRoles({
                name: value
            })
        }
        else if (!value) {
            this.fetchRoles();
        }
    };

    render() {
        const {onChange, selectProps, value} = this.props;
        const {items} = this.state;
        return (
            <Select
                className={'width-100pc pointer'}
                allowClear
                value={value}
                filterOption={false}
                showSearch={true}
                onChange={onChange}
                onSearch={this.handleSearch}
                placeholder={"Chọn vai trò"}
                {...selectProps}
            >
                {items.map((item: RoleDomain) => (
                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default RoleSelector;
