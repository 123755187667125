import React from 'react';
import QRCode from "qrcode";

interface Props {
    value: string,
    width?: number,
    height?: number,
    className?: string
}

class QrCode extends React.Component<Props, any> {

    static defaultProps: Props = {
        value: "",
        width: 80,
        height: 80,
        className: 'qr-code'
    }

    state = {
        qrCodeDataImg: ""
    }

    componentDidMount() {
        this.generateQrCode();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.value !== this.props.value) {
            this.generateQrCode();
        }
    }

    generateQrCode = () => {
        QRCode.toDataURL(this.props.value, {margin: 0})
            .then(url => {
                this.setState({qrCodeDataImg: url});
            })
    }

    render() {
        const {qrCodeDataImg} = this.state;
        const {width, height, className} = this.props;
        return (
            <img className={className} width={width} height={height} src={qrCodeDataImg} alt="qr-code"/>
        )
    }
}

export default QrCode;
