import apiClient from "../Util/ApiClient";
import SuggestStaffQueryCriteria from "../QueryCriteria/SuggestStaffQueryCriteria";

export default class VoucherApi {

    static getSuggestVoucherStatus(query: SuggestStaffQueryCriteria) {
        return apiClient.get('/voucher-statuses', {
            params: {...query}
        })
    }
}