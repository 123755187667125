import React from 'react'
import appConfig from "../../Config/App";
import {Spin} from "antd";
import localStore from "../../Util/LocalStore";
import Utils from "../../Util/Utils";
import {AppContext} from "../../Context/AppContext";

class Login extends React.Component<any, any> {
    static contextType = AppContext;

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        const redirectBackUrl = Utils.getUrlQueryByName('redirectBackUrl', this.props.location)
        if (redirectBackUrl) {
            localStore.setItem('redirectBackUrl', redirectBackUrl);
        }

        setTimeout(() => {
            const tenant = Utils.getAppContext(this).state.tenant;
            // @ts-ignore
            window.location.href = appConfig.loginUrl + '/' + tenant.code + '?redirect_uri=' + appConfig.authenticationUrl;
        }, 1000);
    }

    render() {
        return (
            <div className={'mg-t-10 mg-l-10'}>Đang chuyển trang, vui lòng đợi một chút.... <Spin /></div>
        )
    }
}

export default Login;

