import React from 'react';
import DefaultLayout from "../Layout/Default";
import Error403 from "../403";

function AccessDenied() {
    return (<>
        <DefaultLayout>
            <Error403/>
        </DefaultLayout>
    </>)
}

export default AccessDenied;
