import { AutoComplete, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form"
import lodash, { get } from "lodash"
import moment from "moment"
import { useRef } from "react"
import CustomerDomain from "../../../Domain/CustomerDomain"
import ProjectDomain from "../../../Domain/ProjectDomain"
import { ACCOUNT_PERMISSIONS, REVIEW_STATUSES_CODE, TRANSACTION_TYPES } from "../../../Util/Constants"
import SecurityService from "../../../Util/SecurityService"

type Props = {
  receiptVoucher: any
  form: any
  accountProjects: any
  loading: boolean
  deliveryNotes: any[]
  handleChangeInput: (...arg: any) => void
  handleSubmit: (...arg: any) => void
  handleChangeProject: (...arg: any) => void
  handleChangeSelect: (...arg: any) => void
  projects: Array<ProjectDomain>
  handleSearchDeliveryNote: (...arg: any) => void
  handleChangePayerType: (...arg: any) => void
  payerType: any
  project: string
  handleSearchStaff: (...arg: any) => void
  customers: any
  handleChangeInputNumber: (...arg: any) => void
  handleChangeDatePicker: (...arg: any) => void
  openModal: boolean
  onCancel: (...arg: any) => void
  onOk: (...arg: any) => void
  disableSubmit: () => boolean
  isEditing: boolean
}

export const ReceiptVoucherEdit: React.FC<Props> = (props) => {
  const { receiptVoucher, form, accountProjects, loading,
    handleChangeInput, handleSubmit, handleChangeProject, handleChangeSelect,
    projects, handleSearchDeliveryNote, deliveryNotes, handleChangePayerType, payerType, project,
    handleSearchStaff, customers, handleChangeInputNumber, handleChangeDatePicker, openModal, onCancel, onOk,
    disableSubmit,
  } = props
  const formRef = useRef<FormInstance>(null)

  return (
    <>
      <Modal
        title={'Chỉnh sửa phiếu thu'}
        centered
        visible={openModal}
        onCancel={onCancel}
        onOk={() => {
          onOk()
        }}
        okText={'Cập Nhật'}
        okButtonProps={{
          disabled: disableSubmit(),
          className: '',
          type: 'primary',
          loading: props.isEditing
        }}
        cancelText={'Huỷ Bỏ'}
        cancelButtonProps={{
          type: 'ghost',
          className: 'opacity-50'
        }}
        width={600}
        destroyOnClose
      >
        <Form labelCol={{ xs: 6, sm: 8 }} labelAlign={"left"} ref={formRef} className={'payment-infos horizontal-form'}>
          <Form.Item label={"Tài khoản quỹ"}>
            <span>
              {get(receiptVoucher, "account.name")} (
              {get(receiptVoucher, "account.code")})
            </span>
          </Form.Item>

          <Form.Item label={"Thời gian tạo"}>
            <span>
              {get(receiptVoucher, "preparedAt")
                ? moment(get(receiptVoucher, "preparedAt")).format(
                  "DD/MM/YYYY HH:mm:ss"
                )
                : "--"}
            </span>
          </Form.Item>

          <Form.Item label={"Người tạo"}>
            <span>
              {get(receiptVoucher, "preparedBy.username")
                ? get(receiptVoucher, "preparedBy.username")
                : "--"}
            </span>
          </Form.Item>

          {get(receiptVoucher, 'reviewStatus') === 'REVIEWED' && <>
            <Form.Item label={"Thời gian duyệt"}>
              <span>
                {get(receiptVoucher, "reviewedAt")
                  ? moment(get(receiptVoucher, "reviewedAt")).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                  : "--"}
              </span>
            </Form.Item>

            <Form.Item label={"Người duyệt"}>
              <span>
                {get(receiptVoucher, "reviewedBy.username")
                  ? get(receiptVoucher, "reviewedBy.username")
                  : "--"}
              </span>
            </Form.Item>
          </>}

          <Form.Item label={"Trạng thái giao dịch"}>
            <span>{receiptVoucher && receiptVoucher.reviewStatus ?
              REVIEW_STATUSES_CODE.find((x) => receiptVoucher.reviewStatus === x.code)?.name : "--"}</span>
          </Form.Item>

          <Form.Item label={"Loại"}>
            <span>{TRANSACTION_TYPES[get(receiptVoucher, "type")] || "--"}</span>
          </Form.Item>

          {
            get(receiptVoucher, "transaction") && <Form.Item label={"Mã giao dịch"}>
              <span>{get(receiptVoucher, "transaction") || "--"}</span>
            </Form.Item>
          }
          {
            get(receiptVoucher, "type") !== 'CUSTOMER' && <Form.Item label={"Mã thanh toán"} required>
              {/* <span>{get(receiptVoucher, "orderCode") || "--"}</span> */}
              <div>
                <Input
                  value={form.orderCode}
                  onChange={handleChangeInput.bind(this, "orderCode")}
                  onPressEnter={() => handleSubmit()}
                  placeholder="Vui lòng nhập mã thanh toán"
                  disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
                />
              </div>
            </Form.Item>
          }

          <Form.Item label={'Hệ thống'}
            required={['DEPOSIT_BY_PAYMENT', 'ORDER'].includes(form.type)}
          >
            <Select
              disabled={!accountProjects.length}
              className={"w-full"}
              placeholder={"Hệ thống"}
              value={loading ? undefined : project}
              allowClear={!['DEPOSIT_BY_PAYMENT', 'ORDER'].includes(form.type as string)}
              onChange={(val) => handleChangeProject(val)}
            >
              {projects
                .filter((item) =>
                  accountProjects.includes(item.code)
                )
                .map((item: ProjectDomain) => (
                  <Select.Option
                    key={item.code}
                    value={item.code}
                  >
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label={"Mã phiếu xuất"}>
            <AutoComplete
              className={"[>.ant-select-selector]:rounded-r-lg"}
              filterOption={false}
              value={form.deliveryNote}
              showSearch={true}
              onChange={handleChangeSelect.bind(this, "deliveryNote")}
              onSearch={lodash.debounce(handleSearchDeliveryNote, 1000)}
              placeholder={'Nhập mã phiếu xuất'}
              options={deliveryNotes.map((ele) => ({
                value: ele.code
              }))}
            />
          </Form.Item>

          {
            form.type === 'CUSTOMER' &&
            <>
              <Form.Item label={"Người nộp tiền"} labelCol={{ span: 6 }}>
                <div className="mg-bt-10 flex items-center ml-4">
                  <Radio
                    disabled={!accountProjects.length || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
                    value={"system"}
                    checked={payerType === "system"}
                    onChange={handleChangePayerType}
                    className="pd-r-6"
                  />

                  <div className="flex1">
                    <Row gutter={12}>
                      <Col span={24}>
                        <AutoComplete
                          disabled={
                            !accountProjects.length ||
                            payerType !== "system"
                          }
                          className={"width-100pc"}
                          placeholder={"Username"}
                          value={form.payerRef}
                          showSearch={true}
                          filterOption={false}
                          allowClear
                          onChange={handleChangeSelect.bind(
                            this,
                            "payerRef"
                          )}
                          onSearch={lodash.debounce(
                            handleSearchStaff,
                            500
                          )}
                        >
                          {customers.map((item: CustomerDomain) => (
                            <AutoComplete.Option
                              key={item.username}
                              value={item.username}
                            >
                              {item.fullname} ({item.username})
                            </AutoComplete.Option>
                          ))}
                        </AutoComplete>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="flex items-center relative  ml-4">
                  <Radio
                    value={"person"}
                    checked={payerType === "person"}
                    onChange={handleChangePayerType}
                    disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
                    className="pd-r-6"
                  />

                  <div className="flex1">
                    <Input
                      disabled={payerType !== "person" || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
                      placeholder={"Người nộp tiền"}
                      value={form.payer}
                      onChange={handleChangeInput.bind(this, "payer")}
                      onPressEnter={() => handleSubmit()}
                    />
                  </div>
                </div>
              </Form.Item>
            </>
          }

          <Form.Item
            label={"Số tiền"}
            // name="amount"\
            required
            rules={[{ required: true, message: "Số tiền không được để trống" }]}
          >
            <InputNumber
              placeholder={"Vui lòng nhập số tiền"}
              value={form.amount}
              onChange={handleChangeInputNumber.bind(this, "amount")}
              formatter={(value) => {
                if (!value) {
                  return ''
                }
                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              className={"width-100pc"}
              disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
            />
          </Form.Item>

          <Form.Item
            label={"Nội dung giao dịch"}
            // name="memo"
            required
            rules={[
              {
                required: true,
                message: "Nội dung giao dịch không được để trống",
              },
            ]}
          >
            <Input
              value={form.memo}
              onChange={handleChangeInput.bind(this, "memo")}
              onPressEnter={() => handleSubmit()}
              placeholder="Vui lòng nhập nội dung giao dịch"
              disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
            />
          </Form.Item>

          <Form.Item label={"Ghi chú"}>
            <Input
              value={form.remark}
              onChange={handleChangeInput.bind(this, "remark")}
              placeholder="Vui lòng nhập ghi chú"
              disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
            />
          </Form.Item>

          <Form.Item label={"Thời gian giao dịch"}>
            <DatePicker
              className={"width-100pc"}
              format={"DD/MM/YYYY HH:mm:ss"}
              value={form.timestamp ? moment(form.timestamp) : null}
              onChange={handleChangeDatePicker.bind(this, "timestamp")}
              disabledDate={(current) => current && current > moment()}
              showTime
              placeholder="Vui lòng chọn thời gian giao dịch"
              disabled={!SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
            />
          </Form.Item>

          <Form.Item label={"Mã bút toán"}>
            <Input
              disabled={get(receiptVoucher, "account.type") !== "BANK" || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE)}
              min={0}
              className={"width-100pc"}
              value={form.ref}
              placeholder="Vui lòng nhập mã bút toán"
              onChange={handleChangeInput.bind(this, "ref")}
            />
          </Form.Item>
        </Form>
      </Modal>

    </>
  )

}