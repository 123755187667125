import React from "react";
import {Badge, Button, Card, Form, Input, InputNumber, Modal, Radio} from "antd";
import TransactionsDomain from "../../Domain/TransactionDomain";
import {SubTransactionCommand} from "../../Command/SubTransactionCommand";
import Utils from "../../Util/Utils";
import {AppContext} from "../../Context/AppContext";
import lodash from "lodash";
import SelectPaymentReason from "../SelectPaymentReason";
import SubTransaction from "../../Api/SubTransaction";
import {NotificationCommon} from "../Notification";

type SubCreateProps = {
    visible: boolean;
    rootTransaction: TransactionsDomain;
    handleModalCreateSubTransactionVisibleChange: (visible: boolean) => void;
    editSubTransactions?: SubTransactionCommand[];
    fetchTransactions?: (filter?: any) => void;
    fetchSubTransaction?:() => void;
}

function SubTransactionCreate(props: SubCreateProps) {
    const {
        visible, rootTransaction, handleModalCreateSubTransactionVisibleChange, editSubTransactions, fetchTransactions,fetchSubTransaction
    } = props;
    const [formData] = Form.useForm();
    const [formList, setFormList] = React.useState<SubTransactionCommand[]>([]);
    const [total, setTotal] = React.useState<number>(0)
    const account = React.useContext(AppContext);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (editSubTransactions) {
            let tempList = editSubTransactions.map((ele) => {
                return {
                    code: ele.code,
                    amount: ele.amount,
                    paymentReason: lodash.get(ele, 'paymentReason.code'),
                    remark: ele.remark,
                    memo: ele.memo,
                    type: ele.type,
                }
            })
            setFormList(tempList)
        } else {
            setFormList([
                {
                    amount: 0,
                    memo: '',
                    remark: '',
                    paymentReason: undefined,
                    type: rootTransaction.type
                },
                {
                    amount: 0,
                    memo: '',
                    remark: '',
                    paymentReason: undefined,
                    type: rootTransaction.type
                }])
        }

        if (rootTransaction && typeof rootTransaction.amount === 'number') {
            setTotal(rootTransaction.amount)
        }
    }, [rootTransaction, editSubTransactions])


    const handleCancelModal = () => {
        setFormList([]);
        handleModalCreateSubTransactionVisibleChange(false);
    }

    const renderMoreSubTransactionForm = () => {
        let temp = [...formList];
        temp.push({
            amount: 0,
            memo: '',
            remark: '',
            paymentReason: undefined,
            type: rootTransaction.type
        });
        setFormList(temp);
    }

    const handleDeleteSubTransactionForm = (index: number) => {
        let temp = [...formList];
        temp.splice(index, 1);
        setFormList(temp)
    }

    const getElementByIndex = (idx: number, ppt?: string) => {
        let ele: any = formList.find((value, index) => index === idx);
        if (ele) {
            if (ppt) return ele[ppt]
            else return ele
        }
    }

    const handleChangeInputValue = (idx: number, ppt: string, value: any) => {
        let subData = getElementByIndex(idx);
        subData[ppt] = value;
        if(ppt === 'amount' || ppt === 'type') {
            if (subData['type'] === 'CREDIT') {
                subData['amount'] = Math.abs(subData['amount'])
            } else if (subData['type'] === 'DEBIT') {
                subData['amount'] = - subData['amount']
            }
        }
        if(subData['type'] === 'CREDIT') {
            lodash.unset(subData,'paymentReason')
        }
        let formListTemp = [...formList];
        formListTemp.splice(idx, 1, subData);
        setFormList(formListTemp);
    }

    const handleSubmit = () => {
        let formListTemp = formList.map((f) => {
            return {
                ...f,
                amount: Math.abs(f.amount),
            }
        });
        setSubmitting(true);
        if (rootTransaction) {
            SubTransaction.createSubTransactions(rootTransaction.code, formListTemp).then(() => {
                NotificationCommon.success({
                    message: !editSubTransactions ? 'Tạo giao dịch con thành công' : 'Sửa giao dịch con thành công',
                });
                if (fetchTransactions) fetchTransactions(Utils.getQueryFromLocation(window.location));
                if (fetchSubTransaction) fetchSubTransaction();
                handleCancelModal();
            })
                .catch((err) => {
                    let errorMessage = !editSubTransactions ? "Tạo giao dịch con thất bại!" : 'Sửa giao dịch con thất bại'
                    if (err.response) {
                        switch (lodash.get(err.response,'data.status')) {
                            case 403 : errorMessage = 'Bạn không có quyền thực hiện thao tác này';break;
                        }
                        switch (lodash.get(err.response, 'data.title')) {
                            case 'total_amount_invalid' :
                                errorMessage = 'Tổng tiền giao dịch con không lệ';break;
                            case 'transaction_rejected' :
                                errorMessage = 'Giao dịch đã bị hủy. Vui lòng kiểm tra lại';break;
                            default:
                                break;
                        }
                    }
                    NotificationCommon.error({
                        message: errorMessage
                    })
                })
                .finally(() => setSubmitting(false))
        }
    }

    return (
        <Modal
            title={<div className={'space-between'}>
                <span>Tạo Giao Dịch Con</span>
                <span className={'font-size-12'}>
                    Tổng tiền:<span
                    className={`font-size-16 mg-l-2 ${total > 0 ? 'positive-money' : 'negative-money'}`}>{Utils.currencyFormatByCurrency(total, lodash.get(account.state.account, 'currency.code'))}</span>
                </span>

            </div>}
            visible={visible}
            onCancel={handleCancelModal}
            closeIcon={<i className={'fa-solid fa-xmark'}/>}
            className={'sub-transaction-form'}
            footer={<div className={'width-100pc space-between'}>
                <Button
                    type={'primary'}
                    className={'width-50pc'}
                    onClick={renderMoreSubTransactionForm}
                    disabled={submitting}
                >
                    <i className={'fa-regular fa-plus mg-r-4'}/>
                    Thêm giao dịch con
                </Button>
                <Button style={{width: '49%'}}
                        disabled={
                            formList.reduce((accumulator, object) => {
                                return accumulator + object.amount;
                            }, 0) !== total
                        }
                        loading={submitting}
                        onClick={handleSubmit}
                >
                    <i className={'fa-regular fa-check mg-r-4'}/>
                    Xác nhận
                </Button>
            </div>}
            centered
            destroyOnClose
        >
            <Form form={formData}
                  autoComplete="on"
                  layout={'horizontal'}
                  labelCol={{xs: 8, lg: 5}}
                  labelAlign={'left'}
            >
                {formList.map((f, index) => {
                    return (<div key={index}>
                        <Card
                            title={<div><span>Giao Dịch Con: </span><span className={'mg-l-4'}><Badge
                                count={index + 1}/></span></div>}
                            extra={formList.length > 2 && <Button type={'link'} onClick={() => handleDeleteSubTransactionForm(index)}><i className={'fa-regular fa-trash-can'}/></Button>}
                        >
                            <Form.Item label={'Loại'}>
                                <Radio.Group value={f.type}
                                             onChange={(e) => handleChangeInputValue(index, 'type', e.target.value)}>
                                    <Radio value={'CREDIT'}>Cộng tiền</Radio>
                                    <Radio value={'DEBIT'}>Trừ tiền</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label={'Số tiền'} required={true}>
                                <InputNumber
                                    autoFocus={index === 0}
                                    min={0}
                                    className={'width-100pc'}
                                    controls={false}
                                    required
                                    value={Math.abs(getElementByIndex(index, 'amount'))}
                                    placeholder={'Nhập số tiền giao dịch'}
                                    onChange={(value) => {
                                        handleChangeInputValue(index, 'amount', Math.abs(value))
                                    }}
                                    formatter={(value) => {
                                        let currency = lodash.get(account.state, 'account.currency.code')
                                        if (!value) {
                                            return ''
                                        }
                                        return currency === "VND"
                                            ? Utils.currencyFormat(value, "0,0")
                                            : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                    addonAfter={lodash.get(account.state.account, 'currency.code')}

                                />
                            </Form.Item>
                            <Form.Item label={'Nội dung'}>
                                <Input.TextArea
                                    rows={1}
                                    onChange={(e) => {
                                        handleChangeInputValue(index, 'memo', e.target.value)
                                    }}
                                    value={getElementByIndex(index, 'memo')}
                                    placeholder={'Nhập nội dung giao dịch'}
                                />
                            </Form.Item>

                            {(f.type === 'DEBIT') && <Form.Item label={'Mục đích chi'}>
                                <SelectPaymentReason
                                    value={getElementByIndex(index, 'paymentReason')}
                                    onChange={(value) => handleChangeInputValue(index, 'paymentReason', value)}
                                    selectProps={{
                                        placeholder: 'Chọn mục đích chi',
                                    }}
                                />
                            </Form.Item>}

                            <Form.Item label={'Ghi chú'}>
                                <Input.TextArea
                                    rows={1}
                                    onChange={(e) => {
                                        handleChangeInputValue(index, 'remark', e.target.value)
                                    }}
                                    value={getElementByIndex(index, 'remark')}
                                    placeholder={'Nhập ghi chú'}
                                />
                            </Form.Item>
                        </Card>
                    </div>)
                })}
            </Form>
        </Modal>
    )
}

export default SubTransactionCreate;
