export enum LogResrouceType {
    RECEIPT_VOUCHER = "receipt_voucher",
    PAYMENT_VOUCHER = "payment_voucher",
    ACOUNT = "account",
    TRANSACTION = "transaction",
    SMS = "sms",
    MEMO = "memo_mapping",
}

export enum ReceiptVoucherEvent {
    CREATE = "CREATE_RECEIPT_VOUCHER",
    UPDATE = "UPDATE_RECEIPT_VOUCHER",
    APPROVED = "APPROVED_RECEIPT_VOUCHER",
    REVIEWED = "REVIEWED_RECEIPT_VOUCHER",
    REJECTED = "REJECTED_RECEIPT_VOUCHER",
    UPDATE_PAYER = "UPDATE_PAYER_RECEIPT_VOUCHER",
}

export enum PaymentVoucherEvent {
    CREATE = "CREATE_PAYMENT_VOUCHER",
    UPDATE = "UPDATE_PAYMENT_VOUCHER",
    APPROVED = "APPROVED_PAYMENT_VOUCHER",
    REVIEWED = "REVIEWED_PAYMENT_VOUCHER",
    REJECTED = "REJECTED_PAYMENT_VOUCHER",
    UPDATE_RECEIVER = "UPDATE_RECEIVER_PAYMENT_VOUCHER",
}

export enum TransactionEvent {
    INITIAL = "INITIAL_TRANSACTION",
    DEBIT = "DEBIT_TRANSACTION",
    CREDIT = "CREDIT_TRANSACTION",
    UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT_TRANSACTION",
    UPDATE_REMARK = "UPDATE_REMARK_TRANSACTION",
    SYNCHRONIZE = "SYNCHRONIZE_TRANSACTION",
    REVIEWED = "REVIEWED_TRANSACTION",
    TRANSACTION_REJECTED = 'TRANSACTION_REJECTED'
}

export enum MessageEvent {
    CREATE = "CREATE_MESSAGE",
    ANALYSIS = "ANALYSIS_MESSAGE",
    UPDATE_REMARK = "UPDATE_REMARK_MESSAGE",
}

export enum MemoEvent {
    DELETE = "DELETE_MEMO_MAPPING",
    CREATE = "CREATE_MEMO_MAPPING"
}

export enum AccountEvent {
    CREATE = "CREATE_ACCOUNT",
    UPDATE = "UPDATE_ACCOUNT",
    GRANT_PROJECT = "GRANT_PROJECT_ACCOUNT", // gán project vào quỹ
    REVOKE_PROJECT = "REVOKE_PROJECT_ACCOUNT", // Bỏ gán project
    ENABLE_DEBIT_TRANSACTION = "ENABLE_DEBIT_TRANSACTION_IN_ACCOUNT", // cho phép bật tắt nhận giao dịch chi tiền từ SMS
    TRANSACTION_EXPIRE_CONFIG = "TRANSACTION_EXPIRE_CONFIG_IN_ACCOUNT", // cấu hình kiểm tra trùng mã bút toán trong 1 khoảng thời gian
    GRANT_ACCOUNT_OWNER = "GRANT_ACCOUNT_OWNER", // Gán chủ quỹ
    REVOKE_ACCOUNT_OWNER = "REVOKE_ACCOUNT_OWNER", // Bỏ gán
    CHANGE_ACCOUNT_OWNER = "CHANGE_ACCOUNT_OWNER", // thay đổi
    GRANT_ROLE_STAFF_ACCOUNT_OWNER = "GRANT_ROLE_STAFF_ACCOUNT_OWNER", // thêm nhân viên với quyền gì đó trong quỹ
    REVOKE_ROLE_STAFF_ACCOUNT_OWNER = "REVOKE_ROLE_STAFF_ACCOUNT_OWNER", // xóa
    CHANGE_ROLE_STAFF_ACCOUNT_OWNER = "CHANGE_ROLE_STAFF_ACCOUNT_OWNER", // thay đổi
    SETTLEMENT = "SETTLEMENT_ACCOUNT", //CHốt sổ
    CHANGE_AVAILABLE_BALANCE = "CHANGE_AVAILABLE_BALANCE_ACCOUNT", // thay đổi số dư khả dụng của quỹ
    CHANGE_PENDING_AMOUNT = "CHANGE_PENDING_AMOUNT_ACCOUNT", // Thay đổi số dư khi xác nhận
    CHANGE_REVIEW_PENDING_AMOUNT = "CHANGE_REVIEW_PENDING_AMOUNT_ACCOUNT" // Thay đổi số dư chờ khi duyệt phiếu
}

export interface ChangedValuesInterface {
    key: string
    left: any
    right: any
}

export interface LogInterface<EventType = string, Details = {}> {
    id: string
    application: string
    namespace: string
    module: string
    tenant: string
    subTenant: string
    activity: EventType
    timestamp: string
    actor: {
        grn: string
        username: string
        namespace: string
        module: string
        role: string
        tenant: string
        ip: string
        agent: string
        metadatda: {}
    }
    tracing: {
        tracer: string
        traceId: string
        spanId: string
    }
    change: {
        type: "NEW_OBJECT"
        newObject: [Details]
        removedObject: [{}]
        valueChange: {
            changedValues: ChangedValuesInterface[]
        }
        mapChange: {
            addedEntries: [
                {
                    key: string
                    value: string
                }
            ]
            removedEntries: [
                {
                    key: string
                    value: string
                }
            ]
            changedEntries: [
                {
                    key: string
                    left: {}
                    right: {}
                }
            ]
        }
        containerChange: {
            key: string
            addedElements: [
                {
                    value: string
                }
            ]
            removedElements: [
                {
                    value: string
                }
            ]
        }
    }
    target: {
        id: string
        grn: string
        metadata: Details
    }
}
