import React from 'react';
import {Button, Col, Row, Statistic, Tooltip} from "antd";
import Utils from "../../Util/Utils";
import lodash from "lodash";
import AccountDomain from "../../Domain/AccountDomain";
import {AppContext} from "../../Context/AppContext";

interface Props {
    account: AccountDomain
}

class AccountBoxStatistic extends React.Component<Props, any> {

    static contextType = AppContext

    handleRefresh = () => {
        Utils.getAppContext(this).func.fetchAccountAndUpdateState(this.props.account.code)
    }

    render(): any {
        const account = this.props.account || {};
        const currency = lodash.get(account, 'currency.code');
        const {loading} = Utils.getAppContext(this).state;
        
        return (
            <>
            <div className={"font-medium mg-bt-20 fsz-16px capitalize md-space-between"}>
                Thông tin tài chính
                <Tooltip title={'Làm mới'}>
                    <Button type='link' onClick={this.handleRefresh} className="pd-l-7"><i className="fa-solid fa-rotate-right" /></Button>
                </Tooltip>
            </div>
                    <Row gutter={20} className={'box-account-total-amount'}>
                        <Col xs={12} lg={6} className="border-left">
                                <Statistic
                                    title={`Số dư khả dụng (${currency})`}
                                    valueRender={() => {
                                        const amount = lodash.get(account, 'availableBalance') || 0;
                                        return <span className={amount < 0 ? 'negative-money font-medium word-break' : 'positive-money font-medium word-break'}>
                                                    {Utils.currencyFormatByCurrency(amount, currency)}
                                                </span>
                                    }}
                                    loading={loading}
                                />
                        </Col>

                        <Col xs={12} lg={6} className="border-left">
                                <Statistic
                                    title={`Số dư dự kiến (${currency})`}
                                    valueRender={() => {
                                        const amount = lodash.get(account, 'pendingAmount') + lodash.get(account,'availableBalance');
                                        return <span className={amount < 0 ? 'negative-money font-medium word-break' : 'positive-money font-medium word-break'}>
                                                    {Utils.currencyFormatByCurrency(amount, currency)}
                                                </span>
                                    }}
                                loading={loading}
                                />
                        </Col>

                        <Col xs={12} lg={6} className="border-left">
                            <Statistic
                                title={`Tổng thu chờ xác nhận (${currency})`}
                                valueRender={() => {
                                    const amount = lodash.get(account, 'pendingReceiptAmount') || 0;
                                    return <span className={'positive-money font-medium word-break'}>
                                                {Utils.currencyFormatByCurrency(amount, currency)}
                                        </span>
                                }}
                            loading={loading}
                            />
                        </Col>

                        <Col xs={12} lg={6}>
                            <Statistic
                                title={`Tổng chi chờ xác nhận (${currency})`}
                                valueRender={() => {
                                    const amount = lodash.get(account, 'pendingPaymentAmount');
                                    return <span className={'negative-money font-medium word-break'}>
                                            {Utils.currencyFormatByCurrency(amount, currency)}
                                        </span>
                                }}
                                loading={loading}
                            />
                        </Col>
                    </Row>
            </>
        );
    }
}

export default AccountBoxStatistic;
