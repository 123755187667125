import {Button, Form, Modal, Select} from "antd";
import React, {useEffect, useState} from "react";
import ProjectApi from "../../../Api/ProjectApi";
import ProjectDomain from "../../../Domain/ProjectDomain";
import AccountDomain from "../../../Domain/AccountDomain";
import AccountApi from "../../../Api/AccountApi";
import {events} from "../../../Util/Event";
import {ACCOUNT_EVENT} from "../../../Util/Constants";
import lodash from "lodash";
import { NotificationCommon } from "../../../Component/Notification";

interface Props {
    account: AccountDomain
    onVisibleChange: (visible: boolean) => void
}

export const AddProjectModal = (props: Props) => {
    const {account} = props
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState<Array<ProjectDomain>>([])
    const [form] = Form.useForm()
    const accountProjects = account.projects || []

    const fetchProjects = () => {
        setLoading(true)
        ProjectApi.filter({limit: 100})
            .then(response => {
                setProjects(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchProjects()
    }, [])

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                setLoading(true)
                return AccountApi.addProjects(account.code, values.projects)
            })
            .then(() => {
                NotificationCommon.success({
                    message: 'Thêm dự án thành công'
                })

                props.onVisibleChange(false)

                events.emit(ACCOUNT_EVENT.ADD_PROJECT)

            })
            .catch((error) => {
                if (error.errorFields) {
                    NotificationCommon.error({
                        message: 'Kiểm tra lại thông tin trên form'
                    })
                }
                else if (lodash.get(error, 'response.status') === 400) {
                    NotificationCommon.error({
                        message: lodash.get(error, 'response.data.title')
                    })
                }
            })
    }

    return (
        <Modal
            closeIcon={<i className="fa-solid fa-xmark"  />}
            title={'Thêm hệ thống'}
            visible={true}
            onCancel={() => props.onVisibleChange(false)}
            centered
            footer={null}
        >
            <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
                <Form.Item label={'Hệ thống'} required={true} name={'projects'} rules={[
                    {required: true, message: "Hệ thống không được để trống!"}
                ]}>
                    <Select
                        allowClear={true}
                        placeholder={'Chọn hệ thống'}
                        mode={'multiple'}
                        showSearch={false}
                        autoFocus
                    >
                        {projects.filter(item => !accountProjects.includes(item.code)).map(item => (
                            <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate>
                    {(f) => {
                        return  (<div className={'flex justify-content-flex-end'}>
                            <Button type={'ghost'} className={'mg-r-10'} disabled={loading}
                                    onClick={() => props.onVisibleChange(false)}
                            >
                                Bỏ qua
                            </Button>
                            <Button type={'primary'}
                                    disabled={
                                        !form.isFieldsTouched(true)
                                        || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                    loading={loading}
                                    onClick={handleSubmit}
                            >
                                Cập nhật
                            </Button>
                        </div>)}
                    }
                </Form.Item>
            </Form>
        </Modal>
    )
}
