import { ConfigProvider, Empty, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { FC } from 'react'
import SkeletonTable from './SkeletonTable'
import emptyImage from '../../resources/images/empty.png'

interface IProps extends TableProps<any> {
    columns: ColumnsType<any>
    columnScroll?: number
    emptyText?: string
}

const CommonTable: FC<IProps> = ({ pagination, loading, scroll, columnScroll = 3, emptyText, ...props }) => {
    if (loading) {
        return <SkeletonTable columns={props.columns} />
    }

    const scrollDefault = props.columns.length > columnScroll ? { x: 1167 } : undefined

    return (
        <ConfigProvider
            renderEmpty={() => (
                <Empty
                    image={emptyImage}
                    description={emptyText}
                />
            )}>
            <Table
                scroll={scroll || scrollDefault}
                tableLayout='auto'
                pagination={
                    !!pagination
                        ? {
                              ...pagination,
                              hideOnSinglePage: false,
                              total: Number(pagination.total),
                              pageSize: Number(pagination.pageSize),
                              pageSizeOptions: [10, 20, 25, 50, 100],
                              showSizeChanger: true
                          }
                        : undefined
                }
                {...props}
                // rowKey={() => uniqueId()}
            />
        </ConfigProvider>
    )
}

export default CommonTable

CommonTable.defaultProps = {
    emptyText: 'Bảng đang trống',
}
