export default interface TransactionsDomain {
    transactionType?: string | any
    code: string
    paymentVoucher?: any
    timestamp?: string
    approvedAt?: any
    amount?: string | number
    preparedBy?: any
    approvedBy?: any
    receiptVoucher?: any | undefined
    participant?: string
    participantRef?: any
    remark?: string
    memo?: string
    project?: string
    type?: string
    hasSubTransaction?: boolean
    reviewStatus?: any
    requestResponse?: any
    orderCode?: any
}

export const TransactionDomainDefault: TransactionsDomain = {
    code: '',
}

export enum TransactionStatusEnum {
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
}

export enum TransactionReviewStatusEnum {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
}

export interface TransactionInterface {
    id: null
    code: string
    tenant: string
    account: string
    amount: number
    memo: string
    remark: null | string
    balance: number
    timestamp: string
    receiptVoucher: string | null
    paymentVoucher: string
    preparedBy: {
        id: null
        tenant: null
        username: string
        name: null
        email: null
        mobile: null
        dob: null
        gender: null
        avatar: null
        createdAt: null
        createdBy: null
        modifiedAt: null
        modifiedBy: null
        version: 0
    }
    preparedAt: string
    approvedBy: {
        id: null
        tenant: null
        username: string
        name: null
        email: null
        mobile: null
        dob: null
        gender: null
        avatar: null
        createdAt: null
        createdBy: null
        modifiedAt: null
        modifiedBy: null
        version: number
    }
    approvedAt: string
    approvedRemark: null
    status: TransactionStatusEnum
    type: string
    project: null
    participant: string
    participantRef: null
    ref: null
    refBalance: null
    settledBalance: null
    source: null
    referenceTx: null
    paymentReason: {
        id: string
        tenant: string
        code: string
        name: string
        description: string
        invoiceTemplate: null
        invoiceExample: null
        createdAt: string
        createdBy: string
        modifiedAt: string
        modifiedBy: string
        version: 4
    }
    invoice: null
    reviewStatus: string
    reviewedAt: null
    reviewedBy: {
        id: null
        tenant: null
        username: null
        name: null
        email: null
        mobile: null
        dob: null
        gender: null
        avatar: null
        createdAt: null
        createdBy: null
        modifiedAt: null
        modifiedBy: null
        version: 0
    }
    parent: null
    recharged: false
    hasSubTransaction: false
    txLog: {
        id: string
        tenant: string
        tx: string
        module: null
        ref: null
        project: null
        customer: null
        timestamp: null
        requestData: null
        requestResponse: null
        status: string
        reason: string
        createdAt: string
        createdBy: null
        modifiedAt: string
        modifiedBy: null
        version: null
    }
}
