import {FC} from "react";
import {Button, Dropdown, Menu} from "antd";
import {Link} from "react-router-dom";
import localStore from "../../../Util/LocalStore";
import AccountDomain from "../../../Domain/AccountDomain";
import SecurityService from "../../../Util/SecurityService";
import {ACCOUNT_PERMISSIONS} from "../../../Util/Constants";

interface LinkDropdownProps {
    account: AccountDomain
}

const addCodeToQuery = (page: string, code: string) => `${page}?accountCode=${code}`

const LinkDropdown: FC<LinkDropdownProps> = ({account}) => {
    const goPage = (page: string) => {
        localStore.setJson('account', account);
        window.location.href = page;
    }

    return (
        <Dropdown overlay={() => (
            <Menu>
                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_VIEW) && <Menu.Item key={"link-1"} onClick={() => goPage('transactions')}>
                    <Link to={addCodeToQuery('transactions', account.code)}>
                        <i className="fa-solid fa-money-check-pen"/>
                        Đến lịch sử giao dịch
                    </Link>
                </Menu.Item>}

                <Menu.Item key={"link-2"} onClick={() => goPage('pending-vouchers')}>
                    <Link to={addCodeToQuery('pending-vouchers', account.code)}>
                        <i className="fa-solid fa-memo-circle-info"/>
                        Đến phiếu chờ giao dịch
                    </Link>
                </Menu.Item>

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_VIEW) && <Menu.Item key={"link-3"} onClick={() => goPage('payment-vouchers')}>
                    <Link to={addCodeToQuery('payment-vouchers', account.code)}>
                        <i className="fa-solid fa-receipt"/> Đến Phiếu Chi
                    </Link>
                </Menu.Item>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_VIEW) && <Menu.Item key={"link-4"} onClick={() => goPage('receipt-vouchers')}>
                    <Link to={addCodeToQuery('receipt-vouchers', account.code)}>
                        <i className="fa-solid fa-scroll"/> Đến phiếu thu
                    </Link>
                </Menu.Item>}

            </Menu>
        )} placement="bottomRight">
            <Button type={"text"}><i className="fa-solid fa-ellipsis-vertical"/></Button>
        </Dropdown>
    )
}

export {LinkDropdown}
