import AccountDomain from './AccountDomain'
import CurrencyDomain from './CurrencyDomain'
import UserDomain from './UserDomain'

export default interface ReceiptVoucherDomain {
    code: string
    createdAt: string
    timestamp: string
    amount: number
    memo: string
    approvedBy: string
    preparedBy: string
    payer: string
    payerRef?: any
    approvedAt: string
    transactionAt: string
}

export const ReceiptVoucherModel: ReceiptVoucherDomain = {
    code: '',
    timestamp: '',
    createdAt: '',
    amount: 0,
    memo: '',
    approvedBy: '',
    preparedBy: '',
    payer: '',
    payerRef: '',
    approvedAt: '',
    transactionAt: '',
}

export interface StatusDetail {
    id: string
    code: string
    tenant: string
    name: string
    description: string
    createdAt: null
    createdBy: null
    modifiedAt: null
    modifiedBy: null
    version: null
}

export interface ReceiptVoucherDetails {
    deliveryNote: any
    orderCode: string | number | readonly string[] | undefined
    type: any
    id: string
    code: string
    ref: string | undefined
    tenant: string
    account: AccountDomain
    currency: CurrencyDomain
    amount: 12
    timestamp: string | undefined
    memo: string
    remark: string | undefined
    project: string
    payer: string | undefined
    status: StatusDetail
    payerRef: UserDomain
    preparedBy: UserDomain
    preparedAt: string
    approvedBy: UserDomain
    approvedAt: null
    rejectedBy: UserDomain
    rejectedAt: string
    transaction: null
    source: null
    reviewStatus: string
    reviewedAt: null
    reviewedBy: UserDomain
    createdAt: string
    createdBy: string
    modifiedAt: string
    modifiedBy: string
    version: null
}
