import { FC, memo } from 'react'
import { LogItemInterface } from '../../../Component/LogModal/type'
import { LogInterface, MemoEvent } from '../../../Interface/LogInterface'
import MemoDomain from '../../../Domain/MemoDomain'

interface ListItemProps extends LogItemInterface<LogInterface<MemoEvent, MemoDomain>> {}

const ListItem: FC<ListItemProps> = memo(({ item, changedValues, newObject, removedObject }: ListItemProps) => {
    return (
        <div>
            {
                {
                    [MemoEvent.CREATE]: (
                        <>
                            Cấu hình cú pháp <span className="font-medium">{newObject.placeholder}</span> trên hệ thống{' '}
                            <span className="font-medium">{newObject.project}</span>
                        </>
                    ),
                    [MemoEvent.DELETE]: (
                        <>
                            Xóa cú pháp <span className="font-medium">{removedObject.placeholder}</span> trên hệ thống{' '}
                            <span className="font-medium">{removedObject.project}</span>
                        </>
                    ),
                }[item.activity]
            }
        </div>
    )
})

export { ListItem }
