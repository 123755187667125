import React, {useState} from "react";
import {Tooltip} from "antd";
import {useHistory} from "react-router-dom";
import lodash, {get} from "lodash";
import moment from "moment";
import AccountDomain, {AccountModel} from "../../../Domain/AccountDomain";
import SettlementModal from "./SettlementModal";
import {AxiosError} from "axios";
import {AccountViewDomain} from "../../../Domain/AccountViewDomain";
import {NotificationCommon} from "../../../Component/Notification";
import CommonTable from "../../../Component/CommonTable";
import {LinkDropdown} from "./LinkDropdown";
import {ColumnsType} from "antd/lib/table";
import localStore from "../../../Util/LocalStore";
import AccountApi from "../../../Api/AccountApi";
import {ACCOUNT_PERMISSIONS, PERMISSIONS} from "../../../Util/Constants";
import SecurityService from "../../../Util/SecurityService";

type Props = {
    dataSources: AccountViewDomain[]
    pagination: any,
    onChangePage: (page: number, pageSize: number) => void;
    showAccount: (code: string) => void,
    loading: boolean,
    handleCheckingSettlementPermission:(accountCode:string,permission:string,cb:() => void | any) => void,
    showModalClone: (initialValues: AccountDomain) => void
}

function DataList(props: Props) {
    const [settlementVisible, setSettlementVisible] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({...AccountModel});
    const history = useHistory();

    const showSettlementModal = (account: any) => {
        handleSettlementModalVisible(true);
        setSelectedAccount(account);
    }

    const handleSettlementModalVisible = (visible: boolean) => {
        setSettlementVisible(visible);
    }

    const handleSettlementSuccess = () => {
        NotificationCommon.success({
            message: "Chốt sổ thành công"
        });
        setSettlementVisible(false);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    const handleSettlementFail = (error: AxiosError) => {
        if (get(error.response, 'status') === 400) {
            if (get(error.response, 'data.title') === 'voucher_status_ineligible') {
                NotificationCommon.error({
                    message: `${get(error.response, 'data.receiptVoucher')} phiếu thu, ${get(error.response, 'data.paymentVoucher')} phiếu chi cần phải được xác nhận hoặc huỷ hết trước khi chốt sổ`
                })
            } else if (get(error.response, 'data.title') === 'timestamp_invalid') {
                NotificationCommon.error({
                    message: 'Thời gian chốt không hợp lệ'
                })
            } else if (get(error.response, 'data.title') === 'settlement_at_ineligible') {
                NotificationCommon.error({
                    message: `Thời gian chốt sổ cần lớn hơn ${moment(lodash.get(selectedAccount, 'lastSettlementAt', '')).format("DD/MM/YYYY HH:mm")}`
                })
            } else if (get(error.response,'data.violations').length > 0) {
                NotificationCommon.error({
                    message:"Không được để trống nội dung"
                })
            }
            else {
                NotificationCommon.error({
                    message: error.message
                })
            }
        } else {
            NotificationCommon.error({
                message: error.message
            })
        }
    }

    const {dataSources, pagination,handleCheckingSettlementPermission} = props;

    const columns: ColumnsType<any> = [
        {
            title: 'Tài khoản',
            key: 'code',
            render: (row: any) => {
                return <>
                    <div className={"account-field"}>
                        <div className={(row.status === "ACTIVE" ? "icon-active" : "icon-inactive") + " self-center"}/>
                        <div className={"name"} onClick={(e)=> {
                            e.stopPropagation();
                            handleRouteToAccountDetail(row.code)
                        }}>
                            <Tooltip placement={'top'} arrowPointAtCenter title={row.code}>
                                <div className={"font-size-14 text-maxW-250 mg-r-6"}>
                                    <span className={'link-span text-blue'}>{row.code}</span>
                                </div>
                            </Tooltip>
                            <span className={'divine'}/>
                            <Tooltip placement={'top'} arrowPointAtCenter title={row.name}>
                                <div className={"font-size-14 text-maxW-250"}>
                                    <span className={'link-span text-blue'}>{row.name}</span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </>
            }
        },
        {
            title: 'Ngân hàng',
            key: 'bank',
            render: (row: any) => {
                return <>
                    <div className={'bank-field'}>
                        <span className={'capitalize'}>{lodash.get(row, 'bank.code', '') || '---'}</span>
                        <Tooltip placement="top" title={lodash.get(row, 'bank.name')}>
                            <span
                                className={"font-size-14 text-maxW-250"}>{lodash.get(row, 'bank.name') || '---'}</span>
                        </Tooltip>
                    </div>
                </>
            }
        },
        {
            title: 'Số tài khoản',
            key: 'accountNumber',
            render: (row: any) => {
                return <>
                    <span>{row.accountNumber ? lodash.get(row, 'accountNumber', '') : '---'}</span>
                </>
            }
        },
        {
            title: 'Chủ tài khoản',
            key: 'accountHolder',
            dataIndex: 'accountHolder',
            render: (accountHolder: string) => <>{accountHolder || '---'}</>
        },
        {
            title: 'Chốt sổ lần cuối',
            key: 'lastUpdate',
            render:(row:any)=> {
                return <>
                    Bởi <span className={'font-medium'}>{lodash.get(row,'lastSettlementBy','---')}</span> lúc <span>{moment(lodash.get(row,'lastSettlementAt','---')).format('HH:mm DD/MM/YYYY ')}</span>
                </>
            }
        },
        {
            title: 'Đơn vị tiền tệ',
            key: 'currencyUnit',
            render: (row: any) => {
                return <>
                    <span className={'font-medium'}>{row.currency ? lodash.get(row, 'currency.code', '') : '---'}</span>
                </>
            }
        },
        {
            title: 'Ghi chú',
            key: 'description',
            render: (row: any) => {
                return (<div className={'flex align-items-center'}>
                           {row.description ?
                               <Tooltip title={lodash.get(row, 'description', '')}>
                                   <div className={'txt-maxW-150'}>{lodash.get(row, 'description', '')}</div>
                               </Tooltip> : '---'}
                </div>)
            }
        },
        {
            title: 'Links',
            key: 'links',
            align: 'center',
            render: (account: AccountDomain) => {
                return <LinkDropdown account={account}/>
            }
        },
        {
            title: '',
            key: 'actions',
            render: (row: AccountDomain) => {
                const isShowCloneButton = SecurityService.can(PERMISSIONS.ACCOUNT_CREATE)
                return(
                    <div style={{width: 24, height: '100%', minHeight: isShowCloneButton && 84 }} className={'flex'} onClick={(e) => e.stopPropagation()}>
                        <div className={'actions'}>
                            <Tooltip title={'Thông tin quỹ'} color={'#339DFF'} placement={'left'}>
                                <span className={'pointer mg-bt-6'} style={{backgroundColor: '#339DFF'}} onClick={() => {
                                    handleCheckingSettlementPermission(row.code,ACCOUNT_PERMISSIONS.ACCOUNT_VIEW,()=> props.showAccount(row.code))
                                }}>
                                    <i className="fa-regular fa-circle-info text-white"/>
                                </span>
                            </Tooltip>
                            <Tooltip className={'pointer'} title={'Chốt sổ'} color={'#4DD69C'} placement={'left'}>
                                <span style={{backgroundColor: '#4DD69C'}} onClick={() => {
                                    handleCheckingSettlementPermission(row.code,ACCOUNT_PERMISSIONS.ACCOUNT_SETTLEMENT,()=> showSettlementModal(row))
                                }}>
                                    <i className="fa-regular fa-pen-to-square text-white"/>
                                </span>
                            </Tooltip>

                            {isShowCloneButton && (
                                <Tooltip className={'pointer'} title={'Nhân bản quỹ'} color={'#27AE61'} placement={'left'}>
                                    <span className="mg-t-6" style={{backgroundColor: '#27AE61'}} onClick={() => {
                                        props.showModalClone(row)
                                    }}>
                                        <i className="fa-solid fa-clone text-white"/>
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )
            }
        },
        {
            title:'',
            key:'alert',
            render: (row:any) => {
                const settlementDuration = lodash.get(localStore.getJson('tenant'),'config.settlementDuration');
                const timeToShowAlert = moment().subtract(moment.duration(settlementDuration)).subtract(1,'days') > moment(row.lastSettlementAt)
                return(
                    <>
                        {timeToShowAlert && <Tooltip
                            title={<div> Hệ thống sẽ tự động chốt sổ vào thời điểm <span className={'bold'}>{moment().subtract(moment.duration(settlementDuration)).format('DD/MM/YYYY')}!</span></div>}
                            placement="topRight" className={'account-item__description mg-l-4'}
                            arrowPointAtCenter
                        >
                            <i className="fa-regular fa-triangle-exclamation text-yellow pointer"/>
                        </Tooltip>}
                    </>
                )
            }
        }
    ];

    const handleRouteToAccountDetail = (code:string) => {
        AccountApi.findByCode(code).then((response)=> {
            if(response) history.push(`/accounts/${code}`)
        }).catch((err)=> {
            if(err && err.response) {
                if(lodash.get(err.response.data,'status') === 403) {
                    NotificationCommon.error({
                        message:'Bạn không có quyền thực hiện thao tác này'
                    })
                }
            }
        })
    }

    return (
        <div className={'account-list-view-list'}>
            <CommonTable
                loading={props.loading}
                dataSource={dataSources}
                columns={columns}
                pagination={{
                    ...pagination,
                    pageSize: pagination.pageSize ? Number(pagination.pageSize) : 24,
                    pageSizeOptions: [10, 20, 24, 50, 100],
                    hideOnSinglePage: false,
                    onChange: props.onChangePage,
                    showSizeChanger: true,
                    showLessItems: true,
                }}
                emptyText="Danh sách tài khoản quỹ đang trống"
                rowKey={'id'}
                rowClassName={'account-table-row'}
                scroll={{x: 1024}}
                onRow={(record) => {
                    return {
                        onClick: () => handleRouteToAccountDetail(record.code),
                    };
                }}
            />
            {settlementVisible && <SettlementModal
                onVisibleChange={handleSettlementModalVisible}
                onSuccess={handleSettlementSuccess}
                onFail={handleSettlementFail}
                account={selectedAccount}
            />}
        </div>
    )
}

export default DataList;
