import { Modal, ModalFuncProps } from 'antd'

interface IProps extends ModalFuncProps {}

export class ModalConfirm {
    static confirm = ({ cancelButtonProps, okButtonProps, cancelText = 'Hủy', okText = 'Xác nhận', ...props }: IProps) => {
        Modal.confirm({
            className: 'confirm-delete-role',
            centered: true,
            cancelButtonProps: { type: 'ghost', icon: <i className="fa-solid fa-xmark" />, ...cancelButtonProps },
            okButtonProps: {
                icon: <i className="fa-regular fa-trash" />,
                danger: true,
                ...okButtonProps,
            },
            cancelText,
            okText,
            maskClosable: true,
            closable: true,
            closeIcon: <i className="fa-solid fa-xmark" />,
            ...props,
        })
    }
}
