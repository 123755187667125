import React from 'react';
import {Select, SelectProps} from "antd";
import {debounce} from 'lodash';
import DeviceApi from "../../Api/DeviceApi";
import DeviceDomain from "../../Domain/DeviceDomain";

interface Props {
    value: any,
    onChange: (code: string) => void,
    selectProps?: SelectProps<any>
}

interface State {
    value: any,
    loading: boolean,
    devices: Array<DeviceDomain>
}

class SelectDevice extends React.Component<Props, State> {

    state = {
        loading: false,
        value: undefined,
        devices: []
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        const newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        this.fetchDevices();
    }

    fetchDevices = (query = {}) => {
        this.setState({loading: true});
        query = {
            offset: 0,
            limit: 1000,
            ...query,
        }
        DeviceApi.autocomplete(query)
            .then(response => {
                this.setState({devices: response.data});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleChange = (value: any) => {
        this.setState({value});
        this.props.onChange(value);
    }

    handleSearch = (keyword: any) => {
        const fetchDevices = debounce(this.fetchDevices, 500);
        fetchDevices({query: keyword});
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.state.value !== prevState.value) {
            this.fetchDevices();
        }
    }

    render() {
        const {selectProps} = this.props;
        const {devices, value} = this.state;
        return (
            <Select
                showSearch={true}
                filterOption={false}
                allowClear={true}
                value={value}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                {...selectProps}
            >
                {devices.map((item: any) => (
                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default SelectDevice;
