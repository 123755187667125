export default interface PaymentVoucherDomain {
    code: string
    createdAt: string
    amount: number
    approvedBy: string
    preparedBy: string
    receiver: string
    receiverRef?: any
    project?: string
    approvedAt: string
    timestamp: string
    memo: string
    paymentReason: {
        id: string
        code: string
        name: string
        invoiceTemplate: string
        invoiceExample: string
    }
    invoice: string
    ref: string
    remark: string
    attachmentInvoices: Array<string>
    warning: string
    status: {
        code: string
        name: string
    }
    account: {
        code: string
        name: string
    }
    reviewStatus?: string
    reviewedBy?: {
        name: string
        user: string
    }
    system?: string
    reviewedAt?: string
}

export const PaymentVoucherModel: PaymentVoucherDomain = {
    code: '',
    timestamp: '',
    createdAt: '',
    amount: 0,
    approvedBy: '',
    preparedBy: '',
    receiver: '',
    receiverRef: '',
    project: '',
    approvedAt: '',
    memo: '',
    paymentReason: {
        id: '',
        code: '',
        name: '',
        invoiceTemplate: '',
        invoiceExample: '',
    },
    invoice: '',
    ref: '',
    remark: '',
    attachmentInvoices: [],
    warning: '',
    status: {
        code: '',
        name: '',
    },
    account: {
        name: '',
        code: '',
    },
}
