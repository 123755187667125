import apiClient from "../Util/ApiClient";
import {RcFile} from "antd/lib/upload/interface";

export default class InvoiceApi {
    static attachment = (files: Array<File|RcFile>) => {
        const formData = new FormData()
        for (let file of files) {
            formData.append('attachments', file)
        }
        return apiClient.post('/invoices/attachments', formData);
    };
}
