import {Badge, Button, Modal} from "antd"
import {AxiosError} from "axios"
import lodash from "lodash"
import PaymentVoucherApi from "../../../Api/PaymentVoucherApi"
import PaymentVoucherCreateCommand from "../../../Command/PaymentVoucherCreateCommand"
import DefaultComponent from "../../../Component/DefaultComponent"
import DefaultLayout from "../../../Component/Layout/Default"
import ModalPaymentVoucherCreate from "../../../Component/ModalPaymentVoucherCreate"
import { NotificationCommon } from "../../../Component/Notification"
import AccountBoxStatisticConsumer from "../../../Consumer/AccountBoxStatisticConsumer"
import {AppContext} from "../../../Context/AppContext"
import PaymentVoucherDomain from "../../../Domain/PaymentVoucherDomain"
import Utils from "../../../Util/Utils"
import DataList from "./DataList"
import FilterForm from "./FilterForm"

import './style.scss'
import ProjectApi from "../../../Api/ProjectApi";
import ProjectDomain from "../../../Domain/ProjectDomain";
import React, {createRef, RefObject} from "react";
import {DraggableModalRef} from "../../../Component/DraggableModal";
import SecurityService from "../../../Util/SecurityService";
import {ACCOUNT_PERMISSIONS} from "../../../Util/Constants";

interface State {
	isShowModalCreate: boolean
	items: Array<PaymentVoucherDomain>
	total: number
	pageSize: number
	currentPage: number
	account: any
	projects:Array<ProjectDomain>
	voucherClone?: PaymentVoucherDomain
}

class PaymentVoucherList extends DefaultComponent<any, State> {
	static contextType = AppContext
	createPaymentVoucherRef: RefObject<DraggableModalRef> = createRef()

	state = {
		account: this.context.state.account,
		loading: false,
		isShowModalCreate: false,
		items: [],
		total: 0,
		pageSize: this.getUrlQueryByName("pageSize", 25),
		currentPage: this.getUrlQueryByName("page", 1),
		suggestStaffs: [],
		projects:[],
		voucherClone: undefined
	}

	componentDidMount() {
		this.fetchPaymentVouchers({
			...this.getQueryFromLocation(),
		})
		this.fetchProjects();
	}

	componentDidUpdate(prevProps: any) {
		if (
			JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
			JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
		) {
			this.fetchPaymentVouchers({
				...this.getQueryFromLocation(),
			})
		}
	}

	fetchProjects = () => {
		ProjectApi.filter({offset: 0, limit: 1000}, true)
			.then(response => {
				this.setState({ projects: response.data} )
			})
	}

	fetchPaymentVouchers = (filter: any = {}) => {
		const { account } = this.state
		if (account.code) {
			filter = {
				offset: 0,
				limit: this.state.pageSize,
				sort: "timestamp:desc",
				...filter,
			}

			this.setState({ loading: true })
			PaymentVoucherApi.getPaymentVouchersByAccount(lodash.get(account, "code"), filter)
				.then((response) => {
					this.setState({
						items: response.data,
						currentPage: parseInt(lodash.get(response, "headers.x-page-number")) + 1,
						pageSize: parseInt(lodash.get(response, "headers.x-page-size")),
						total: parseInt(lodash.get(response, "headers.x-total-count")),
					})
				})
				.finally(() => {
					this.setState({ loading: false })
				})
		}
	}

	showModalCreate = (voucher?: PaymentVoucherDomain) => {
		this.setState({ isShowModalCreate: true, voucherClone: voucher })
	}

	handleModalCreateVisibleChange = (visible: boolean) => {
		if (this.createPaymentVoucherRef.current && visible){
			this.createPaymentVoucherRef.current?.setVisible(true)
			return
		}

		this.setState({ isShowModalCreate: visible })
	}

	handleChangePage = (page: number, pageSize: number) => {
		this.pushCleanQueryToHistory({
			...this.getQueryFromLocation(),
			page: page,
			limit: pageSize,
			offset: (page - 1) * pageSize,
		})
	}

	handleCreatePaymentVoucher = (form: PaymentVoucherCreateCommand) => {
		const { account } = this.state
		console.log('chay vao day')
		this.setState({ loading: true })
		PaymentVoucherApi.create(form)
			.then((response) => {
				NotificationCommon.success({
					message: "Tạo phiếu chi thành công",
				})
				this.setState({ isShowModalCreate: false })

				if (Object.keys(this.getQueryFromLocation()).length) {
					this.pushCleanQueryToHistory({})
				} else {
					this.fetchPaymentVouchers()
				}
				Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code)
			})
			.catch((error: AxiosError) => {
				if (lodash.get(error, "response.status") === 400) {
					if (lodash.get(error, "response.data.title") === "payment_ineligible") {
						NotificationCommon.error({
							message: "Thời gian giao dịch không hợp lệ do đã chốt sổ",
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist") {
						NotificationCommon.error({
							message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(
								error.response,
								"data.transactionCode"
							)}`,
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
						this.confirmForceCreateVoucher(form, error)
					} else if (lodash.get(error, "response.data.title") === "file_not_image_file") {
						NotificationCommon.error({
							message: "File không phải là file ảnh",
						})
					} else if (lodash.get(error, "response.data.title") === "invalid_project") {
						NotificationCommon.error({
							message: "Hệ thống nhận tiền không hợp lệ",
						})
					} else if (lodash.get(error, "response.data.title") === "invalid_customer") {
						NotificationCommon.error({
							message: "Sai định dạng username",
						})
					}
					else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
						let details = lodash.get(error,'response.data.detail').split(/'/);
						NotificationCommon.error({
							message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
						})
					}
					else {
						NotificationCommon.error({
							message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form",
						})
					}
				}
				else {
					NotificationCommon.error({
						message: "Lỗi: " + error.message,
					})
				}
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	}

	confirmForceCreateVoucher = (form: PaymentVoucherCreateCommand, error: AxiosError) => {
		const voucherCodes = lodash.get(error.response, "data.voucherCodes")
		Modal.confirm({
			title: `Mã bút toán đã nằm trong phiếu chi ${voucherCodes.join(",")}. Bạn có muốn tiếp tục tạo phiếu không?`,
			okText: "Xác nhận",
			cancelText: "Bỏ qua",
			onOk: () => {
				this.handleCreatePaymentVoucher({
					...form,
					force: true,
				})
			},
		})
	}

	render() {
		const { items, total, pageSize, currentPage, isShowModalCreate, loading, account,projects } = this.state
		const voucherClone: any = this.state.voucherClone
		return (
			<DefaultLayout {...this.props} title={`${lodash.get(account, "name")} - Danh sách phiếu chi`} breadcrumb={[{title: ' Danh sách phiếu chi'}]}>
				<div className="main-content payment-voucher">
					<div className="white-box px-12 py-12">
						<AccountBoxStatisticConsumer />
					</div>

					<div className="white-box py-12 px-12">
						<FilterForm {...this.props} />
					</div>

					<div className="white-box py-12 px-12 ">
						<div className="table-container">
							<div className="table-header space-between mg-bt-12">
								<div className="align-center font-medium fsz-16px capitalize">
									Danh sách phiếu chi <Badge count={total} showZero overflowCount={999999} className="pd-l-8" />
								</div>
								{SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_CREATE) && <div>
									<Button disabled={!account.code} type={"primary"} onClick={() => this.showModalCreate()} icon={<i className="fa-regular fa-plus pd-r-8" />}>
										Tạo phiếu chi
									</Button>
								</div>}
							</div>
									<DataList
									loading={loading}
									items={items}
									total={total}
									pageSize={pageSize}
									currentPage={currentPage}
									onChangePage={this.handleChangePage}
									cloneVoucher={this.showModalCreate}
									projects={projects}
								/>
							</div>
						</div>
				</div>

				{isShowModalCreate && (
					<ModalPaymentVoucherCreate
						modalRef={this.createPaymentVoucherRef}
						loading={loading}
						voucherCloneCode={voucherClone?.code as any}
						onVisibleChange={this.handleModalCreateVisibleChange}
						onSubmit={this.handleCreatePaymentVoucher}
					/>
				)}
			</DefaultLayout>
		)
	}
}

export default PaymentVoucherList
