import {uniqueId} from "lodash"
import {FC, memo} from "react"
import {LogItemInterface} from "../../Component/LogModal/type"
import MessageDomain from "../../Domain/MessageDomain"
import {ChangedValuesInterface, LogInterface, MessageEvent} from "../../Interface/LogInterface"
import {LogChangedIcon} from "../../Component/LogModal/ChangedIcon";
import { getFailedReason } from "./DataList"

interface ListItemProps extends LogItemInterface<LogInterface<MessageEvent, MessageDomain>> {
}

const keyMapping: any = {
    status: 'Trạng thái',
    lastFailedReason: 'Lý do lỗi cuối cùng',
    lastProcessedBy: 'Xử lý lần cuối bởi',
    transaction: 'Mã giao dịch',
}

const ListItem: FC<ListItemProps> = memo(
    ({item, changedValues, newObject}: ListItemProps) => {

        const renderAnalysisLog = () => {

            return <>
                <span>Phân tích tin nhắn: </span>
                {changedValues.map(({key, right}: ChangedValuesInterface, index: number) => {
                    let failedReason: any
                    if (key === 'lastFailedReason') {
                        failedReason = getFailedReason(String(right), {})
                    }
                    return (
                    <span key={uniqueId()}>
                        {
                            keyMapping[key] && right && <>
                                {index > 0 ? " | " : ''}
                                {keyMapping[key]}: <span
                                className={"font-medium"}>{ failedReason?.reason || right || '---'}</span>
                            </>
                        }
                    </span>
                )})}
            </>
        }

        return (<div>{
            {
                [MessageEvent.CREATE]: <>Phát sinh tin nhắn <span className="font-medium">#{newObject.id}</span></>,
                [MessageEvent.ANALYSIS]: renderAnalysisLog(),
                [MessageEvent.UPDATE_REMARK]:
                    <>
                        Cập nhật ghi chú: <span className="font-medium">{changedValues[0]?.left || '---'}</span> <LogChangedIcon/>
                        <span className="font-medium"> {changedValues[0]?.right}</span>
                    </>,
            }[item.activity]
        }</div>)
    }
)

export {ListItem}
