export interface PaymentReasonDomain {
    code?: string
    name?: string
    description?: string
    createdAt?: string
    createdBy?: string
}

export class PaymentReason implements PaymentReasonDomain{

}
