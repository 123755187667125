import * as React from 'react'
import {useEffect, useMemo, useCallback, memo} from 'react'
import {Modal} from 'antd'
import {ModalProps} from 'antd/lib/modal'
import {useDrag} from '../hooks/useDrag'
import {DraggableModalContextMethods} from '../context/DraggableModalContext'
import {usePrevious} from '../hooks/usePrevious'
import {ModalID, ModalState} from '../context/draggableModalReducer'

const modalStyle: React.CSSProperties = {margin: 0, paddingBottom: 0, pointerEvents: 'auto'}

interface ContextProps extends DraggableModalContextMethods {
    id: ModalID
    modalState: ModalState
    initialWidth?: number
    initialHeight?: number
}

export type DraggableModalInnerProps = ModalProps & { children?: React.ReactNode } & ContextProps

function DraggableModalInnerNonMemo(
    {
        id,
        modalState,
        dispatch,
        visible,
        children,
        title,
        initialWidth,
        initialHeight,
        ...otherProps
    }: DraggableModalInnerProps) {

    useEffect(() => {
        dispatch({type: 'mount', id, intialState: {initialWidth, initialHeight}})
        return () => dispatch({type: 'unmount', id})
    }, [dispatch, id, initialWidth, initialHeight])

    const visiblePrevious = usePrevious(visible)

    useEffect(() => {
        if (visible !== visiblePrevious) {
            if (visible) {
                dispatch({type: 'show', id})
            } else {
                dispatch({type: 'hide', id})
            }
        }
    }, [visible, visiblePrevious, id, dispatch])

    const {zIndex, x, y, width, height} = modalState

    const style: React.CSSProperties = useMemo(() => ({...modalStyle, top: y, left: x, height}), [
        y,
        x,
        height,
    ])

    const onFocus = useCallback(() => dispatch({type: 'focus', id}), [id, dispatch])

    const onDragWithID = useCallback(args => dispatch({type: 'drag', id, ...args}), [
        dispatch,
        id,
    ])

    const onMouseDrag = useDrag(x, y, onDragWithID)

    const titleElement = useMemo(
        () => (
            <div
                className="ant-design-draggable-modal-title"
                onMouseDown={onMouseDrag}
                onClick={onFocus}
            >
                {title}
            </div>
        ),
        [onMouseDrag, onFocus, title],
    )

    return (
        <Modal
            wrapClassName={"ant-design-draggable-modal"}
            style={style}
            width={width}
            mask={false}
            maskClosable={false}
            zIndex={zIndex}
            title={titleElement}
            visible={visible}
            {...otherProps}
        >
            {children}
        </Modal>
    )
}

export const DraggableModalInner = memo(DraggableModalInnerNonMemo)

if (process.env.NODE_ENV !== 'production') {
    DraggableModalInner.displayName = 'DraggableModalInner'
}
